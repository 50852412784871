<template>
  <div>
    <form @submit.prevent="submitPost" method="post" v-if="!reportFlag">
      <div class="report-abuse">
        <div class="report-abuse-heading">Report abuse</div>
        <div class="report-abuse-description">
          A few common reasons customers report reviews:
        </div>
        <ul class="report-abuse-list">
          <li>Harassment, profanity</li>
          <li>Spam, advertisement, promotions</li>
          <li>Given in exchange for cash, discounts</li>
        </ul>
        <div class="report-abuse-description">
          When we get your report, we will check if the review meets our
          community guidelines. If it doesn’t, we’ll remove it.
        </div>
        <button class="report-abuse-button">
          <div class="text">Submit</div>
        </button>
      </div>
    </form>
    <div class="report-abuse" v-else>
      <div class="report-abuse-heading">Thank You!</div>
      <div class="report-abuse-description">
        We have received your request and due action will be taken upon the
        content soon
      </div>
      <button class="report-abuse-button" @click="close">
        <div class="text">KEEP SHOPPING</div>
      </button>
    </div>
  </div>
</template>

<script>
import customerReview from "../services/customer-review";

export default {
  name: "report-abuse",
  data() {
    return {
      reportAbuse: {},
      reportFlag: false,
    };
  },
  props: ["itemId", "productId"],
  methods: {
    close() {
      document.querySelector("body").classList.contains("no-scroll")
        ? document.querySelector("body").classList.remove("no-scroll")
        : "";
      this.$emit("closeModel");
    },
    submitPost() {
      document.querySelector("body").classList.contains("no-scroll")
        ? document.querySelector("body").classList.remove("no-scroll")
        : "";
      customerReview
        .postReportAbuse(this.itemId, this.productId)
        .then(() => {
          this.reportFlag = true;
          this.$emit("reportReview", this.itemId);
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style lang="less">
.report-abuse {
  .report-abuse-heading {
    color: var(--textHeading, #261a1a);
    font-family: unset;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
  }
  .report-abuse-description {
    color: var(--textBody, #3c3131);
    font-family: unset;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 21px */
    margin-top: 12px;
  }
  .report-abuse-list {
    list-style: disc;
    padding: 0px 40px;
    color: var(--textLabel, #7d7676);
    font-family: unset;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    margin: 12px 0;
  }
  .report-abuse-button {
    width: 100%;
    height: 56px;
    border-radius: 4px;
    border: 1px solid var(--buttonPrimary, #8b8374);
    background: var(--buttonPrimary, #8b8374);
    margin-top: 24px;

    color: var(--buttonSecondary, #ffffff);
    font-family: unset;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: -0.28px;
    text-transform: uppercase;
    cursor: pointer;
  }
}
</style>
