<template>
  <div>
    <div
      class="question-answer-container"
      v-if="questionAnswerConfig.is_active"
    >
      <div class="question-answer">
        <div class="qna">
          <div class="header-container">
            <div class="heading">
              {{ questionAnswerConfig && questionAnswerConfig.section_heading }}
            </div>
            <div
              class="search-wrapper"
              :style="{
                gap: show ? '24px' : '0',
              }"
            >
              <div class="left" v-if="showInput">
                <img
                  src="./../assets/search_icon.svg"
                  alt="searchIcon"
                  class="search-icon"
                />
                <div class="search-query">
                  <input
                    type="text"
                    placeholder="Search Question"
                    :value="searchQuery"
                    @input="onSearchChange"
                  />
                </div>
              </div>
              <div
                class="vertical-line"
                v-if="showInput && posted && show"
              ></div>
              <div class="right" v-if="posted">
                <div class="post-item" v-if="show">
                  <div v-if="!checkIsPlatform">
                    <fdk-accounts v-slot="accountsData">
                      <button
                        class="post ask-question"
                        type="submit"
                        @click="handleOnSubmit(accountsData)"
                      >
                        {{
                          questionAnswerConfig &&
                          questionAnswerConfig.question_button_text
                        }}
                      </button>
                    </fdk-accounts>
                  </div>
                  <div v-else>
                    <button class="ask-question" @click="postButton()">
                      {{
                        questionAnswerConfig &&
                        questionAnswerConfig.question_button_text
                      }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            class="not-found fade-in"
            v-if="searchQuery.length && !resources.length"
          >
            <img src="./../assets/empty_response.svg" alt="" srcset="" />
            <p class="no-result-text">
              There is no search results relevant to this
            </p>
          </div>

          <div v-if="posted">
            <div class="post-form" v-if="!posted && !showNetworkError">
              <post-question
                :questionFormConfig="questionFormConfig"
                :productData="productItem"
                @closeQuestion="closeQuestion"
                @closeModal="closeModal"
              ></post-question>
            </div>
          </div>

          <div class="feedback-post" v-if="!show">
            <post-question
              :questionFormConfig="questionFormConfig"
              :productData="productItem"
              @closeFeedback="closeFeedback"
              @closeModal="closeModal"
            ></post-question>
          </div>

          <div class="no-response fade-in" v-if="!filteredQuestions.length">
            <img
              src="./../assets/empty_response.svg"
              alt="No Question and Answer"
              class="no-response-image"
            />
            <p class="no-result-text">There is no question and answer</p>
          </div>

          <div class="question-answer-comment fade-in">
            <div class="box" v-if="filteredQuestions.length">
              <div
                class="question-box"
                v-for="(item, index) in resources"
                :key="index"
              >
                <!-- <div class="" v-if="showOnlyWithAnswers(item)"> -->
                <QuestionAnswerItem
                  :item="item"
                  :qnaConfig="questionAnswerConfig"
                  :closeAnswer="closeAnswer"
                  ref="qna"
                />
                <!-- </div> -->
              </div>
            </div>
          </div>

          <pagination
            v-if="pageConfig.limit < pageConfig.total"
            @change="paginationChange"
            v-model="pageConfig"
          >
          </pagination>
        </div>
        <modals class="model_error" ref="errorModal">
          <template>
            <errorBody @closeDialog="$refs.errorModal.closeModal()"></errorBody>
          </template>
        </modals>
      </div>
    </div>
  </div>
</template>

<script>
import QuestionAnswerItem from "./components/question-answer-item/question-answer-item.vue";
// import  from "./components/question-answer-item/question-answer-item.vue";
import PostQuestion from "./components/post-question/post-question.vue";
import pagination from "./../components/pagination.vue";
import modals from "./../components/modals.vue";
import errorBody from "./../components/error-body.vue";
import { debounce } from "../utils";

// import product from "./../mocks/product.json";
// window.FPI = {
//     state: {
//         product: {
//             subscribe(cb) {
//                 cb(product);
//             },
//         },
//     },
// };

export default {
  name: "qna",
  props: [
    "questionAnswerConfig",
    "resources",
    "paginationConfig",
    "productItem",
    "showInput",
  ],
  components: {
    QuestionAnswerItem,
    PostQuestion,
    pagination,
    modals,
    errorBody,
  },
  data() {
    return {
      /*** Required for inner page */
      isValidationAllowed: false,
      closeAnswer: false,
      count: 0,
      show: true,
      posted: true,
      allshow: true,
      isPlatform: false,
      postQuestion: {
        question: {
          text: null,
        },
      },
      showButton: false,
      options: null,
      selectedItem: "desc_date",
      optionTitles: [
        { name: "Most Recent", id: "desc_date", value: "desc_date" },
        { name: "Oldest", id: "asc_date", value: "asc_date" },
      ],
      showNetworkError: false,
      user: {},
      searchQuery: "",
      // showInput: false,
      questionFormConfig: {
        heading: "Ask your Question?",
      },
      updatedPaginationConfigs: null,
      pageConfig: {},
    };
  },
  mounted() {
    window.FPI.state.product.subscribe(this.getProducts.bind(this));
    window.FPI.state.user.subscribe(({ user }) => {
      this.user = user;
    });
    this.pageConfig = this.paginationConfig;
  },
  destroy() {
    window.FPI.state.product.unsubscribe(this.getProducts.bind(this));
  },
  computed: {
    validated() {
      return this.isValidationAllowed && !this.postQuestion.question.text;
    },
    name() {
      return `${this.user.first_name || ""} ${this.user.last_name || ""}`;
    },
    showFirstSix() {
      return this.resources.slice(0, 4);
    },
    checkIsPlatform() {
      return Boolean(window.sessionStorage.getItem("isPlatform"));
    },
    filteredQuestions() {
      if (!this.resources || !Array.isArray(this.resources)) {
        return [];
      }
      return this.resources.filter(this.showOnlyWithAnswers);
    },
  },
  methods: {
    onSearchChange: debounce(function (e) {
      this.searchQuery = e.target.value;
      this.$emit("search-change", e);
    }, 1000),

    paginationChange(e) {
      this.$refs.qna.forEach((vm) => {
        vm.reset();
      });
      this.updatedPaginationConfigs = { ...e };
      this.$emit("pagination-change", this.updatedPaginationConfigs);
    },
    buttonToggle() {
      this.showButton = !this.showButton;
    },
    cancelButton() {
      this.show = true;
    },
    postButton() {
      this.show = false;
    },
    closeQuestion() {
      this.showNetworkError = false;
      this.$emit("closeQuestion");
    },
    closeFeedback() {
      this.posted = true;
      this.show = true;
    },
    closeModal() {
      this.posted = true;
      this.show = true;
    },
    handleOnSubmit(accountsData) {
      if (this.questionAnswerConfig.who_can_ask_q_and_a == "guest_user") {
        this.postButton();
      } else {
        accountsData.is_logged_in
          ? this.postButton()
          : accountsData.openLogin();
      }
    },
    showOnlyWithAnswers(item) {
      return item.comment && item.comment.total_count > 0 ? true : false;
    },
  },
};
</script>
<style lang="less" scoped>
@import "./../assets/common-config.less";

.error-message {
  font-weight: 300;
  font-size: 10px;
  color: #ee485d;
  display: flex;
  align-items: center;
  margin-top: 5px;
}

.qna {
  margin: 7px 10px 7px 10px;

  @media screen and (max-width: 480px) {
    margin: 7px 10px 7px 10px;
  }
}

.question-answer-container {
  margin: 4rem 0;

  .question-answer {
    margin-bottom: 60px;

    .header-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .selected-likevote {
      display: none;
    }

    .pagignation {
      .previous {
        margin-right: 30px;

        button {
          border: none;
          background: white;

          .text {
            font-family: unset;
            font-weight: 500;
            font-size: 16px;
            line-height: 19px;
            color: #828282;
            margin-left: 10px;
            cursor: pointer;
          }
        }
      }

      .next {
        button {
          border: none;
          background: none;

          .text {
            margin-right: 10px;
            font-family: unset;
            font-weight: 500;
            font-size: 16px;
            line-height: 19px;
            color: #828282;
            cursor: pointer;
          }
        }
      }
    }

    .heading {
      color: var(--textHeading, #261a1a);
      /* Desktop/Heading/H3 - Desktop */
      font-family: unset;
      font-size: 28px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: -0.56px;
      align-self: start;
      justify-content: flex-start;
    }

    .search-wrapper {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      gap: 24px;
      flex: 1 0 0;
      justify-content: flex-end;

      .vertical-line {
        border: 0.5px solid #d4d1d1;
        height: 38px;
        border-radius: 4px;
      }

      .left {
        display: flex;
        flex-direction: column;

        @media screen and (max-width: 450px) {
          width: 100%;
        }

        .search-icon {
          position: absolute;
          margin-top: 0;
          padding: 13px;

          @media screen and (max-width: 450px) {
            padding: 12px;
          }
        }

        .search-query {
          display: flex;
          align-items: center;
          cursor: pointer;
          border-radius: 4px;
          border: 1px solid #d4d1d1;

          input {
            padding-left: 35px;
            height: 36px;
            border-radius: 4px;
            border: 1px solid #d4d1d1;
            width: 100%;
            font-size: 1rem;
            border: none;
            outline: none;

            &:focus {
              border: 1px solid #33b2c1;
              // transition: 0.35s ease;
              border-radius: 4px;
            }
          }
        }
      }

      .right {
        display: flex;
        flex-direction: row;
        width: auto;

        .ask-question {
          display: flex;
          width: auto;
          min-width: 150px;
          height: 40px;
          justify-content: center;
          align-items: center;
          gap: 4px;
          border-radius: 4px;
          background: var(--buttonPrimary, #8b8374);
          color: var(--buttonSecondary, #ffffff);

          /* Desktop/Button/Button text - Desktop */
          font-family: unset;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          letter-spacing: -0.28px;
          border: none;
          cursor: pointer;
        }
      }
    }

    .no-response,
    .not-found {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      margin-top: 48px;
      margin-bottom: 48px;

      .no-response-image {
        @media only screen and (max-width: 480px) {
          width: 350px;
        }
      }

      .no-result-text {
        color: #1f1f1f;
        text-align: center;
        font-family: unset;
        font-size: 1.5rem;
        font-style: normal;
        font-weight: 600;
        line-height: 130%;
        margin-top: -35px;
        letter-spacing: -0.48px;
      }
    }

    .sort-item {
      display: flex;
      flex-direction: column;
      position: absolute;
      display: block;
      background: #f8f8f8;
      border: 1px solid #e0e0e0;
      box-sizing: border-box;
      border-radius: 4px;
      right: 0px;

      .selected-options {
        display: flex;
        justify-content: flex-end;
        margin: 5px 0px 5px 0px;
        cursor: pointer;
        background: #f2f2f2;
        border-radius: 3px 3px 0px 0px;
      }
    }

    .post-Question {
      display: flex;
      flex-direction: row;
      margin-top: 16px;
      background: var(--buttonPrimary, #8b8374);
      width: 100%;

      @media screen and (max-width: 450px) {
        display: flex;
        flex-direction: column;
      }

      .post-description {
        margin: 45px 0px 45px 21px;

        @media screen and (max-width: 450px) {
          margin: 16px 0px 0px 0px;
        }

        .post-text {
          font-family: unset;
          font-weight: 500;
          font-size: 16px;
          line-height: 140%;
          text-align: center;
          color: var(--textHeading, #261a1a);
          margin-left: 20px;

          @media screen and (max-width: 450px) {
            color: rgba(0, 0, 0, 0.5);
          }
        }
      }

      .post-item {
        margin: 32px 17px 32px 36px;

        @media screen and (max-width: 480px) {
          width: 90%;
          margin: 10px 10px 16px 10px;
        }

        .post {
          height: 48px;
          border: 1px solid var(--buttonPrimary, #8b8374);
          background: var(--buttonPrimary, #8b8374);
          box-sizing: border-box;
          border-radius: 3px;
          display: flex;
          cursor: pointer;
          justify-content: center;
          align-items: center;

          @media screen and (max-width: 480px) {
            width: 100%;
          }

          .text {
            font-family: unset;
            font-weight: 600;
            font-size: 16px;
            line-height: 20px;
            color: var(--textHeading, #261a1a);
            margin: 14px 88.5px 14px 88.5px;

            @media screen and (max-width: 480px) {
              margin: 10px;
            }
          }
        }
      }
    }

    .common-heading {
      margin-top: 10px;

      .heading {
        font-family: unset;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: var(--textHeading, #41434c);
      }
    }

    .question-answer-comment {
      margin-top: 2rem;

      .question-number {
        width: 60px;

        @media screen and (max-width: 450px) {
          display: none;
        }
      }

      .question-mob {
        display: none;

        @media screen and (max-width: 450px) {
          display: block;
        }
      }
    }

    .separator {
      border: 0.5px solid #d4d1d1;
    }

    .see-more-questions {
      color: #b1655b;

      /* Desktop/Button/Button text - Desktop */
      font-family: unset;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: -0.28px;
      text-transform: uppercase;
      cursor: pointer;
    }
  }
}

.model_error {
  ::v-deep .modal__dialog {
    max-width: 40%;

    @media screen and (max-width: 930px) {
      max-width: 90%;
    }
  }
}

.fade-in {
  // -webkit-animation: fade-in 1s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  // animation: fade-in 1s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

/**
 * ----------------------------------------
 * animation fade-in
 * ----------------------------------------
 */
// @-webkit-keyframes fade-in {
//   0% {
//     opacity: 0;
//   }

//   100% {
//     opacity: 1;
//   }
// }

// @keyframes fade-in {
//   0% {
//     opacity: 0;
//   }

//   100% {
//     opacity: 1;
//   }
// }

/******** Start New Styles ********/
@media screen and(max-width:992px) {
  .question-answer-container {
    margin: 1.5rem 0.5rem;

    .question-answer {
      .header-container {
        display: flex;
      }

      .search-wrapper {
        .right {
          width: auto;
        }
      }
    }
  }
}

@media screen and(max-width:768px) {
  .question-answer-container {
    margin: 1rem;

    .question-answer {
      .header-container {
        display: block;
      }

      .search-wrapper {
        justify-content: flex-start;
        margin-top: 1rem;

        .left {
          width: 100%;
        }

        .right {
          width: auto;
        }
      }
    }
  }
}

@media screen and(max-width:576px) {
  .question-answer-container {
    margin: 0.5rem;

    .question-answer {
      .header-container {
        display: block;
      }

      .search-wrapper {
        justify-content: flex-start;
        margin-top: 1rem;
        flex-direction: column;
        gap: 1rem;

        .left {
          width: 100%;
        }

        .vertical-line {
          display: none;
        }

        .right {
          width: 100%;
          display: block;

          .ask-question {
            width: 100%;
          }
        }
      }
    }
  }
}
</style>
