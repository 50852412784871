<template>
  <div class="reply-container" v-if="showReply">
    <div class="reply-profile-container">
      <div class="reply-profile">
        <div class="reply-profile-text">
          {{ getFirstLetter(productReviewConfig.seller_reply_title) }}
        </div>
      </div>
      <div class="profile-name">
        {{ productReviewConfig.seller_reply_title }}
      </div>
    </div>
    <div class="reply">
      {{ item.comment.comment }}
    </div>
  </div>
</template>

<script>
export default {
  name: "reply-comments",
  props: ["productReviewConfig", "item"],
  methods: {
    getFirstLetter(name) {
      if (name) {
        return name.split("")[0];
      }
    },
  },
  computed: {
    showReply() {
      return this.item.comment && this.item.comment.comment.length;
    },
  },
};
</script>

<style lang="scss" scoped>
.reply-container {
  display: inline-block;
  position: relative;
  border: 1px solid var(--themeAccentL4, #faf8f3);
  border-radius: 4px;
  padding: 20px;
  background: var(--themeAccentL4, #faf8f3);
  margin-top: 10px;

  color: var(--textBody, #3c3131);
  font-size: 14px;
  font-family: unset;
  line-height: 18px;
  letter-spacing: -0.28px;
}
.reply-container:before {
  content: "";
  display: block;
  position: absolute;
  left: 18px;
  bottom: 100%;
  width: 0;
  height: 0;
  border: 10px solid transparent;
  border-bottom-color: var(--themeAccentL4, #faf8f3);
}

.reply-container:after {
  content: "";
  display: block;
  position: absolute;
  left: 18px;
  bottom: 100%;
  width: 0;
  height: 0;
  border: 9px solid transparent;
  border-bottom-color: var(--themeAccentL4, #faf8f3);
}
.reply-profile-container {
  display: flex;
  align-items: center;
  margin-bottom: 8px;

  .reply-profile {
    margin-right: 7px;
    border-radius: 50%;
    width: 36px;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--themeAccentL2, #e7dbc2);

    .reply-profile-text {
      font-family: unset;
      font-size: 16px;
      line-height: 19px;
      text-align: center;
      color: var(--themeAccentL5, #fff);
      text-transform: capitalize;
    }
  }
  .profile-name {
    color: var(--textHeading, #261a1a);
    font-size: 14px;
    font-family: unset;
    font-weight: 600;
    letter-spacing: -0.28px;
    display: flex;
    align-items: center;
    justify-content: left;
    margin-bottom: 8px;
  }
}
</style>
