<template>
  <div>
    <div class="overall-rating">
      <div class="overall-rating-field">
        <span class="heading">Overall Rating</span>
      </div>

      <div class="star-rating">
        <starRating
          v-model="forms.rating.value"
          v-bind:star-size="32"
          :show-rating="false"
          :active-color="starColor"
          inactive-color="#ffffff"
          :border-color="starColor"
          :border-width="2"
          :padding="12"
        />
      </div>
    </div>
    <!-- <div class="error-message">Please give a rating to this product</div> -->
  </div>
</template>

<script>
import starRating from "vue-star-rating";

export default {
  name: "rating-component",
  props: { forms: {}, starColor: { type: String, default: "#8B8374" } },
  components: {
    starRating,
  },
};
</script>

<style scoped lang="scss">
@import "../form.style.scss";

.overall-rating {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 36px;
  .star-rating {
  }
  .overall-rating-field {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-right: 8px;
    .heading {
      font-family: unset;
      font-weight: 500;
      font-size: 16px;
      line-height: 110%;
      color: #333333;
    }
  }
}
.error-message {
  font-weight: 300;
  font-size: 12px;
  color: #ee485d;
  display: flex;
  align-items: center;
  margin-left: 5px;
}
</style>
