<template>
  <modals class="model_rating" ref="questionForm" @closeModal="closeModal">
    <div class="post-question-container">
      <div v-if="!feedback">
        <questionHeader :modalHeading="questionFormConfig.heading" />
        <questionForm
          :productData="productData"
          @closeQuestion="closeQuestion"
          @handleQuestionSubmit="handleQuestionSubmit"
          @questionSubmitted="questionSubmitted"
        />
      </div>
      <questionFeedback
        :feedback="feedback"
        :showNetworkError="showNetworkError"
        @closeFeedback="closeFeedback"
        :submittedQuestion="submittedQuestion"
      />
    </div>
  </modals>
</template>

<script>
import questionHeader from "./components/question-header.vue";
import questionForm from "./components/question-form.vue";
import questionFeedback from "./components/question-feedback.vue";
import modals from "./../../../components/modals.vue";

export default {
  name: "postQuestion",
  components: {
    modals,
    questionForm,
    questionFeedback,
    questionHeader,
  },
  props: [
    "formConfig",
    "questionFormConfig",
    "ratingByFeatureConfig",
    "productData",
  ],
  data() {
    return {
      feedback: false,
      showNetworkError: false,
      submittedQuestion: "",
    };
  },
  mounted() {
    this.$refs.questionForm.openModal();
  },
  methods: {
    closeQuestion() {
      document.querySelector("body").classList.contains("no-scroll")
        ? document.querySelector("body").classList.remove("no-scroll")
        : "";
      this.showNetworkError = false;
      this.$emit("closeQuestion");
    },
    closeFeedback() {
      document.querySelector("body").classList.contains("no-scroll")
        ? document.querySelector("body").classList.remove("no-scroll")
        : "";
      this.$emit("closeFeedback");
    },
    closeModal() {
      this.$emit("closeModal");
    },
    handleQuestionSubmit() {
      this.feedback = true;
    },
    questionSubmitted(question) {
      this.submittedQuestion = question;
    },
  },
};
</script>

<style lang="scss" scoped>
.model_rating {
  ::v-deep .modal__dialog {
    max-width: 458px !important;
    border-radius: 8px;

    @media screen and (max-width: 930px) {
      max-width: 100%;
    }

    @media screen and (max-width: 576px) {
      max-width: 100%;
      margin: 0 auto;
    }

    .modal__body {
      @media screen and (max-width: 576px) {
        padding: 18px;
      }

      button {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}
</style>
