<template>
  <div class="thankyou-card">
    <inline-svg :src="'tick-icon'"></inline-svg>
    <h3 class="heading">Thank you for feedback</h3>
    <p class="text">
      Your feedback is valuable to us and will help us in improving our service
      to serve you better
    </p>
    <div class="coupon-code">
      <h1 class="heading" id="coupon">{{ getCouponName }}</h1>
      <p
        class="text"
        v-if="rewards.type_slug === 'percentage_quantity_percentage'"
      >
        Flat {{ getValue }}% OFF on next purchase
      </p>
      <p class="text" v-else>Flat Rs. {{ getValue }} OFF on next purchase</p>
    </div>
    <share-copy-button :showSharingLink="showSharingLink"></share-copy-button>
    <div class="shopping-btn">
      <button @click="closeModal">KEEP SHOPPING</button>
    </div>
  </div>
</template>

<script>
import modals from "../components/modals.vue";
import inlineSvg from "../components/inline-svg.vue";
import shareCopyButton from "../components/share-copy-button.vue";

export default {
  name: "reward",
  props: ["rewards", "showSharingLink"],
  computed: {
    getRuleMax() {
      return this.rewards?.rule[0]?.max;
    },
    getRuleMin() {
      return this.rewards?.rule[0]?.min;
    },
    getCouponName() {
      return this.rewards?.code;
    },
    getValue() {
      return this.rewards?.rule[0]?.value;
    },
    checkIsPlatform() {
      return window.sessionStorage.getItem("isPlatform");
    },
  },
  components: {
    modals,
    inlineSvg,
    "share-copy-button": shareCopyButton,
  },
  methods: {
    closeModal() {
      this.$emit("closeModals");
    },
  },
};
</script>

<style lang="less" scoped>
.thankyou-card {
  display: flex;
  padding: 24px;
  flex-direction: column;
  align-items: center;
  border-radius: 8px;
  text-align: center;
  .heading {
    font-size: 24px;
    font-weight: 600;
    margin: 0;
    margin-top: 10px;
  }

  .text {
    font-size: 15px;
    line-height: 140%;
  }
  .coupon-code {
    border-radius: 4px;
    border: 1px dashed var(--themeAccentL1, #e7dbc2);
    background: var(--themeAccentL4, #faf8f3);
    padding: 16px 0;
    margin-top: 24px;
    width: 100%;
    .heading {
      font-size: 30px;
      font-family: unset;
      font-weight: 300;
      margin: 0;
      text-transform: uppercase;
    }
    .text {
      font-size: 14px;
      font-family: unset;
      line-height: 120%;
    }
  }

  .shopping-btn {
    width: 100%;
  }
  .shopping-btn button {
    display: flex;
    padding: 20px 32px;
    margin-top: 24px;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    border: 1px solid var(--buttonPrimary, #8b8374);
    background: var(--buttonPrimary, #8b8374);
    color: #fff;
    width: 100%;

    color: var(--buttonSecondary, #fff);
    /* Desktop/Button/Button text - Desktop */
    font-family: unset;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.28px;
    text-transform: uppercase;
  }
}
</style>
