var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"review-with-images"},[_c('div',{staticClass:"image-gallery"},[_c('div',{staticClass:"image-review"},[_c('div',{attrs:{"id":"slider"}},[_c('transition-group',{staticClass:"slides-group",attrs:{"tag":"div","name":_vm.transitionName}},[(_vm.show)?_c('div',{key:_vm.current,staticClass:"slide"},[(_vm.reviewItem && _vm.reviewItem.media)?_c('div',{staticClass:"image-video-section"},[(_vm.reviewItem.media.items[_vm.current].type == 'video')?_c('div',{staticClass:"img-review"},[_c('video',{attrs:{"width":"680px","height":"500px","loading":"lazy","autoplay":"","controlsList":"nodownload nofullscreen"}},[_c('source',{attrs:{"src":_vm.reviewItem.media.items[_vm.current].url,"type":"video/mp4"}})])]):_c('div',{staticClass:"img-review"},[_c('img',{directives:[{name:"show",rawName:"v-show",value:(_vm.showLoader),expression:"showLoader"}],staticClass:"loader",attrs:{"src":require("../../assets/loader.svg"),"alt":"","srcset":""}}),_c('image-moderation',{attrs:{"isNSFW":_vm.reviewItem.media.items[_vm.current].is_nsfw,"mediaUrl":_vm.reviewItem.media.items[_vm.current].url,"optimizeType":'responsive',"className":['']},on:{"image-loaded":_vm.onImageLoad}})],1)]):_c('div',{staticClass:"image-video-section shimmer"})]):_vm._e()])],1)]),(_vm.reviewItem)?_c('div',{staticClass:"review-list"},[(_vm.configImageReview)?_c('div',{staticClass:"review-config-floating"},[_c('div',{staticClass:"review-profile"},[_c('div',{staticClass:"profile"},[(_vm.configImageReview.display_user_avatar)?_c('div',{staticClass:"name-index"},[_vm._v(" "+_vm._s(_vm.reviewItem.created_by.first_name.charAt(0))+" ")]):_vm._e()]),_c('div',{staticClass:"name-with-verification",style:({
              'justify-content': _vm.reviewItem.verified
                ? 'space-evenly'
                : 'center',
            })},[_c('div',{staticClass:"name"},[_vm._v(" "+_vm._s(_vm.reviewItem.created_by.first_name + " " + _vm.reviewItem.created_by.last_name)+" ")]),(_vm.reviewItem.verified)?_c('div',{staticClass:"verification-status"},[_c('img',{attrs:{"src":require("./../../assets/verified_shopper.svg"),"alt":"","srcset":""}}),_c('div',{staticClass:"verification-text"},[_vm._v("Verified Purchase")])]):_vm._e()])]),_c('div',{staticClass:"review-content"},[_c('div',{staticClass:"overall-rating"},[(_vm.configImageReview.rating_type == 'star_rating')?_c('div',{staticClass:"star-rating"},[_c('div',{staticClass:"rating"},[_vm._v(_vm._s(_vm.reviewItem.rating.value))]),_c('star-rating',{attrs:{"star-size":15,"read-only":true,"max-rating":5,"show-rating":false,"active-color":_vm.configImageReview.star_color},model:{value:(_vm.reviewItem.rating.value),callback:function ($$v) {_vm.$set(_vm.reviewItem.rating, "value", $$v)},expression:"reviewItem.rating.value"}})],1):_vm._e(),(_vm.configImageReview.rating_type == 'number_rating')?_c('div',{staticClass:"number-rating"},[_c('div',{staticClass:"number"},[_c('div',{staticClass:"number-average-rating"},[_vm._v(" "+_vm._s(_vm.reviewItem.rating.value)+" ")]),_c('star-rating',{attrs:{"star-size":15,"active-color":_vm.configImageReview.star_color,"read-only":true,"max-rating":1,"show-rating":false},model:{value:(_vm.reviewItem.rating.value),callback:function ($$v) {_vm.$set(_vm.reviewItem.rating, "value", $$v)},expression:"reviewItem.rating.value"}})],1)]):_vm._e(),(
                _vm.configImageReview.rating_type == 'both_star_and_number_rating'
              )?_c('div',{staticClass:"number-star"},[_c('div',{staticClass:"number-star-rating"},[_c('div',{staticClass:"number-average-rating"},[_vm._v(" "+_vm._s(_vm.reviewItem.rating.value)+" ")]),_c('starRating',{attrs:{"star-size":15,"active-color":_vm.configImageReview.star_color,"read-only":true,"show-rating":false},model:{value:(_vm.reviewItem.rating.value),callback:function ($$v) {_vm.$set(_vm.reviewItem.rating, "value", $$v)},expression:"reviewItem.rating.value"}})],1)]):_vm._e()]),_c('div',{staticClass:"comment-heading"},[(_vm.reviewItem.review.title)?_c('div',{staticClass:"comment-item"},[_vm._v(" "+_vm._s(_vm.getTitle)+" ")]):_vm._e()]),(_vm.configImageReview.display_date)?_c('div',{staticClass:"date-time"},[_vm._v(" Reviewed on: "+_vm._s(_vm.getDate(_vm.reviewItem))+" ")]):_vm._e(),_c('div',{staticClass:"answer-description"},[(_vm.reviewItem.review.description)?_c('div',{staticClass:"comment"},[_vm._v(" "+_vm._s(_vm.getDescription)+" ")]):_vm._e()]),_c('div',{staticClass:"image-container"},[(_vm.reviewItem.media)?_c('div',{staticClass:"comment-container-image"},[_vm._l((_vm.getImages(_vm.reviewItem.media.items)),function(i,imgIndex){return _c('div',{key:i.type + imgIndex,staticClass:"images",style:({
                  outline:
                    imgIndex == _vm.current
                      ? '2px solid var(--buttonLink, #b9af9b)'
                      : 'none',
                }),on:{"click":function($event){return _vm.selectedMedia(i, imgIndex)}}},[(i.type == 'image')?_c('div',[_c('div',{staticClass:"img-slot"},[_c('img',{directives:[{name:"show",rawName:"v-show",value:(_vm.showLoader),expression:"showLoader"}],staticClass:"loader-small",attrs:{"src":require("../../assets/loader.svg"),"alt":"","srcset":""}}),_c('image-moderation',{attrs:{"isNSFW":i.is_nsfw,"mediaUrl":i.url,"optimizeType":'thumbnail',"className":['review-image-block']},on:{"image-loaded":_vm.onImageLoad}})],1)]):_vm._e()])}),_vm._l((_vm.video(_vm.reviewItem.media.items)),function(v,Vindex){return _c('div',{key:v.type + Vindex,staticClass:"images",style:({
                  outline:
                    Vindex == _vm.current
                      ? '2px solid var(--buttonLink, #b9af9b)'
                      : 'none',
                }),on:{"click":function($event){return _vm.selectedMedia(v, Vindex)}}},[(v.type == 'video')?_c('div',[_c('div',[_c('video',{attrs:{"width":"56px","height":"56px"}},[_c('source',{attrs:{"src":v.url,"type":"video/mp4"}})])])]):_vm._e()])})],2):_vm._e()])])]):_vm._e()]):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }