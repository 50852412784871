<template>
  <div class="modal-header">
    <div class="review-heading">{{ modalHeading }}</div>
  </div>
</template>

<script>
export default {
  name: "question-header",
  props: ["modalHeading"],
};
</script>

<style scoped lang="scss">
.modal-header {
  display: flex;
  flex-direction: row;
  margin-bottom: 14px;
  justify-content: space-between;

  .review-heading {
    color: #000;
    font-family: unset;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
    /* 33.6px */
  }
}
</style>
