<template>
  <div>
    <div class="customer-review">
      <h1>{{ productReviewConfig.section_heading }}</h1>
      <div class="customer-review-feature">
        <ratingComponent
          :reviewMetrics="reviewMetrics"
          :productReviewConfig="productReviewConfig"
        />
        <div class="graph-feature-wrapper">
          <div>
            <ratingGraph
              :reviewMetrics="reviewMetrics"
              :metricList="metricList"
              :theme="productReviewConfig.theme"
            />
            <div class="line line-progress"></div>
          </div>
          <div v-if="productReviewConfig.has_rating_by_feature">
            <ratingByFeature
              v-if="checkRatingByFeaturePDP"
              :selectedLayout="productReviewConfig.selected_layout"
              :ratingByFeaturePDP="ratingByFeaturePDP"
              :starColor="productReviewConfig.star_color"
            />
            <div class="line line-feature"></div>
          </div>
        </div>
        <div class="line line-tab"></div>
      </div>
      <div class="feedback-comment">Please help with us your feedback</div>
      <submitButton
        :productReviewConfig="productReviewConfig"
        @onClick="handleOnClick"
        :orderHistory="orderHistory"
      />
    </div>
    <modals
      class="model_rating"
      ref="formModalName"
      :disableClose="disableCloseButton"
    >
      <div v-show="modalType === 'formModal'">
        <reviewForm
          :reviewFormConfig="reviewFormConfig"
          :ratingByFeatureConfig="ratingByFeatureConfig"
          :productData="productData"
          :orderHistory="orderHistory"
          :productReviewConfig="productReviewConfig"
          @closeDialog="$refs.formModalName.closeModal()"
          @disableClose="removeModalClose"
        ></reviewForm>
      </div>

      <div v-show="modalType === 'notEligible'">
        <notEligible @closeModals="closeModals" />
      </div>
    </modals>
    <!-- <modals class="not-eligible" ref="notEligible" :disableClose="true">
      <template>
        <notEligible @closeModals="closeModals" />
      </template>
    </modals> -->
  </div>
</template>

<script>
import ratingGraph from "./components/rating-graph.vue";
import ratingByFeature from "./components/rating-by-feature.vue";
import ratingComponent from "./components/rating-component.vue";
import submitButton from "./components/submit-button.vue";
import modals from "../../../components/modals.vue";
import reviewForm from "../../../product-review-form/product-review-form.vue";
import notEligible from "./components/not-eligible.vue";

export default {
  name: "customer-reviews",
  props: [
    "reviewMetrics",
    "productReviewConfig",
    "formConfig",
    "reviewFormConfig",
    "ratingByFeatureConfig",
    "productData",
    "ratingByFeaturePDP",
    "orderHistory",
  ],
  components: {
    ratingGraph,
    ratingByFeature,
    ratingComponent,
    submitButton,
    modals,
    reviewForm,
    notEligible,
  },
  data() {
    return {
      disableCloseButton: false,
      modalType: "formModal",
    };
  },
  computed: {
    metricList() {
      const data = this.reviewMetrics.rating_metric;
      return (data && [...data].reverse()) || [];
    },
    checkRatingByFeaturePDP() {
      return this.ratingByFeaturePDP?.length > 0;
    },
  },
  methods: {
    handleOnClick(key) {
      if (key === "notEligible") {
        this.modalType = key;
      }

      this.$refs.formModalName.openModal();

      // if (key === "notEligible") {
      //   this.$refs.notEligible.openModal();
      // } else {
      //   this.$refs.formModalName.openModal();
      // }
    },
    closeModals() {
      // this.$refs.notEligible.closeModal();
      this.modalType = "formModal";
      this.$refs.formModalName.closeModal();
    },
    removeModalClose() {
      this.disableCloseButton = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.customer-review {
  h1 {
    font-family: unset;
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 37px;
    letter-spacing: -0.02em;
    color: var(--textHeading, #261a1a);
    margin-top: 0px;
    margin-bottom: 2rem;
  }

  @media only screen and (max-width: 576px) {
    width: 100%;
    padding: 0px;
  }
}

.feedback-comment {
  margin-top: 32px;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  text-align: left;
  letter-spacing: -0.02em;
  color: var(--textHeading, #261a1a);
}
.graph-feature-wrapper {
  display: block;
  @media only screen and (min-width: 577px) and (max-width: 992px) {
    display: grid;
    gap: 24px;
    grid-template-columns: 1fr 1fr;
  }
}

.line {
  width: 100%;
  height: 0;
  border-bottom: 1px solid var(--dividerStokes, #e0e0e0);

  @media only screen and (max-width: 576px) {
    width: 100%;
  }
  @media only screen and (min-width: 577px) and (max-width: 992px) {
    display: none;
  }
}
.line-progress {
  @media only screen and (max-width: 992px) {
    display: none;
  }
}

.line-tab {
  display: none;
  @media only screen and (min-width: 577px) and (max-width: 992px) {
    width: 100%;
    height: 0;
    border: 1px solid #e0e0e0;
    display: inline-block;
  }
}
</style>
