<template>
  <div :class="getRatingClass">
    <!-- star -->
    <div
      class="star-rating"
      v-if="productReviewConfig.rating_type == 'star_rating'"
    >
      <starRating
        v-model="item.rating.value"
        v-bind:star-size="15"
        :active-color="productReviewConfig.star_color"
        :read-only="true"
        :show-rating="false"
        :padding="4"
      />
    </div>
    <!-- both -->
    <div
      class="number-star"
      v-if="productReviewConfig.rating_type == 'both_star_and_number_rating'"
    >
      <div class="number-star-rating">
        <div
          class="number-average-rating"
          :style="{
            'padding-top':
              productReviewConfig.selected_layout === 3 ? '3px' : '0',
          }"
        >
          {{ item.rating.value }}
        </div>
        <starRating
          v-model="item.rating.value"
          v-bind:star-size="15"
          :active-color="productReviewConfig.star_color"
          :read-only="true"
          :show-rating="false"
          :padding="4"
        />
      </div>
    </div>
    <!-- number -->
    <div
      class="number"
      v-if="productReviewConfig.rating_type == 'number_rating'"
    >
      <div class="number-rating">
        <div class="number-average-rating">
          {{ item.rating.value }}
        </div>
        <star-rating
          v-model="item.rating.value"
          v-bind:star-size="15"
          :active-color="productReviewConfig.star_color"
          :read-only="true"
          :max-rating="1"
          :show-rating="false"
          :padding="4"
        />
      </div>
    </div>
  </div>
</template>

<script>
import starRating from "vue-star-rating";

export default {
  name: "rating",
  props: ["productReviewConfig", "item"],
  components: { starRating },
  computed: {
    getRatingClass() {
      if (this.productReviewConfig.selected_layout === 3) {
        return "overall-rating overall-rating-layout";
      }

      return "overall-rating";
    },
  },
};
</script>

<style lang="scss" scoped>
.star-rating {
  ::v-deep .vue-star-rating-rating-text {
    font-size: 14px;
  }
}

.overall-rating {
  margin-bottom: 7px;

  .number-rating {
    display: flex;
    align-items: center;
  }
}

.number-star-rating {
  display: flex;

  align-items: center;
}

.number-average-rating {
  font-weight: 400;
  font-size: 14px;
  letter-spacing: -0.02em;
  color: var(--textHeading, #261a1a);
  margin-right: 7px;
}

.overall-rating-layout {
  margin: 0;
  margin-top: -3px;
}
</style>
