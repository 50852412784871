<template>
  <transition name="modal" v-if="isOpen">
    <div class="modal" ref="modal" tabindex="0" v-bind:class="modalClass">
      <div class="modal-container">
        <div class="modal-header">
          <div class="cross" @click="closeDialog" v-if="isCancelable">
            <inline-svg :src="'cross-black'"></inline-svg>
          </div>
        </div>
        <div class="modal-body">
          <div v-for="media in selectedImgUrl.media" :key="media">
            <div class="ca" v-if="media.type === 'image' && type === 'img'">
              <img :src="media.url" />
            </div>
            <div class="ca" v-if="media.type === 'video' && type === 'video'">
              <video
                class="video-play"
                controls
                v-if="media.type === 'video'"
                width="650px"
                height="370px"
              >
                <source :src="media.url" type="video/mp4" />
              </video>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<style lang="less" scoped>
@import "../../main.less";
.modal {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: @dialog * 3;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: rgba(82, 78, 78, 0.52);
  transition: opacity 0.25s ease;
  font-family: Inter;
  .mirage-scrollbar;
  .flex-center();
  @media @mobile {
    background-color: @White;
    height: 100%;
  }

  .modal-container {
    width: auto;
    position: relative;
    min-width: 300px;
    border-radius: 10px;
    min-height: 100px;
    max-width: 720px;
    max-height: 720px;
    .modal-header {
      padding: 10px 24px;
      .flex-center();
      justify-content: space-between;
    }
    .modal-body {
      display: flex;
      margin: 0 auto;
      justify-content: center;
      .ca img {
        width: 650px;
      }
      // .ca .video-play {
      //   width: 163px;
      //   height: 92px;
      //   border-radius: 4px;
      //   margin-right: 24px;
      // }
    }
    .cross {
      right: 1em;
      position: absolute;

      background: white;
      top: 2em;
      padding: 4px 6px;
      span {
        cursor: pointer;
      }
    }
    .modal-title {
      margin-left: 0;
      text-align: center;
      color: @Mako;
    }
  }
}
.width-100 {
  .modal-container {
    width: 100%;
    padding-bottom: 50px;
  }
}
@media @mobile {
  .modal {
    .modal-container {
      height: 98vh;
      width: 100%;
    }
  }
}
.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}
.modal-enter,
.modal-leave-to {
  transform: unset;
}
</style>

<script>
import inlineSvg from "../../components/common/inline-svg.vue";

export default {
  name: "ImageModal",
  props: {
    selectedImgUrl: {
      type: String,
      default: "",
    },
    isOpen: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: "",
    },

    title: {
      type: String,
    },
    modalClass: {
      type: String,
      default: null,
      required: false,
    },
    isCancelable: {
      type: Boolean,
      default: true,
    },
    childHandleFocus: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    closeDialog() {
      if (this.isCancelable) {
        this.$emit("closedialog");
      }
      document.body.style.overflowY = "scroll";
    },
  },
  components: {
    "inline-svg": inlineSvg,
  },
  mounted() {

    this.$root.$el.append(this.$el);
  },
  destroyed() {
    if (this.$el && this.$el.parentNode && this.$el.nodeName !== "#comment") {
      this.$el.parentNode.removeChild(this.$el);
    }
    document.body.style.overflowY = "scroll";
  },
};
</script>
