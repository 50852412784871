<template>
  <div class="fw-profile-container">
    <div class="verified">
      <div class="profile-verified">
        <div class="verified-icon">
          <img
            v-if="item.verified"
            src="./../../assets/verified_shopper.svg"
            alt="verified_shopper"
          />
          <div class="profile-name">
            {{ item.created_by.first_name }}
            {{ item.created_by.last_name }}
          </div>
          <div class="pipe" v-if="showRating" />
          <slot v-if="showRating"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "profile",
  props: ["floatingReviewConfig", "item"],
  mounted() {},
  methods: {
    getFirstLetter(name) {
      if (name) {
        return name.split("")[0];
      }
    },
  },
  computed: {
    showRating() {
      return this.floatingReviewConfig.selected_layout === 3;
    },
  },
};
</script>

<style lang="scss" scoped>
.fw-profile-container {
  display: flex;

  margin-bottom: 13px;
  align-items: center;

  .verified {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-content: center;

    .profile-verified {
      margin-top: 4px;
      display: flex;
      align-items: center;
      justify-content: left;

      .verified-icon {
        margin-right: 2px;
        display: flex;

        justify-content: center;
        align-content: center;

        .verified-text {
          font-size: 12px;
          line-height: 14px;
          letter-spacing: -0.02em;
          color: var(--textLabel, #7d7676);
        }

        img {
          width: 15px;
          height: 15px;
          margin-right: 6px;
          align-self: center;
        }
      }
    }

    .profile-name {
      font-weight: 600;
      font-size: 16px;
      line-height: 19px;
      letter-spacing: -0.02em;
      color: var(--textHeading, #261a1a);
      display: flex;
      align-items: center;
      justify-content: left;

      @media only screen and (max-width: 576px) {
        max-width: 16rem;
      }
    }
  }
}

.pipe {
  border: 1px solid var(--dividerStokes, #d4d1d1);
  height: 12px;
  margin: 0 8px;
}
</style>
