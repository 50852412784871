"use strict";

import TickIcon from "./svgs/tick-icon.js";
import copyClipboard from "./svgs/copy-clipboard.js";
import shareIcon from "./svgs/share-icon.js";

var svgs = {};

svgs["tick-icon"] = TickIcon;
svgs["copy-clipboard"] = copyClipboard;
svgs["share-icon"] = shareIcon;

export default svgs;
