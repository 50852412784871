<template>
  <div>
    <modals
      ref="applicationReviewModal"
      class="application-modals"
      :disableClose="reviewSubmitted"
    >
      <template>
        <div :style="addThemeVariables">
          <application-review-form
            v-if="!showNetworkError && !reviewSubmitted"
            :applicationReviewConfig="applicationReviewConfig"
            @submitApplicationReview="submitApplicationReview"
            mode="application"
            :orderId="orderId"
          ></application-review-form>
          <feedback-comment
            v-else="reviewSubmitted"
            comment="Your feedback is submitted successfully"
            :feedback="reviewSubmitted"
            :showNetworkError="showNetworkError"
            @closeModals="closeModals"
          />
        </div>
      </template>
    </modals>
  </div>
</template>

<script>
import modals from "../components/modals.vue";
import ApplicationReviewForm from "./application-review-form.vue";
import configuration from "../services/configuration";
import { APPLICATION_REVIEW_CONFIG } from "./constants/ApplicationModalData";
import feedbackComponent from "../product-review-form/components/feedback-comment.vue";
import { colorShadeObject } from "../utils";
export default {
  name: "application-review",
  data() {
    return {
      selectedReview: "config",
      applicationReviewConfig: APPLICATION_REVIEW_CONFIG,
      showNetworkError: false,
      orderId: "",
      reviewSubmitted: false,
    };
  },
  components: {
    modals,
    "application-review-form": ApplicationReviewForm,
    "feedback-comment": feedbackComponent,
  },
  methods: {
    submitApplicationReview(data) {
      configuration
        .postApplicationReview(data)
        .then(() => {
          this.reviewSubmitted = true;
        })
        .catch((error) => {
          if (
            error &&
            error.message &&
            error.message.indexOf("Network Error") > -1
          ) {
            this.showNetworkError = true;
          }
        });
    },
    closeModals() {
      this.showNetworkError = false;
      this.$refs.applicationReviewModal.closeModal();
    },
  },
  async mounted() {
    window.FPI.event.on("order.processed", async (eventData) => {
      this.orderId = eventData.order_id;
      let response = null;
      try {
        await configuration.checkApplicationReviewForOrderId(this.orderId);
      } catch (error) {
        if (error.response.status === 404) {
          let widget = "application_review";
          response = await configuration.getWidgetsConfig(widget);
          this.applicationReviewConfig = response?.data?.data[0]?.config;
          if (response?.data?.data[0]?.config?.is_active) {
            this.$refs.applicationReviewModal.openModal();
          }
        }
      }
    });
  },
  computed: {
    addThemeVariables() {
      let themeShadeColor =
        getComputedStyle(document.documentElement).getPropertyValue(
          "--themeAccentL1"
        ) || undefined;

      let themeAccent = getComputedStyle(
        document.documentElement
      ).getPropertyValue("--accentColor");

      return !themeShadeColor ? colorShadeObject(themeAccent) : {};
    },
  },
};
</script>

<style lang="scss" scoped>
.application-modals {
  @media screen and (max-width: 480px) {
    align-items: flex-end;
  }
  ::v-deep .modal__dialog {
    max-width: 558px;
  }

  ::v-deep .modal__body {
    padding: 16px;
    @media only screen and (min-width: 577px) and (max-width: 992px) {
      padding: 30px;
    }
    @media only screen and (min-width: 993px) {
      padding: 40px;
    }
  }
}
</style>
