<template>
  <div class="header-container">
    <div class="header-wrapper">
      <media
        :item="item"
        className="media-v2"
        v-if="productReviewConfig.review_media"
      />
      <div class="header-content">
        <div>
          {{ item.created_by.first_name }}
          {{ item.created_by.last_name }}
        </div>
        <div>
          <verified :item="item" />
        </div>
        <div>
          <rating :item="item" :productReviewConfig="productReviewConfig" />
        </div>
      </div>
    </div>
    <div v-if="productReviewConfig.review_date" class="review-date">
      {{ getDate(item) }}
    </div>
  </div>
</template>

<script>
import media from "./media.vue";
import verified from "../../rating/components/top-reviews/components/reviews/components/verified.vue";
import rating from "../../rating/components/top-reviews/components/reviews/components/rating.vue";
import { convertDate } from "./../../utils";

export default {
  name: "review-carousel-header",
  props: ["item", "productReviewConfig"],
  data() {
    return {
      reviewListId: null,
    };
  },
  computed: {
    getMedia() {
      return this.item?.media?.items.slice(0, 1);
    },
  },
  components: { verified, rating, media },
  methods: {
    closeModals() {
      this.$refs.imageReview.closeModal();
    },
    getReviewIdWithImage(review, url) {
      this.reviewListId = {
        review,
        url,
      };
      this.$refs.imageReview.openModal();
    },
    getDate(items) {
      let date = convertDate(items);
      return date;
    },
  },
};
</script>
<style scoped lang="scss">
.header-wrapper {
  display: flex;
  gap: 16px;
  align-items: center;
}
.header-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .header-content {
    color: var(--textHeading, #261a1a);
    /* Desktop/Heading/Subheading H5 - Desktop */
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.32px;
  }
  @media only screen and (max-width: 576px) {
    display: block;
  }
}
.image_review {
  ::v-deep .modal__dialog {
    width: 90%;
    max-width: 946px;
    max-height: 600px;
    @media only screen and (max-width: 576px) {
      width: 288px;
      max-height: none;
    }
  }
  ::v-deep .modal__body {
    padding: 0;
    @media only screen and (max-width: 576px) {
      max-width: 100%;
    }
  }
}
.review-date {
  color: var(--textLabel, #7d7676);
  /* label, caption/small body - Desktop,mob,tab */
  font-family: unset;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.24px;
}
</style>
