import axios from "axios";
let { url } = require("./urlset");

const PROXY_BASE_URL = "/ext/reviews/api";

const configuration = {
  reviewRating() {
    return axios.get(
      `${url}${PROXY_BASE_URL}/v1.0/configuration/entity/product/`
    );
  },
  getWidgetsConfig(widget) {
    return axios.get(
      `${url}${PROXY_BASE_URL}/v2.0/configurations?widget=${widget}`
    );
  },
  getFeatureRating(productId) {
    // To show on the product description page
    return axios.get(
      `${url}${PROXY_BASE_URL}/v2.0/products/${productId}/features`
    );
  },
  reviewForm() {
    return axios.get(
      `${url}${PROXY_BASE_URL}/v1.0/configuration/entity/form-style/`
    );
  },
  questionAnswer() {
    return axios.get(
      `${url}${PROXY_BASE_URL}/v1.0/configuration/entity/question/`
    );
  },
  applicationReview() {
    return axios.get(
      `${url}${PROXY_BASE_URL}/v1.0/configuration/entity/form-style?tab=application`
    );
  },
  postApplicationReview(data) {
    return axios.post(
      `${url}${PROXY_BASE_URL}/v1.0/review-rating/entity/application`,
      data
    );
  },
  checkApplicationReviewForOrderId(orderId) {
    return axios.get(
      `${url}${PROXY_BASE_URL}/v1.0/review-rating/entity/application?order_id=${orderId}`
    );
  },
};
export default configuration;
