<template>
  <div class="feedback-comment">
    <div class="feedback">
      <div class="heading">Network Error!</div>
    </div>
  </div>
</template>

<style lang="less" scoped>
@import "../assets/common-config.less";

.feedback-comment {
  .feedback {
    .heading {
      font-family: unset;
      font-weight: 600;
      font-size: 18px;
      line-height: 25px;
      text-align: center;
      color: #4f4f4f;
      margin-bottom: 10px;
    }
  }
}
</style>

<script>
export default {
  name: "errorModal",
  components: {},
  props: {},
  data() {
    return {
      showNetworkError: false,
    };
  },
};
</script>
