<template>
  <div v-if="showReviews">
    <topReviewHeader />
    <reviews
      :reviewListItem="reviewListItem"
      :totalReviews="totalReviews"
      :productReviewConfig="productReviewConfig"
      :showLoadMoreButton="showLoadMoreButton"
      :voteSuccess="voteSuccess"
      :productData="productData"
    />
  </div>
</template>

<script>
import topReviewHeader from "./components/top-review-header.vue";
import reviews from "./components/reviews/reviews.vue";

export default {
  name: "top-reviews",
  props: [
    "reviewListItem",
    "totalReviews",
    "productReviewConfig",
    "showLoadMoreButton",
    "voteSuccess",
    "productData",
  ],
  components: {
    topReviewHeader,
    reviews,
  },
  computed: {
    showReviews() {
      return this.reviewListItem?.length > 0;
    },
  },
};
</script>

<style></style>
