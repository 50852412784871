<template>
  <div>
    <title-component
      :title="applicationReviewConfig.title"
      v-if="isTitleLength"
    ></title-component>
    <rating-panel
      :originalRating="originalRating"
      :scalableRating="scalableRating"
      @ratingClick="handleRatingClick"
      @numberRatingClick="handleNumberRatingClick"
      :ratingType="applicationReviewConfig.rating_type"
    />
    <validation
      :validation="applicationReviewConfig.map_validation"
      :originalRating="originalRating"
      v-if="applicationReviewConfig.has_map_validation"
    ></validation>
    <tags
      :tags="applicationReviewConfig.map_tags"
      :originalRating="originalRating"
      :selectedTag="selectedTag"
      @toggleTag="toggleTag"
      v-if="applicationReviewConfig.has_map_tags"
    ></tags>
    <description-box
      :placeholder="applicationReviewConfig.description"
      @handleDescription="handleDescription"
      :description="description"
      v-if="applicationReviewConfig.has_description"
    ></description-box>
    <submit-button
      :buttonText="applicationReviewConfig.button_text"
      @handleSubmit="handleSubmit"
      :originalRating="originalRating"
    ></submit-button>
  </div>
</template>

<script>
import titleComponent from "./components/title.vue";
import RatingPanel from "./components/rating-panel.vue";
import tags from "./components/tags.vue";
import validation from "./components/validation.vue";
import descriptionBox from "./components/description-box.vue";
import submitButton from "./components/submit-button.vue";

export default {
  name: "application-review-form",
  props: ["applicationReviewConfig", "mode", "orderId"],
  components: {
    "title-component": titleComponent,
    "rating-panel": RatingPanel,
    tags,
    validation,
    "description-box": descriptionBox,
    "submit-button": submitButton,
  },
  data() {
    return {
      originalRating: 0,
      scalableRating: 0,
      selectedTag: [],
      description: "",
    };
  },
  computed: {
    isTitleLength() {
      return this.applicationReviewConfig?.title?.length > 0;
    },
  },
  methods: {
    handleRatingClick(rating) {
      this.originalRating = rating;
      this.scalableRating = rating * 2;
    },
    handleNumberRatingClick(item) {
      this.scalableRating = item;
      this.originalRating = Math.ceil(item / 2);
    },
    toggleTag(value) {
      if (this.selectedTag.includes(value)) {
        this.selectedTag = this.selectedTag.filter((el) => el !== value);
      } else {
        this.selectedTag = [...this.selectedTag, value];
      }
    },
    handleDescription(val) {
      this.description = val;
    },
    handleSubmit() {
      const data = {
        description: this.description,
        rating: this.originalRating || 0,
        order_id: this.orderId,
        tags: this.selectedTag || [],
      };
      if (this.mode === "application") {
        this.$emit("submitApplicationReview", data);
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
