<template>
  <div class="progress">
    <div v-if="metricList.length > 0">
      <div
        class="progress-bar"
        v-for="(item, index) in metricList"
        :key="index"
      >
        <div class="bar-counting">
          <div class="total-bar">{{ item.rating }} Star</div>
        </div>
        <div class="progress-item">
          <div class="bar" :style="[getWidth(item), getBarColor(index)]"></div>
        </div>
        <div class="total-percentage">
          {{
            Math.round(
              (item.count / reviewMetrics.review_count).toFixed(2) * 100
            )
          }}%
        </div>
      </div>
    </div>
    <div v-if="metricList.length == 0">
      <div class="progress-bar" v-for="index in 5" :key="index">
        <div class="bar-counting">
          <span class="total-bar">{{ 6 - index }}</span>
          <span class="total-bar">Star</span>
        </div>
        <div class="progress-item">
          <div class="bar" :style="{ width: 0 + '%' }"></div>
        </div>
        <div class="total-percentage">{{ 0 }}%</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "rating-graph",
  props: ["color", "reviewMetrics", "metricList", "theme"],
  methods: {
    getWidth(item) {
      return {
        width: (item.count / this.reviewMetrics.review_count) * 100 + "%",
      };
    },
    getBarColor(index) {
      if (this.theme === "multi_colour") {
        if (index === 0 || index === 1 || index === 2) {
          return { background: "#14958F" };
        } else if (index === 3) {
          return { background: "#FCB301" };
        } else {
          return { background: "#F16565" };
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.progress {
  margin: 32px 0;
  @media only screen and (max-width: 576px) {
    width: 100%;
  }
  .progress-bar {
    display: flex;

    height: 24px;
    margin-bottom: 7px;
    align-items: center;
    justify-content: space-between;

    @media only screen and (max-width: 576px) {
      width: 100%;
    }
    .total-percentage {
      font-size: 12px;
      line-height: 14px;
      letter-spacing: -0.02em;
      color: var(--textBody, #3c3131);
      width: 6%;
      text-align: right;
    }
    .bar-counting {
      display: flex;

      justify-content: space-between;
      width: 41px;
      .total-bar {
        font-size: 12px;
        line-height: 14px;
        letter-spacing: -0.02em;
        color: var(--textBody, #3c3131);
      }
    }
    .progress-item {
      padding: 0px;
      margin: 0 16px;
      width: 80%;
      height: 24px;
      overflow: hidden;
      border-radius: 4px;
      background: rgba(0, 0, 0, 0.03);

      .bar {
        position: relative;
        float: left;
        min-width: 1%;
        height: 100%;
        background: var(--themeAccentL2, #f1e9da);
        border-radius: 4px;
        span {
          float: right;
          padding: 4px 5px;
          color: #fff;
          font-size: 0.7em;
        }
      }
    }
  }
}
</style>
