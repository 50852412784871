<template>
  <div>
    <div
      class="review-card-v3"
      :style="{ display: isDisabledMedia ? 'block' : 'flex' }"
    >
      <div
        :class="{
          'review-wrapper-v3': true,
          'disabled-review-wrapper-v3': true,
        }"
        :style="{ display: isDisabledMedia ? 'block' : 'flex' }"
      >
        <div
          :class="{
            'disabled-media': isDisabledMedia,
          }"
        >
          <img
            src="../../assets/apostrophe.svg"
            alt="apostrophe"
            class="apostrophe"
          />
          <rating
            :productReviewConfig="homeReviewCarouselConfig"
            :item="review"
          />
          <review-body
            :class="{
              'review-body': true,
              'review-body-center': isDisabledMedia,
            }"
            :productReviewConfig="getData"
            :item="review"
          />
          <view-product
            class="product-link"
            :item="review"
            :productReviewConfig="homeReviewCarouselConfig"
            :showDate="false"
          />
          <profile
            :productReviewConfig="homeReviewCarouselConfig"
            :item="review"
            identifier="review-carousel"
            :isDisabledMedia="isDisabledMedia"
            :className="
              isDisabledMedia
                ? 'profile-review-carousel cs-center'
                : 'profile-review-carousel'
            "
          />
        </div>
        <media
          :item="review"
          className="media-v3"
          v-if="homeReviewCarouselConfig.review_media"
        />
      </div>
    </div>
  </div>
</template>

<script>
import profile from "../../rating/components/top-reviews/components/reviews/components/profile.vue";
import rating from "../../rating/components/top-reviews/components/reviews/components/rating.vue";
import reviewBody from "../../rating/components/top-reviews/components/reviews/components/review-body.vue";
import reviewHeader from "../components/review-header.vue";
import viewProduct from "../components/viewProduct.vue";
import media from "../components/media.vue";

export default {
  name: "review-card-v3",
  props: ["review", "homeReviewCarouselConfig"],
  components: {
    rating,
    "review-body": reviewBody,
    "review-header": reviewHeader,
    "view-product": viewProduct,
    profile,
    media,
  },
  computed: {
    getData() {
      return { ...this.homeReviewCarouselConfig, display_date: true };
    },
    isDisabledMedia() {
      return !this.homeReviewCarouselConfig.review_media;
    },
  },
};
</script>

<style lang="scss" scoped>
.review-card-v3 {
  display: flex;
  justify-content: center;
  padding: 10px 20px;
  gap: 24px;
  flex-direction: row;
  width: 100%;

  @media only screen and (max-width: 576px) {
    flex-direction: column-reverse;
    gap: 0px;
    width: auto;
  }
}

.review-wrapper-v3 {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  padding: 1rem 0;
  width: auto;
}
.disabled-review-wrapper-v3 {
  border-radius: 12px;
  background: #fff;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.08);
  padding: 32px;
  gap: 24px;
}

.disabled-media {
  display: flex;
  flex-direction: column;
  align-items: center;
  .apostrophe {
    position: unset;
    z-index: 0;
    width: 100px;
    height: 76.404px;

    @media only screen and (max-width: 576px) {
      width: 60px;
      height: 45.843px;
    }
    @media only screen and (min-width: 577px) and (max-width: 768px) {
      width: 80px;
      height: 61.124px;
    }
  }
}

.review-body {
  margin-top: 8px;
  text-align: left;
}
.review-body-center {
  text-align: center;
}

.review-body-center .date-time {
  justify-content: center;
}

.apostrophe {
  display: flex;
  justify-content: left;
  padding-bottom: 16px;
  height: 77px;
  width: 100px;
  @media only screen and (max-width: 992px) {
    position: absolute;
    z-index: -1;
  }
}

@media only screen and (min-width: 577px) and (max-width: 767px) {
  .review-card-v3 {
    padding: 10px;
    .media-v3 {
      height: auto;
      width: 229px;
    }
  }
}
@media only screen and (min-width: 992px) {
  .review-card-v3 {
    padding: 10px 0px;
  }
}
@media only screen and (min-width: 768px) {
  .review-card-v3 {
    width: 100%;
    .review-wrapper-v3 {
      width: 100%;
      margin: 0 12px;
    }
  }
}
@media only screen and (max-width: 576px) {
  .review-wrapper-v3 {
    width: 100%;
  }
}
</style>
