var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"review-card-v3",style:({ display: _vm.isDisabledMedia ? 'block' : 'flex' })},[_c('div',{class:{
        'review-wrapper-v3': true,
        'disabled-review-wrapper-v3': true,
      },style:({ display: _vm.isDisabledMedia ? 'block' : 'flex' })},[_c('div',{class:{
          'disabled-media': _vm.isDisabledMedia,
        }},[_c('img',{staticClass:"apostrophe",attrs:{"src":require("../../assets/apostrophe.svg"),"alt":"apostrophe"}}),_c('rating',{attrs:{"productReviewConfig":_vm.homeReviewCarouselConfig,"item":_vm.review}}),_c('review-body',{class:{
            'review-body': true,
            'review-body-center': _vm.isDisabledMedia,
          },attrs:{"productReviewConfig":_vm.getData,"item":_vm.review}}),_c('view-product',{staticClass:"product-link",attrs:{"item":_vm.review,"productReviewConfig":_vm.homeReviewCarouselConfig,"showDate":false}}),_c('profile',{attrs:{"productReviewConfig":_vm.homeReviewCarouselConfig,"item":_vm.review,"identifier":"review-carousel","isDisabledMedia":_vm.isDisabledMedia,"className":_vm.isDisabledMedia
              ? 'profile-review-carousel cs-center'
              : 'profile-review-carousel'}})],1),(_vm.homeReviewCarouselConfig.review_media)?_c('media',{attrs:{"item":_vm.review,"className":"media-v3"}}):_vm._e()],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }