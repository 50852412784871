<template>
  <div>
    <div class="feedback-comment" v-if="feedback && !showNetworkError">
      <div class="feedback">
        <div class="icon">
          <img src="../../assets/thank_you_feedback.svg" alt="" />
        </div>
        <div class="heading">Thank You!</div>
        <span
          >Your feedback is under review and it will be published soon subjected
          to approval</span
        >
        <div v-if="rating > 3">
          <share-copy-button
            :showSharingLink="showSharingLink"
            :showCopy="false"
          ></share-copy-button>
        </div>
        <button class="button" @click="$emit('closeModals')">
          <div class="text">KEEP SHOPPING</div>
        </button>
      </div>
    </div>
    <div class="feedback-comment" v-if="showNetworkError">
      <div class="feedback">
        <div class="heading">Network Error!</div>
      </div>
    </div>
  </div>
</template>

<script>
import sharedCopyButton from "../../components/share-copy-button.vue";

export default {
  name: "feedback-component",
  props: ["feedback", "showNetworkError", "showSharingLink", "rating"],
  components: {
    "share-copy-button": sharedCopyButton,
  },
  mounted() {},
};
</script>

<style scoped lang="scss">
.feedback-comment {
  .feedback {
    .icon {
      display: flex;
      justify-content: center;
      margin-bottom: 20px;
    }
    .heading {
      font-size: 24px;
      font-weight: 600;
      margin: 0;
      margin-top: 10px;
      text-align: center;
      color: var(--textHeading, #261a1a);
      margin-bottom: 10px;
    }
    span {
      font-family: unset;
      font-size: 15px;
      line-height: 140%;
      text-align: center;
      margin-bottom: 10px;
      display: flex;
      align-content: center;
      justify-content: center;
    }
    .button {
      display: flex;
      padding: 20px 32px;
      margin-top: 24px;
      justify-content: center;
      align-items: center;
      border-radius: 4px;
      border: 1px solid var(--buttonPrimary, #8b8374);
      background: var(--buttonPrimary, #8b8374);
      color: #fff;
      width: 100%;

      .text {
        color: var(--buttonSecondary, #fff);
        /* Desktop/Button/Button text - Desktop */
        font-family: unset;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: -0.28px;
        text-transform: uppercase;
      }
    }
  }
}
</style>
