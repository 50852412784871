<template>
  <div>
    <div class="image-box" v-for="(img, index) in imageItem" :key="index">
      <button class="image-review-box" @click="emitId(img.entity.id, img.url)">
        <div v-if="img.type == 'video'">
          <video width="92px" height="92px" loading="lazy">
            <source :src="img.url" type="video/mp4" />
          </video>
        </div>
        <div v-else>
          <img
            src="../assets/loader.svg"
            class="loader-small"
            alt=""
            srcset=""
            v-show="showLoader"
          />
          <image-moderation
            :isNSFW="img.is_nsfw"
            :mediaUrl="img.url"
            :optimizeType="'thumbnail'"
            :className="['']"
            @image-loaded="onImageLoad"
          ></image-moderation>
        </div>
      </button>
    </div>
  </div>
</template>
<style lang="less" scoped>
.image-review-box {
  border: none;
  cursor: pointer;
  video {
    object-fit: cover;
  }
}
.image-box {
  float: left;
  padding: 7px;
  padding-left: 10px;
  display: block;
  overflow: hidden;
  border-radius: 3px;

  img {
    width: 92px;
    height: 92px;
    border-radius: 4px;
  }

  img:hover {
    transform: scale(2, 2);
    transition: 0.3s transform;
  }
}
</style>
<script>
import { resizeThumbnails } from "./../utils";
import imageModeration from "./../components/image-moderation.vue";

export default {
  name: "allImage",
  props: ["imageItem"],
  components: { imageModeration },
  data() {
    return {
      images: null,
      productId: null,
      visible: false,
      paginationConfig: {
        limit: 10,
        current: 1,
        total: 0,
      },
      showLoader: true,
    };
  },
  mounted() {
    let element = document.querySelector(".all_image .modal__body");
    element.addEventListener("scroll", this.callScrollEvent);
  },
  methods: {
    callScrollEvent(e) {
      if (e.target.offsetHeight + e.target.scrollTop >= e.target.scrollHeight) {
        this.$emit("scrollEvent");
      }
    },
    close() {
      this.$emit("closemodel");
    },
    emitId(review, url) {
      this.$emit("reviewid", { review, url });
    },
    getThumbnailMedia(url) {
      return resizeThumbnails(url);
    },
    onImageLoad() {
      this.showLoader = false;
    },
  },
};
</script>
