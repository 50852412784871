<template>
  <div>
    <div class="review-image" v-if="productReviewConfig.display_review_images">
      <div
        :class="{
          'comment-container-image': true,
          layout3: productReviewConfig.selected_layout === 3,
        }"
        v-if="item.media"
      >
        <div
          class="images"
          v-for="(media, index) in getMedia"
          :key="media.type + index"
        >
          <div v-if="media.type == 'image'">
            <div>
              <button
                class="img-review"
                v-on:click="getReviewIdWithImage(item._id, media.url)"
              >
                <img
                  src="../../../../../../assets/loader.svg"
                  class="loader-small"
                  alt=""
                  srcset=""
                  v-show="showLoader"
                />
                <image-moderation
                  :isNSFW="media.is_nsfw"
                  :mediaUrl="media.url"
                  :optimizeType="'thumbnail'"
                  :className="['review-image-block', lastClass(index)]"
                  @image-loaded="onImageLoad"
                ></image-moderation>
                <div
                  :class="{ centered: isLastImage(index) }"
                  v-if="isLastImage(index)"
                >
                  {{ getImageText }}
                </div>
              </button>
            </div>
          </div>
          <div v-if="media.type == 'video'">
            <div>
              <button
                :class="{
                  'img-review': true,
                  'is-last': isLastImage(index),
                }"
                v-on:click="getReviewIdWithImage(item._id, media.url)"
              >
                <video width="56px" height="56px">
                  <source :src="media.url" type="video/mp4" />
                </video>
                <div
                  :class="{ centered: isLastImage(index) }"
                  v-if="isLastImage(index)"
                >
                  {{ getImageText }}
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <modals class="image_review" ref="imageReview">
      <template>
        <imageReviewItem
          :getBack="closeModals"
          :rid="reviewListId"
          @closeDialog="$refs.imageReview.closeModal()"
        />
      </template>
    </modals>
  </div>
</template>

<script>
import modals from "../../../../../../components/modals.vue";
import imageReviewItem from "../../../../../../components/image-review.vue";
import ImageModeration from "../../../../../../components/image-moderation.vue";
import { resizeThumbnails } from "../../../../../../utils";

export default {
  name: "media-component",
  props: ["productReviewConfig", "item"],
  data() {
    return {
      reviewListId: null,
      showLoader: true,
    };
  },
  components: { modals, imageReviewItem, ImageModeration },
  computed: {
    getMedia() {
      return this.productReviewConfig.selected_layout !== 1
        ? this.item?.media?.items.slice(0, 3)
        : this.item?.media?.items;
    },
    getImageText() {
      return `+${this.item?.media?.items.length - this.getMedia.length}`;
    },
    checkIsPlatform() {
      return Boolean(window.sessionStorage.getItem("isPlatform"));
    },
  },
  methods: {
    closeModals() {
      this.$refs.imageReview.closeModal();
    },
    getReviewIdWithImage(review, url) {
      if (!this.checkIsPlatform) {
        this.reviewListId = {
          review,
          url,
        };
        this.$refs.imageReview.openModal();
      }
    },
    isLastImage(index) {
      if (
        this.productReviewConfig.selected_layout !== 1 &&
        this.item?.media?.items.length > 3
      ) {
        return this.getMedia.length - 1 === index;
      }
    },
    lastClass(index) {
      return this.isLastImage(index) ? "is-last" : "";
    },
    getThumbnailMedia(url) {
      return resizeThumbnails(url);
    },
    onImageLoad() {
      this.showLoader = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.comment-container-image {
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
  gap: 7px;

  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  .images {
    // margin-right: 7px;
    .review-image-block {
      width: 56px;
      height: 56px;
      object-fit: cover;
      border-radius: 4px;
    }
    .is-last {
      filter: brightness(60%);
    }
  }
}
.layout3 {
  justify-content: end;
}

.img-review {
  border: none;
  background: transparent;
  cursor: pointer;
  padding: 0px;
  position: relative;
  text-align: center;
  color: white;
  width: 56px;
  height: 56px;

  img {
    object-fit: cover;
  }
  .image-review-block {
    width: 80px;
    height: 100px;
    object-fit: cover;
    border-radius: 24px;
  }
}
.image_review {
  ::v-deep .modal__dialog {
    width: 90%;
    max-width: 946px;
    max-height: 600px;
    @media only screen and (max-width: 576px) {
      width: 288px;
      max-height: none;
    }
  }
  ::v-deep .modal__body {
    padding: 0;
    @media only screen and (max-width: 576px) {
      max-width: 100%;
    }
  }
}

.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: var(--button-secondary, #fff);
  text-align: center;
  font-family: unset;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.28px;
}

.loader-small {
  width: 40px;
  height: 40px;
}
</style>
