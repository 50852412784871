<template>
  <div>
    <div v-if="productReviewConfig.no_review === 'hide_review'"></div>
    <div class="no-review" v-else>
      <img
        src="../../assets/empty_response.svg"
        alt="no review"
        class="no-review-image"
      />
      <p class="no-result-text">There is no review for this product</p>
      <submitButton
        className="no-review-submit"
        :productReviewConfig="productReviewConfig"
        @onClick="handleOnClick"
        :orderHistory="orderHistory"
      />
    </div>
    <modals
      class="model_rating"
      ref="formModalName"
      :disableClose="disableCloseButton"
    >
      <template>
        <reviewForm
          :reviewFormConfig="reviewFormConfig"
          :ratingByFeatureConfig="ratingByFeatureConfig"
          :productData="productData"
          @closeDialog="$refs.formModalName.closeModal()"
          :orderHistory="orderHistory"
          @disableClose="removeModalClose"
        ></reviewForm>
      </template>
    </modals>
    <modals class="not-eligible" ref="notEligible" :disableClose="true">
      <template>
        <notEligible @closeModals="closeModals" />
      </template>
    </modals>
  </div>
</template>

<script>
import submitButton from "./customer-review/components/submit-button.vue";
import modals from "../../components/modals.vue";
import reviewForm from "../../product-review-form/product-review-form.vue";
import notEligible from "./customer-review/components/not-eligible.vue";
export default {
  name: "no-review",
  props: [
    "reviewFormConfig",
    "ratingByFeatureConfig",
    "productData",
    "ratingByFeaturePDP",
    "productReviewConfig",
    "orderHistory",
  ],
  data() {
    return {
      disableCloseButton: false,
    };
  },
  components: {
    submitButton,
    modals,
    reviewForm,
    notEligible,
  },
  methods: {
    handleOnClick(data) {
      if (data == "notEligible") {
        this.$refs.notEligible.openModal();
      } else {
        this.$refs.formModalName.openModal();
      }
    },
    closeModals() {
      this.$refs.notEligible.closeModal();
      this.$refs.formModalName.closeModal();
    },
    removeModalClose() {
      this.disableCloseButton = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.no-review {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 48px;
  margin-bottom: 48px;

  .no-review-image {
    @media only screen and (max-width: 480px) {
      width: 350px;
    }
  }

  .no-result-text {
    color: #1f1f1f;
    text-align: center;
    font-family: unset;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 600;
    line-height: 130%;
    margin-top: -35px;
    letter-spacing: -0.48px;
  }
  img {
    z-index: 1;
  }
}
.no-review-text {
  color: var(--textHeading, #261a1a);
  text-align: center;
  font-family: unset;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%; /* 31.2px */
  letter-spacing: -0.48px;

  background: radial-gradient(
    63.15% 62.34% at 48.99% -25.32%,
    #9bb4ff 0%,
    rgba(239, 243, 255, 0) 100%
  );
  width: 400px;
  height: 77.387px;
  flex-shrink: 0;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  position: relative;
  bottom: 27px;
}
</style>
