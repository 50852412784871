<template>
  <div class="customer-review-feature">
    <div v-if="productReviewConfig.rating_type == 'star_rating'">
      <starRatingHeader
        :rating_count="reviewMetrics.rating_count"
        :average_rating="averageRating"
        :color="productReviewConfig.star_color"
      />
    </div>
    <div v-if="productReviewConfig.rating_type == 'number_rating'">
      <numberRatingHeader
        :rating_count="reviewMetrics.rating_count"
        :average_total_rating="averageTotalRating"
        :color="productReviewConfig.star_color"
      />
    </div>
    <div
      v-if="productReviewConfig.rating_type == 'both_star_and_number_rating'"
    >
      <starAndNumberRatingHeader
        :rating_count="reviewMetrics.rating_count"
        :average_total_rating="averageTotalRating"
        :color="productReviewConfig.star_color"
      />
    </div>
  </div>
</template>

<script>
import starRatingHeader from "./star-rating-header.vue";
import starAndNumberRatingHeader from "./star-number-rating-header.vue";
import numberRatingHeader from "./number-rating-header.vue";

export default {
  name: "customer-reviews",
  props: ["reviewMetrics", "productReviewConfig"],
  data: {
    average_rating: 0,
    average_total_rating: 0,
  },
  components: {
    starRatingHeader,
    starAndNumberRatingHeader,
    numberRatingHeader,
  },
  computed: {
    averageTotalRating() {
      return Number(
        (
          this.reviewMetrics.rating_sum / this.reviewMetrics.review_count
        ).toFixed(1)
      );
    },
    averageRating() {
      return Math.ceil(
        this.reviewMetrics.rating_sum / this.reviewMetrics.review_count
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.graph-feature-wrapper {
  display: block;
  @media only screen and (min-width: 577px) and (max-width: 992px) {
    display: grid;
    gap: 24px;
    grid-template-columns: 1fr 1fr;
  }
}
</style>
