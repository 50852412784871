<template>
  <div class="question_wrapper">
    <div class="question" v-for="question in additional_questions">
      <div class="input-label">{{ question }}</div>
      <div class="input-wrapper">
        <input class="input-box" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "additional-questions",
  props: ["forms", "additional_questions"],
};
</script>

<style scoped lang="scss">
@import "../form.style.scss";

.question {
  margin-bottom: 16px;
}

.question_wrapper {
  margin-top: 16px;
}
</style>
