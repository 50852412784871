<template>
  <div class="modal-header">
    <div class="review-heading">{{ modalHeading }}</div>
  </div>
</template>

<script>
export default {
  name: "modal-header",
  props: ["modalHeading"],
};
</script>

<style scoped lang="scss">
.modal-header {
  display: flex;
  flex-direction: row;
  margin-bottom: 14px;
  justify-content: space-between;
  .review-heading {
    font-family: unset;
    font-weight: 600;
    font-size: 20px;
    color: #000000;
    margin-bottom: 5px;
  }
}
</style>
