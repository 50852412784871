<template>
  <div>
    <div class="line"></div>
    <div class="input-label">{{ reviewFormConfig.review_heading }}</div>
    <div class="input-wrapper">
      <input
        class="input-box"
        v-model="forms.review.title"
        placeholder="What did you like the most?"
        autofocus
      />
    </div>
    <div class="write-review">
      <div class="input-label">{{ reviewFormConfig.review_body_heading }}</div>
      <!-- <div class="sub-heading" v-if="reviewFormConfig.smart_assist">
        Customers usually mention about |
        <span class="feature">Quality,</span>
        <span class="feature">Delivery,</span>
        <span class="feature">Cost</span>
      </div> -->
      <textarea
        placeholder="Describe your experience while using our product?"
        class="write-review-area"
        v-model="forms.review.description"
      ></textarea>
    </div>
    <div class="recommend">
      <div class="input-label no-margin">Would you recommend this product?</div>
      <div>
        <button
          :class="{ 'recommend-button': true, active: recommend }"
          @click.prevent="$emit('handleRecommend')"
        >
          Yes
        </button>
      </div>
    </div>
    <div class="line"></div>
  </div>
</template>

<script>
export default {
  name: "review-box",
  props: ["forms", "validated", "reviewFormConfig", "recommend"],
};
</script>

<style scoped lang="scss">
@import "../form.style.scss";
.sub-heading {
  color: #828282;
  font-size: 14px;
  font-family: unset;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  .feature {
    margin-right: 6px;
  }
}

.write-review {
  display: flex;
  flex-direction: column;
  margin-top: 12px;
  .write-review-area::-webkit-input-placeholder {
    color: #bdbdbd;
  }
  .write-review-area:focus {
    border: 1px solid var(--dividerStokes, #d4d1d1);
  }
  .write-review-area {
    padding: 16px;
    color: #7d7676;
    font-size: 14px;
    font-weight: 400;

    border-radius: 4px;
    border: 1px solid #d4d1d1;
  }
}
.recommend {
  display: flex;
  justify-content: space-between;
  height: 32px;
  align-items: center;
  margin: 14px 0 0 0;
  .recommend-button {
    font-size: 14px;
    font-family: unset;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.28px;
    text-transform: uppercase;
    border-radius: 4px;
    padding: 5px 16px;
    cursor: pointer;
    border: 1px solid var(--dividerStokes, #d4d1d1);
    color: var(--buttonPrimary);
    background-color: var(--bgColor);
    border-color: var(--buttonPrimary);
  }
  .active {
    background: var(--buttonPrimary, #8b8374);
    color: var(--buttonSecondary, #fff);
    border: 1px solid var(--buttonPrimary, #8b8374);
  }
  .heading {
    margin: 0;
  }
}
.no-margin {
  margin-bottom: 0;
}
</style>
