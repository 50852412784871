<template>
  <div class="number-star">
    <div class="star">
      <div class="average-ratings">{{ average_total_rating }}</div>
      <star-rating
        :rating="average_total_rating"
        :star-size="21"
        :active-color="color"
        :read-only="true"
        :show-rating="false"
        :round-start-rating="false"
        :padding="3"
        :max-rating="5"
      />
    </div>
    <div class="total-rating">{{ rating_count }} Total Rating</div>
  </div>
</template>

<script>
import starRating from "vue-star-rating";

export default {
  name: "star-number-rating-header",
  props: ["color", "rating_count", "average_total_rating"],
  data: { type: "both" },
  components: {
    starRating,
  },
};
</script>

<style scoped lang="scss">
@import "./customer-rating-styles.scss";
</style>
