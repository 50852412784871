<template>
  <div>
    <vue-masonry-wall :items="reviewGroup" :options="options">
      <template v-slot:default="{ item }">
        <review-card
          :review="item"
          :homeReviewCarouselConfig="homeReviewCarouselConfig"
        />
      </template>
    </vue-masonry-wall>
  </div>
</template>

<script>
import VueMasonryWall from "vue-masonry-wall";

import reviewCard from "../components/review-card.vue";

export default {
  name: "masonry-wall",
  data() {
    return {
      options: {
        width: 363,
      },
    };
  },
  props: ["homeReviewCarouselConfig", "reviewGroup"],
  components: {
    VueMasonryWall,
    "review-card": reviewCard,
  },
};
</script>

<style lang="scss" scoped>
::deep .image-block .masonry-wall {
  margin: 0 -6px !important;
}
</style>
