<template>
  <div>
    <button
      class="rating-button"
      v-if="badRatingSelected"
      key="selectedOptionsBad"
    >
      <lottie
        :options="selectedOptionsBad"
        v-on:animCreated="handleAnimation"
      />
    </button>
    <button
      class="rating-button"
      @click="handleBadEmojiClick"
      @mouseenter="handleBardEmojiHover"
      @mouseleave="handleBadEmojiHoverEnd"
      v-else-if="badRatingHover"
      key="hoverOptionsBad"
    >
      <lottie :options="hoverOptionsBad" v-on:animCreated="handleAnimation" />
    </button>
    <button
      class="rating-button"
      v-else-if="badRatingClicked"
      key="badRatingClicked"
    >
      <lottie
        :options="clickedOptionsBad"
        v-on:animCreated="handleAnimationBad"
      />
    </button>
    <button
      class="rating-button"
      @click="handleBadEmojiClick"
      @mouseenter="handleBardEmojiHover"
      @mouseleave="handleBadEmojiHoverEnd"
      key="defaultOptionsBad"
      v-else
    >
      <lottie :options="defaultOptionsBad" v-on:animCreated="handleAnimation" />
    </button>
  </div>
</template>

<script>
import Lottie from "vue-lottie";

import BadEmojiHover from "../../assets/emojis/hover/bad.json";
import BadEmojiDefault from "../../assets/emojis/default/bad.json";
import BadEmojiSelected from "../../assets/emojis/selected/bad.json";
import BadEmojiClicked from "../../assets/emojis/clicked/bad.json";

export default {
  name: "rating-button",
  data() {
    return {
      defaultOptionsBad: { animationData: BadEmojiDefault, loop: true },
      hoverOptionsBad: { animationData: BadEmojiHover, loop: true },
      clickedOptionsBad: { animationData: BadEmojiClicked, loop: true },
      selectedOptionsBad: { animationData: BadEmojiSelected, loop: true },
      animationSpeed: 1,
      badRatingHover: false,
      badRatingSelected: false,
      badRatingClicked: false,
      anim: null, // for saving the reference to the animation
      animBadClicked: null, // for saving the reference to the animation
    };
  },
  props: ["originalRating"],
  components: {
    lottie: Lottie,
  },
  methods: {
    handleAnimation: function (anim) {
      this.anim = anim;
    },
    handleAnimationBad: function (animBad) {
      this.animBad = animBad;
      this.$watch(
        () => {
          return this.anim._completedLoop;
        },
        (val) => {
          if (val && this.originalRating === 1) {
            this.badRatingSelected = true;
          }
        }
      );
    },
    handleBadEmojiClick() {
      this.badRatingHover = false;
      this.$parent.$parent.$emit("ratingClick", 1);
      this.badRatingClicked = true;
    },
    handleBardEmojiHover() {
      this.badRatingHover = true;
    },
    handleBadEmojiHoverEnd() {
      this.badRatingHover = false;
    },
  },
  watch: {
    originalRating(newRating) {
      if (newRating !== 1) {
        this.badRatingClicked = false;
        this.badRatingSelected = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../rating.scss";
</style>
