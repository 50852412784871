<template>
  <div>
    <button
      class="submit-button"
      :style="{
        opacity: checkDisable ? 0.3 : 1,
        cursor: checkDisable ? 'not-allowed' : 'pointer',
      }"
      :disabled="checkDisable"
      @click="$emit('handleSubmit')"
    >
      {{ getBtnText }}
    </button>
  </div>
</template>

<script>
export default {
  name: "submit-button",
  props: {
    buttonText: {
      type: String,
      default: "",
    },
    originalRating: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    getBtnText() {
      return this.buttonText.length >= 25
        ? this.buttonText.substring(0, 25)
        : this.buttonText;
    },
    checkDisable() {
      return this.originalRating < 1;
    },
  },
};
</script>

<style scoped>
.submit-button {
  margin: 24px 0 0 0;

  height: 56px;
  width: 100%;
  padding: 12px 32px;
  border-radius: 4px;
  border: 1px solid var(--buttonPrimary, #8b8374);
  background: var(--buttonPrimary, #8b8374);

  color: var(--buttonSecondary, #fff);
  /* Desktop/Button/Button text - Desktop */
  font-family: unset;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.28px;
  text-transform: uppercase;
}
</style>
