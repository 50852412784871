<template>
  <div>
    <VueSlickCarousel
      v-bind="carouselSettings"
      class="review-carousel-v2"
      ref="carousel"
    >
      <review-card-v2
        v-for="review in topReviewsList"
        :review="review"
        :homeReviewCarouselConfig="homeReviewCarouselConfig"
      />
    </VueSlickCarousel>
    <button
      v-if="checkShowButton"
      class="slick-arrow slick-prev arrow-button"
      @click="showPrev"
    >
      <img src="../../assets/chevron_left_24px.svg" class="next-btn-png" />
    </button>

    <button
      v-if="checkShowButton"
      class="slick-arrow slick-next arrow-button"
      @click="showNext"
    >
      <img src="../../assets/chevron_right_24px.svg" class="next-btn-png" />
    </button>
  </div>
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";

import reviewCardV2 from "../components/review-card-v2.vue";

export default {
  name: "grid-review-carousel",
  data() {
    return {
      carouselSettings: {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 2,
        centerMode: true,
        centerPadding: "20px",
        focusOnSelect: true,
        pauseOnHover: false,
        autoplay: false,
        arrows: false,

        responsive: [
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              infinite: true,
              dots: true,
            },
          },
        ],
      },
      limit: 1,
    };
  },
  props: ["homeReviewCarouselConfig", "topReviewsList"],
  components: {
    VueSlickCarousel,
    "review-card-v2": reviewCardV2,
  },
  mounted() {},
  methods: {
    showPrev() {
      this.$refs.carousel.prev();
    },
    showNext() {
      this.$refs.carousel.next();
    },
  },
  mounted() {
    this.carouselSettings = {
      ...this.carouselSettings,
      dots: this.homeReviewCarouselConfig.slider_pagination,
      centerMode: this.homeReviewCarouselConfig.slider_overflow,
      pauseOnHover: this.homeReviewCarouselConfig.slider_pause_on_hover,
    };
  },
  computed: {
    checkShowButton() {
      return (
        this.homeReviewCarouselConfig.slider_navigation &&
        this.topReviewsList.length > this.limit
      );
    },
  },
};
</script>

<style scoped lang="scss">
::v-deep {
  .slick-dots {
    bottom: -30px !important;
  }
  .slick-dots li button:before {
    color: var(--accentColor, #e7dbc2) !important;
    font-size: 14px !important;
  }

  .slick-dots li.slick-active button:before {
    color: var(--accentColor, #e7dbc2) !important;
  }
}

.slide {
  margin-bottom: "8px";
}

.d-none {
  display: none;
}

.slick-arrow {
  border-radius: 24px;
  background: rgba(20, 19, 14, 0.6);
  height: 48px;
  width: 48px;
  z-index: 1;
}

.slick-next:before {
  display: none !important;
}
.slick-prev:before {
  display: none !important;
}

.slick-disabled {
  display: none;
}
// .slick-next:before,
// .slick-prev:before {
//   font-family: slick;
//   font-size: 20px;
//   line-height: 1;
//   opacity: 0.75;
//   color: rgba(0, 0, 0, 0.656) !important;
//   -webkit-font-smoothing: antialiased;
//   -moz-osx-font-smoothing: grayscale;
//   display: none;
// }

.slick-next.slick-disabled:before,
.slick-prev.slick-disabled:before {
  color: #888 !important;
  display: none !important;
}
.next-btn-png {
  width: 100%;
  height: 100%;
}
.slick-next img {
  height: 24px;
  width: 24px;
}

.slick-prev img {
  height: 24px;
  width: 24px;
}

.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  border-radius: 24px;
  background: rgba(20, 19, 14, 0.6);
  height: 48px;
  width: 48px;
  z-index: 1;
}
</style>
