<template>
  <div class="fw-review-body">
    <div class="comment-heading">
      <!-- <div class="comment-item" v-if="item.review.title">
                {{ item.review.title }}
            </div> -->
    </div>
    <div class="answer-description">
      <div class="comment-description" v-if="item.review.description">
        {{ item.review.description }}
      </div>
    </div>
    <div class="date-time">
      <div class="comment-time">{{ getDate(item) }}</div>
    </div>
  </div>
</template>

<script>
import { convertDate, convertTime } from "./../../utils";

export default {
  name: "review-body",
  props: ["floatingReviewConfig", "item"],
  methods: {
    getTime(items) {
      let date = convertTime(items);
      return date;
    },
    getDate(items) {
      let date = convertDate(items);
      return date;
    },
  },
};
</script>

<style lang="scss" scoped>
.comment-item {
  margin-bottom: 12px;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: -0.02em;
  color: var(--textHeading, #261a1a);
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
}

.date-time {
  display: flex;

  font-size: 12px;
  line-height: 14px;
  letter-spacing: -0.02em;
  color: var(--textLabel, #7d7676);
  padding: 10px 0 14px 0;

  .location-margin {
    margin-right: 4px;
  }
}

.comment-description {
  font-size: 14px;
  line-height: 19px;
  color: var(--textBody, #3c3131);
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 5;
  text-align: left;
}
</style>
