<template>
  <div>
    <button
      class="rating-button"
      v-if="excellentRatingSelected"
      key="selectedOptionsExcellent"
    >
      <lottie
        :options="selectedOptionsExcellent"
        v-on:animCreated="handleAnimation"
      />
    </button>
    <button
      class="rating-button"
      @click="handleExcellentStarClick"
      @mouseenter="handleBardStarHover"
      @mouseleave="handleBadStarHoverEnd"
      v-else-if="excellentRatingHover"
      key="hoverOptionsExcellent"
    >
      <lottie
        :options="hoverOptionsExcellent"
        v-on:animCreated="handleAnimation"
      />
    </button>
    <button
      class="rating-button"
      v-else-if="excellentRatingClicked"
      key="excellentRatingClicked"
    >
      <lottie
        :options="clickedOptionsExcellent"
        v-on:animCreated="handleAnimationExcellent"
      />
    </button>
    <button
      class="rating-button"
      @click="handleExcellentStarClick"
      @mouseenter="handleBardStarHover"
      @mouseleave="handleBadStarHoverEnd"
      key="defaultOptionsExcellent"
      v-else
    >
      <lottie
        :options="defaultOptionsExcellent"
        v-on:animCreated="handleAnimation"
      />
    </button>
  </div>
</template>

<script>
import Lottie from "vue-lottie";

import ExcellentStarHover from "../../assets/stars/hover/excellent.json";
import ExcellentStarDefault from "../../assets/stars/default/excellent.json";
import ExcellentStarSelected from "../../assets/stars/selected/excellent.json";
import ExcellentStarClicked from "../../assets/stars/clicked/excellent.json";

export default {
  name: "rating-button",
  data() {
    return {
      defaultOptionsExcellent: {
        animationData: ExcellentStarDefault,
        loop: true,
      },
      hoverOptionsExcellent: { animationData: ExcellentStarHover, loop: true },
      clickedOptionsExcellent: {
        animationData: ExcellentStarClicked,
        loop: true,
      },
      selectedOptionsExcellent: {
        animationData: ExcellentStarSelected,
        loop: true,
      },
      animationSpeed: 1,
      excellentRatingHover: false,
      excellentRatingSelected: false,
      excellentRatingClicked: false,
      anim: null, // for saving the reference to the animation
      animExcellentClicked: null, // for saving the reference to the animation
    };
  },
  components: {
    lottie: Lottie,
  },
  props: ["originalRating"],
  methods: {
    handleAnimation: function (anim) {
      this.anim = anim;
    },
    handleAnimationExcellent: function (animExcellent) {
      this.animExcellent = animExcellent;
      this.$watch(
        () => {
          return this.anim._completedLoop;
        },
        (val) => {
          if (val && this.originalRating === 5) {
            this.excellentRatingSelected = true;
          }
        }
      );
    },
    handleExcellentStarClick() {
      this.excellentRatingHover = false;
      this.$parent.$parent.$emit("ratingClick", 5);
      this.excellentRatingClicked = true;
    },
    handleBardStarHover() {
      this.excellentRatingHover = true;
    },
    handleBadStarHoverEnd() {
      this.excellentRatingHover = false;
    },
  },
  watch: {
    originalRating(newRating) {
      if (newRating !== 5) {
        this.excellentRatingClicked = false;
        this.excellentRatingSelected = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../rating.scss";
</style>

