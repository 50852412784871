export const APPLICATION_REVIEW_CONFIG = {
  rating_type: "star_rating",
  title: "Rate Your Experience",
  description: "Happy to hear from you",
  button_text: "Submit",
  has_description: true,
  has_map_validation: true,
  map_validation: {
    poor: "Let us know what went wrong",
    bad: "Let us know how we can improve",
    average: "How can we serve you better?",
    good: "Provide us your valuable feedback",
    excellent: "We’re thrilled to hear your experience",
  },
  has_map_tags: true,
  map_tags: {
    poor: ["Helpful", "Delivery", "Quality", "Poor"],
    bad: ["Helpful", "Delivery", "Quality", "Bad"],
    average: ["Helpful", "Delivery", "Quality", "Average"],
    good: ["Helpful", "Delivery", "Quality", "Good"],
    excellent: ["Helpful", "Delivery", "Quality", "excellent"],
  },
};
