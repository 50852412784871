<template>
  <div class="number-star">
    <div class="star">
      <div class="average-ratings">{{ average_total_rating || 0 }}</div>
      <star-rating
        :rating="1"
        v-bind:star-size="21"
        :active-color="color"
        :read-only="true"
        :max-rating="1"
        :show-rating="false"
        :round-start-rating="false"
        :padding="3"
      />
    </div>
    <div class="total-rating">{{ rating_count || 0 }} Total Rating</div>
  </div>
</template>

<script>
import starRating from "vue-star-rating";

export default {
  name: "number-rating",
  props: ["color", "rating_count", "average_total_rating"],
  components: {
    starRating,
  },
};
</script>

<style scoped lang="scss">
@import "./customer-rating-styles.scss";
</style>
