<template>
  <div>
    <div v-for="(media, index) in getMedia" :key="media.type + index">
      <div v-if="media">
        <div
          v-if="media.type == 'image'"
          :class="className ? className : 'media-v1'"
          v-on:click="getReviewIdWithImage(item._id, media.url)"
          class="media-slot"
        >
          <img
            src="../../assets/loader.svg"
            :class="className == 'media-v2' ? 'loader-small' : 'loader'"
            alt=""
            srcset=""
            v-show="showLoader"
          />
          <image-moderation
            :isNSFW="img && img.is_nsfw"
            :mediaUrl="img && img.url"
            :optimizeType="className == 'media-v2' ? 'thumbnail' : 'responsive'"
            :className="['review-img']"
            @image-loaded="onImageLoad"
          ></image-moderation>
        </div>
        <div
          v-if="media.type == 'video'"
          :class="className ? className : 'media-v1 video-wrapper'"
          v-on:click="getReviewIdWithImage(item._id, media.url)"
        >
          <div class="overlay"></div>
          <video class="review-video" loading="lazy">
            <source :src="media.url" type="video/mp4" />
          </video>
          <img
            class="play-icon"
            src="./../../assets/play_button.svg"
            alt=""
            srcset=""
          />
        </div>
      </div>
    </div>
    <!-- <modals class="image_review" ref="imageReview">
      <template>
        <imageReviewItem
          :getBack="closeModals"
          :rid="reviewListId"
          @closeDialog="$refs.imageReview.closeModal()"
        />
      </template>
    </modals> -->
  </div>
</template>
<script>
import modals from "../../components/modals.vue";
import imageReviewItem from "../../components/image-review.vue";
import { resizePixelbinImage, resizeThumbnails } from "./../../utils";
import ImageModeration from "../../components/image-moderation.vue";

export default {
  name: "media",
  props: ["item", "className"],
  data() {
    return {
      reviewListId: null,
      showLoader: true,
    };
  },
  mounted() {},
  computed: {
    getMedia() {
      if (this.item?.media?.total_count > 0) {
        return this.item?.media?.items.slice(0, 1);
      }
    },
  },
  components: { modals, imageReviewItem, ImageModeration },
  methods: {
    closeModals() {
      this.$refs.imageReview.closeModal();
    },
    getReviewIdWithImage(review, url) {
      this.reviewListId = {
        review,
        url,
      };
      this.$refs.imageReview.openModal();
    },
    getOptimizedMedia(url) {
      return resizePixelbinImage(url);
    },
    getThumbnailMedia(url) {
      return resizeThumbnails(url);
    },
    onImageLoad() {
      this.showLoader = false;
    },
  },
};
</script>
<style scoped lang="scss">
.media-v1 {
  border: none;
  background: white;
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;

  img.review-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    aspect-ratio: 1/1;
  }

  video.review-video {
    width: inherit;
    height: auto;
    aspect-ratio: 1/1;
    object-fit: cover;
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.4);
  }

  img.play-icon {
    width: 54px;
    height: 54px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.media-slot {
  display: flex;
  justify-content: center;
  align-items: center;

  .loader {
    width: 100px;
    height: 100px;
  }

  .loader-small {
    width: 40px !important;
    height: 40px !important;
  }
}
.image_review {
  ::v-deep .modal__dialog {
    width: 100%;
    max-width: 946px !important;
    max-height: 600px !important;

    @media only screen and (max-width: 576px) {
      max-width: 100% !important;
    }
  }

  ::v-deep .modal__body {
    padding: 0 !important;

    @media only screen and (max-width: 576px) {
      max-width: 100% !important;
    }
  }
}
.media-v2 {
  border: none;
  background: white;
  width: 60px;
  height: 60px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    aspect-ratio: 1/1;
    border-radius: 4px;
  }
  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    aspect-ratio: 1/1;
    border-radius: 4px;
  }
  .overlay {
    display: none;
  }

  img.play-icon {
    display: none;
  }
}

.media-v3 {
  height: 288px;
  min-height: 288px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    aspect-ratio: 1/1;
    border-radius: 12px;
  }

  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    aspect-ratio: 1/1;
    border-radius: 12px;
  }
  .overlay {
    display: none;
  }

  img.play-icon {
    display: none;
  }

  @media only screen and (min-width: 577px) and (max-width: 650px) {
    height: auto;
    width: 230px;
  }
  @media only screen and (min-width: 651px) and (max-width: 768px) {
    height: 348px;
    width: 348px;
    min-height: 348px;
  }
  @media only screen and (min-width: 769px) and (max-width: 991px) {
    height: 460px;
    width: 460px;
    min-height: 460px;
  }
  @media only screen and (min-width: 992px) {
    height: auto;
    width: 460px;
  }

  @media only screen and (min-width: 1366px) {
    height: auto;
    width: 558px;
  }
}
</style>
