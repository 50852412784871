<template>
  <star-rating
    v-if="ratingType === 'star_rating'"
    :originalRating="originalRating"
  />
  <emoji-rating
    v-else-if="ratingType === 'emoji_rating'"
    :originalRating="originalRating"
  />
  <number-rating
    v-else-if="ratingType === 'number_rating'"
    :originalRating="originalRating"
    :scalableRating="scalableRating"
  />
</template>

<script>
import EmojiRating from "./emoji-rating.vue";
import StarRating from "./star-rating.vue";
import NumberRating from "./number-rating.vue";

export default {
  name: "title",
  components: {
    "emoji-rating": EmojiRating,
    "star-rating": StarRating,
    "number-rating": NumberRating,
  },
  props: {
    ratingType: {
      type: String,
      default: "",
    },
    originalRating: {
      type: Number,
      default: 0,
    },
    scalableRating: {
      type: Number,
      default: 0,
    },
  },
};
</script>
