<template>
  <div>
    <button
      class="rating-button"
      v-if="goodRatingSelected"
      key="selectedOptionsGood"
    >
      <lottie
        :options="selectedOptionsGood"
        v-on:animCreated="handleAnimation"
      />
    </button>
    <button
      class="rating-button"
      @click="handleGoodStarClick"
      @mouseenter="handleBardStarHover"
      @mouseleave="handleBadStarHoverEnd"
      v-else-if="goodRatingHover"
      key="hoverOptionsGood"
    >
      <lottie :options="hoverOptionsGood" v-on:animCreated="handleAnimation" />
    </button>
    <button
      class="rating-button"
      v-else-if="goodRatingClicked"
      key="goodRatingClicked"
    >
      <lottie
        :options="clickedOptionsGood"
        v-on:animCreated="handleAnimationGood"
      />
    </button>
    <button
      class="rating-button"
      @click="handleGoodStarClick"
      @mouseenter="handleBardStarHover"
      @mouseleave="handleBadStarHoverEnd"
      key="defaultOptionsGood"
      v-else
    >
      <lottie
        :options="defaultOptionsGood"
        v-on:animCreated="handleAnimation"
      />
    </button>
  </div>
</template>

<script>
import Lottie from "vue-lottie";

import GoodStarHover from "../../assets/stars/hover/good.json";
import GoodStarDefault from "../../assets/stars/default/good.json";
import GoodStarSelected from "../../assets/stars/selected/good.json";
import GoodStarClicked from "../../assets/stars/clicked/good.json";

export default {
  name: "rating-button",
  data() {
    return {
      defaultOptionsGood: { animationData: GoodStarDefault, loop: true },
      hoverOptionsGood: { animationData: GoodStarHover, loop: true },
      clickedOptionsGood: { animationData: GoodStarClicked, loop: true },
      selectedOptionsGood: { animationData: GoodStarSelected, loop: true },
      animationSpeed: 1,
      goodRatingHover: false,
      goodRatingSelected: false,
      goodRatingClicked: false,
      anim: null, // for saving the reference to the animation
      animGoodClicked: null, // for saving the reference to the animation
    };
  },
  components: {
    lottie: Lottie,
  },
  props: ["originalRating"],
  methods: {
    handleAnimation: function (anim) {
      this.anim = anim;
    },
    handleAnimationGood: function (animGood) {
      this.animGood = animGood;
      this.$watch(
        () => {
          return this.anim._completedLoop;
        },
        (val) => {
          if (val && this.originalRating === 4) {
            this.goodRatingSelected = true;
          }
        }
      );
    },
    handleGoodStarClick() {
      this.goodRatingHover = false;
      this.$parent.$parent.$emit("ratingClick", 4);
      this.goodRatingClicked = true;
    },
    handleBardStarHover() {
      this.goodRatingHover = true;
    },
    handleBadStarHoverEnd() {
      this.goodRatingHover = false;
    },
  },
  watch: {
    originalRating(newRating) {
      if (newRating !== 4) {
        this.goodRatingClicked = false;
        this.goodRatingSelected = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../rating.scss";
</style>
