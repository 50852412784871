<template>
  <div>
    <button
      class="rating-button"
      v-if="poorRatingSelected"
      key="selectedOptionsPoor"
    >
      <lottie
        :options="selectedOptionsPoor"
        v-on:animCreated="handleAnimation"
      />
    </button>
    <button
      class="rating-button"
      @click="handlePoorStarClick"
      @mouseenter="handleBardStarHover"
      @mouseleave="handleBadStarHoverEnd"
      v-else-if="poorRatingHover"
      key="hoverOptionsPoor"
    >
      <lottie :options="hoverOptionsPoor" v-on:animCreated="handleAnimation" />
    </button>
    <button
      class="rating-button"
      v-else-if="poorRatingClicked"
      key="poorRatingClicked"
    >
      <lottie
        :options="clickedOptionsPoor"
        v-on:animCreated="handleAnimationPoor"
      />
    </button>
    <button
      class="rating-button"
      @click="handlePoorStarClick"
      @mouseenter="handleBardStarHover"
      @mouseleave="handleBadStarHoverEnd"
      key="defaultOptionsPoor"
      v-else
    >
      <lottie
        :options="defaultOptionsPoor"
        v-on:animCreated="handleAnimation"
      />
    </button>
  </div>
</template>

<script>
import Lottie from "vue-lottie";

import PoorStarHover from "../../assets/stars/hover/poor.json";
import PoorStarDefault from "../../assets/stars/default/poor.json";
import PoorStarSelected from "../../assets/stars/selected/poor.json";
import PoorStarClicked from "../../assets/stars/clicked/poor.json";

export default {
  name: "rating-button",
  data() {
    return {
      defaultOptionsPoor: { animationData: PoorStarDefault, loop: true },
      hoverOptionsPoor: { animationData: PoorStarHover, loop: true },
      clickedOptionsPoor: { animationData: PoorStarClicked, loop: true },
      selectedOptionsPoor: { animationData: PoorStarSelected, loop: true },
      animationSpeed: 1,
      poorRatingHover: false,
      poorRatingSelected: false,
      poorRatingClicked: false,
      anim: null, // for saving the reference to the animation
      animPoorClicked: null, // for saving the reference to the animation
    };
  },
  components: {
    lottie: Lottie,
  },
  props: ["originalRating"],
  methods: {
    handleAnimation: function (anim) {
      this.anim = anim;
    },
    handleAnimationPoor: function (animPoor) {
      this.animPoor = animPoor;
      this.$watch(
        () => {
          return this.anim._completedLoop;
        },
        (val) => {
          if (val && this.originalRating === 2) {
            this.poorRatingSelected = true;
          }
        }
      );
    },
    handlePoorStarClick() {
      this.poorRatingHover = false;
      this.$parent.$parent.$emit("ratingClick", 2);
      this.poorRatingClicked = true;
    },
    handleBardStarHover() {
      this.poorRatingHover = true;
    },
    handleBadStarHoverEnd() {
      this.poorRatingHover = false;
    },
  },
  watch: {
    originalRating(newRating) {
      if (newRating !== 2) {
        this.poorRatingClicked = false;
        this.poorRatingSelected = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../rating.scss";
</style>
