<template>
  <div>
    <div v-if="!checkIsPlatform">
      <fdk-accounts v-slot="accountsData">
        <button
          :class="getButtonClass"
          @click="
            accountsData.is_logged_in ? onClick() : accountsData.openLogin()
          "
        >
          {{ productReviewConfig.rating_btn_text }}
        </button>
      </fdk-accounts>
    </div>
    <div v-else>
      <button :class="getButtonClass">
        {{ productReviewConfig.rating_btn_text }}
      </button>
    </div>
  </div>
</template>

<script>
import customerServices from "../../../../services/customer-review";
import { fetchOrderHistory } from "../../../../utils";

export default {
  name: "submitButton",
  props: ["productReviewConfig", "className"],
  data() {
    return {
      orderHistory: {},
      userData: {},
      productData: {},
    };
  },
  mounted() {
    window.FPI.state.user.subscribe((user) => {
      this.userData = user;
    });
    window.FPI.state.product.subscribe((product) => {
      this.productData = product;
    });
    this.orderHistory = fetchOrderHistory(
      this.productData?.product.uid,
      this.userData?.user
    );
  },
  computed: {
    getButtonClass() {
      if (this.className) {
        return this.className;
      }
      if (this.productReviewConfig.selected_layout === 2) {
        return "rating-button-layout2";
      }
      if (this.productReviewConfig.selected_layout === 3) {
        return "rating-button-layout3";
      }
      return "rating-button";
    },
    checkIsPlatform() {
      return window.sessionStorage.getItem("isPlatform");
    },
  },
  methods: {
    async onClick() {
      if (this.productReviewConfig?.can_review === "verified_user") {
        try {
          const res = await customerServices.checkCustomerEligibility(
            this.productData?.product.uid
          );

          if (res?.data?.data) {
            this.$emit("onClick");
          } else {
            this.$emit("onClick", "notEligible");
          }
        } catch (error) {
          if (error.response) {
            if (error.response.status === 401) {
              this.$emit("onClick", "notEligible");
            }
          }
        }
      } else {
        this.$emit("onClick");
      }
    },
  },
};
</script>

<style scoped lang="scss">
.rating-button {
  width: 100%;
  height: 56px;
  border: 1px solid var(--buttonPrimary, #8b8374);
  background: var(--buttonPrimary, #8b8374);
  border-radius: 4px;
  margin-top: 16px;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  letter-spacing: -0.02em;
  text-transform: uppercase;
  color: var(--buttonSecondary, #fff);
  justify-content: center;
  cursor: pointer;
}

.rating-button-layout3 {
  width: 171px;
  height: 40px;
  border-radius: 4px;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: -0.02em;
  text-transform: uppercase;
  color: var(--buttonSecondary, #fff);

  display: flex;
  align-items: center;
  justify-content: center;

  border: 1px solid var(--buttonPrimary, #8b8374);
  background: var(--buttonPrimary, #8b8374);
  cursor: pointer;
  @media only screen and (max-width: 577px) {
    height: 44px;
    width: 100%;
    margin-top: 16px;
  }
}
.rating-button-layout2 {
  width: 100%;
  height: 56px;
  margin-top: 16px;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: -0.02em;
  text-transform: uppercase;
  color: var(--buttonSecondary, #fff);

  display: flex;
  align-items: center;
  justify-content: center;

  border: 1px solid var(--buttonPrimary, #8b8374);
  background: var(--buttonPrimary, #8b8374);
  border-radius: 4px;
  cursor: pointer;
}

button:disabled,
button[disabled] {
  opacity: 0.5;
  cursor: not-allowed;
}

.no-review-submit {
  display: flex;
  width: 300px;
  height: 56px;
  padding: 12px 32px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  margin-top: 16px;

  color: var(--buttonSecondary, #fff);
  /* Desktop/Button/Button text - Desktop */
  font-family: unset;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.28px;
  text-transform: uppercase;
  cursor: pointer;
  border-radius: 4px;
  border: 1px solid var(--buttonPrimary, #8b8374);
  background: var(--buttonPrimary, #8b8374);
}
</style>
