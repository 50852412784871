<template>
  <div class="fw-product-card">
    <div class="horizontal-line" v-if="item.brand_name"></div>
    <!-- product card content -->
    <div class="product-content" @click="openProduct(item.product_url)">
      <img
        class="product-img"
        :src="getMedia"
        alt=""
        srcset=""
        v-if="getMedia"
        loading="lazy"
      />
      <div class="info">
        <p class="title">{{ item.brand_name }}</p>
        <p class="sub-title">{{ item.short_description }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "product-card",
  props: ["floatingReviewConfig", "item"],
  mounted() {},
  methods: {
    openProduct(url) {
      if (!this.checkIsPlatform) {
        window.open(url, "_blank");
      }
    },
  },
  computed: {
    getMedia() {
      // return this.item?.product?.images.slice(0, 1)?.url;
      return (
        this.item.images &&
        this.item.images.length &&
        this.item.images.slice(0, 1)
      );
    },
    checkIsPlatform() {
      return window.sessionStorage.getItem("isPlatform");
    },
  },
};
</script>

<style lang="less" scoped>
@import "./../../assets/common-config.less";

.fw-product-card {
  cursor: pointer;

  .horizontal-line {
    border-top: 1px solid var(--dividerStokes, #d4d1d1);
    margin-bottom: 1rem;
  }

  .product-content {
    display: flex;
    gap: 8px;
    align-items: center;

    .product-img {
      width: 37.075px;
      height: 40px;
      object-fit: fill;
      border-radius: 4px;
    }

    .info {
      text-align: left;
      .title {
        color: var(--textHeading, #3c3131);
        font-family: unset;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: 130%;
        margin: 0;
      }

      .sub-title {
        color: var(--textBody, #3c3131);

        /* Body Text/B3 */
        font-family: unset;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%;
        margin: 0;
      }
    }
  }
}
</style>
