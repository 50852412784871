<template>
  <div>
    <VueSlickCarousel
      v-bind="carouselSettings"
      class="carousel cs-rating-review"
      ref="carousel"
    >
      <masonry-wall
        v-for="(item, indx) in groupReviews"
        :key="`head-${indx}`"
        class="image-block"
        :homeReviewCarouselConfig="homeReviewCarouselConfig"
        :reviewGroup="item"
      />
    </VueSlickCarousel>
    <button
      v-if="checkShowButton"
      class="slick-arrow slick-prev arrow-button"
      @click="showPrev"
    >
      <img src="../../assets/chevron_left_24px.svg" class="next-btn-png" />
    </button>

    <button
      v-if="checkShowButton"
      class="slick-arrow slick-next arrow-button"
      @click="showNext"
    >
      <img src="../../assets/chevron_right_24px.svg" class="next-btn-png" />
    </button>
  </div>
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";

import MasonryWall from "./masonry-wall.vue";

export default {
  name: "masonry-review-carousel",
  data() {
    return {
      limit: 1,
      carouselSettings: {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
        centerMode: true,
        focusOnSelect: true,
        pauseOnHover: false,
        centerPadding: "20px",
        autoplay: true,
        arrows: false,
      },
    };
  },
  props: ["homeReviewCarouselConfig", "topReviewsList"],
  components: {
    "masonry-wall": MasonryWall,
    VueSlickCarousel,
  },
  mounted() {
    let width = this.$refs.carousel.$el.offsetWidth;
    if (width < 576) {
      this.limit = 1;
    } else if (width > 576 && width < 899) {
      this.limit = 4;
    } else if (width > 900 && width < 1200) {
      this.limit = 6;
    } else if (width > 1201 && width < 1600) {
      this.limit = 8;
    } else {
      this.limit = 10;
    }

    this.carouselSettings = {
      ...this.carouselSettings,
      dots: this.homeReviewCarouselConfig.slider_pagination,
      centerMode: this.homeReviewCarouselConfig.slider_overflow,
      pauseOnHover: this.homeReviewCarouselConfig.slider_pause_on_hover,
    };
  },
  computed: {
    groupReviews() {
      const result = [];
      let currentGroup = [{ ...this.topReviewsList[0], actualIndex: 0 }];
      let moduloLimit = this.limit;

      for (let i = 1; i < this.topReviewsList.length; i++) {
        if (i % moduloLimit == 0) {
          result.push(currentGroup);
          currentGroup = [{ ...this.topReviewsList[i], actualIndex: i }];
        } else {
          currentGroup.push({ ...this.topReviewsList[i], actualIndex: i });
        }
      }
      currentGroup.length && result.push(currentGroup);
      return result;
    },
    checkShowButton() {
      return (
        this.homeReviewCarouselConfig.slider_navigation &&
        this.topReviewsList.length > this.limit
      );
    },
  },
  methods: {
    showNext() {
      this.$refs.carousel.next();
    },
    showPrev() {
      this.$refs.carousel.prev();
    },
  },
};
</script>

<style lang="scss" scoped>
.slide {
  margin-bottom: 8px;
}

.grid-container {
  display: grid;
  gap: 8px;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 1fr);
}

.d-none {
  display: none;
}

.slick-arrow {
  border-radius: 24px;
  background: rgba(20, 19, 14, 0.6);
  height: 48px;
  width: 48px;
  z-index: 1;
}
.slick-next:before,
.slick-prev:before {
  font-family: slick;
  font-size: 20px;
  line-height: 1;
  opacity: 0.75;
  color: rgba(0, 0, 0, 0.656) !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: none;
}

.slick-next.slick-disabled:before,
.slick-prev.slick-disabled:before {
  color: #888 !important;
}
.next-btn-png {
  width: 100%;
  height: 100%;
}
.slick-next img {
  height: 24px;
  width: 24px;
}

.slick-prev img {
  height: 24px;
  width: 24px;
}

.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  border-radius: 24px;
  background: rgba(20, 19, 14, 0.6);
  height: 48px;
  width: 48px;
  z-index: 1;
}
</style>

<style lang="scss">
.cs-rating-review .slick-track .masonry-wall {
  margin: 0px 0px !important;
}
</style>
