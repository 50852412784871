<template>
  <div v-if="ratingByFeature">
    <div class="line"></div>
    <div>
      <div class="feature-rating-heading">Feature Rating</div>
      <div class="feature-rating" v-for="feature in ratingByFeature">
        <div class="rating">{{ feature }}</div>
        <div>
          <starRating
            @rating-selected="(value) => setRating(value, feature)"
            v-bind:star-size="16"
            :show-rating="false"
            :active-color="starColor"
            inactive-color="#ffffff"
            :border-color="starColor"
            :border-width="2"
            :padding="12"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import starRating from "vue-star-rating";

export default {
  name: "rating-component",
  props: {
    forms: {},
    ratingByFeature: {},
    starColor: { type: String, default: "#8B8374" },
  },
  components: {
    starRating,
  },
  data() {
    return {
      featureValues: [],
    };
  },
  mounted() {
    this.featureValues = this.forms.ratings_by_category.ratings;
  },
  methods: {
    setRating(rating, ratingOf) {
      let ratingExist = false;
      this.featureValues.forEach((item, index) => {
        if (Object.keys(item).includes(ratingOf)) {
          ratingExist = true;
          this.featureValues[index][ratingOf] = rating;
        }
      });

      if (!ratingExist) {
        this.featureValues.push({ [ratingOf]: rating });
      }
      this.$emit("ratingFeature", this.featureValues);
    },
  },
};
</script>

<style scoped lang="scss">
@import "../form.style.scss";
.feature-rating-heading {
  color: #333333;
  font-size: 16px;
  font-family: unset;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  margin-bottom: 12px;
}
.feature-rating {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 12px;

  .rating {
    color: #4f4f4f;
    font-size: 14px;
    font-weight: 400;
    line-height: 140%;
  }
}
</style>
