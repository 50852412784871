<template>
  <div>
    <button
      class="rating-button"
      v-if="goodRatingSelected"
      key="selectedOptionsGood"
    >
      <lottie
        :options="selectedOptionsGood"
        v-on:animCreated="handleAnimation"
      />
    </button>
    <button
      class="rating-button"
      @click="handleGoodEmojiClick"
      @mouseenter="handleBardEmojiHover"
      @mouseleave="handleBadEmojiHoverEnd"
      v-else-if="goodRatingHover"
      key="hoverOptionsGood"
    >
      <lottie :options="hoverOptionsGood" v-on:animCreated="handleAnimation" />
    </button>
    <button
      class="rating-button"
      v-else-if="goodRatingClicked"
      key="goodRatingClicked"
    >
      <lottie
        :options="clickedOptionsGood"
        v-on:animCreated="handleAnimationGood"
      />
    </button>
    <button
      class="rating-button"
      @click="handleGoodEmojiClick"
      @mouseenter="handleBardEmojiHover"
      @mouseleave="handleBadEmojiHoverEnd"
      key="defaultOptionsGood"
      v-else
    >
      <lottie
        :options="defaultOptionsGood"
        v-on:animCreated="handleAnimation"
      />
    </button>
  </div>
</template>

<script>
import Lottie from "vue-lottie";

import GoodEmojiHover from "../../assets/emojis/hover/good.json";
import GoodEmojiDefault from "../../assets/emojis/default/good.json";
import GoodEmojiSelected from "../../assets/emojis/selected/good.json";
import GoodEmojiClicked from "../../assets/emojis/clicked/good.json";

export default {
  name: "rating-button",
  data() {
    return {
      defaultOptionsGood: { animationData: GoodEmojiDefault, loop: true },
      hoverOptionsGood: { animationData: GoodEmojiHover, loop: true },
      clickedOptionsGood: { animationData: GoodEmojiClicked, loop: true },
      selectedOptionsGood: { animationData: GoodEmojiSelected, loop: true },
      animationSpeed: 1,
      goodRatingHover: false,
      goodRatingSelected: false,
      goodRatingClicked: false,
      anim: null, // for saving the reference to the animation
      animGoodClicked: null, // for saving the reference to the animation
    };
  },
  components: {
    lottie: Lottie,
  },
  props: ["originalRating"],
  methods: {
    handleAnimation: function (anim) {
      this.anim = anim;
    },
    handleAnimationGood: function (animGood) {
      this.animGood = animGood;
      this.$watch(
        () => {
          return this.anim._completedLoop;
        },
        (val) => {
          if (val && this.originalRating === 4) {
            this.goodRatingSelected = true;
          }
        }
      );
    },
    handleGoodEmojiClick() {
      this.goodRatingHover = false;
      this.$parent.$parent.$emit("ratingClick", 4);
      this.goodRatingClicked = true;
    },
    handleBardEmojiHover() {
      this.goodRatingHover = true;
    },
    handleBadEmojiHoverEnd() {
      this.goodRatingHover = false;
    },
  },
  watch: {
    originalRating(newRating) {
      if (newRating !== 4) {
        this.goodRatingClicked = false;
        this.goodRatingSelected = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../rating.scss";
</style>
