<template>
  <div>
    <productRatingReview
      :reviewMetrics="reviewMetrics"
      :imagesByProduct="imagesByProduct"
      :reviewListItem="reviewListItem"
      :totalReviews="totalReviews"
      @setSelectedOption="setSelectedOption"
      :productReviewConfig="productReviewConfig"
      @loadMore="loadMore"
      :reviewFormConfig="reviewFormConfig"
      :ratingByFeatureConfig="ratingByFeatureConfig"
      :productData="productData"
      :showLoadMoreButton="showLoadMoreButton"
      @helpfulVotes="helpfulVotes"
      @reportAbuse="reportAbuse"
      :ratingByFeaturePDP="ratingByFeaturePDP"
      :orderHistory="orderHistory"
      @updateOrderHistory="updateOrderHistory"
    />
  </div>
</template>
<script>
import configuration from "./services/configuration";
import reviewFormService from "./services/review-form";
import customerReview from "./services/customer-review";
import productRatingReview from "./rating/rating.vue";
import { fetchOrderHistory } from "./utils";
// import product from "./mocks/product.json";
// window.FPI = {
//   state: {
//     product: {
//       subscribe(cb) {
//         cb(product);
//       },
//     },
//   },
// };

export default {
  name: "Rating",
  components: {
    productRatingReview,
  },
  data() {
    return {
      reviewListId: null,
      paginationConfig: {
        limit: 6,
        current: 1,
        total: 0,
        item_total: 0,
      },
      totalReviews: null,
      reviewListItem: [],
      reviewList: [],
      allImages: {},
      allImageItem: [],
      totalImage: null,
      reviewMetrics: {},
      optionsName: null,
      currentPage: 0,
      date: null,
      productItem: null,
      categoryId: null,
      attributeMetrics: {},
      attributeFeatureAverage: 0,
      productSlug: null,
      selectedOption: "desc_date",
      imagesByProduct: {},
      productReviewConfig: {},
      reviewFormConfig: {},
      ratingByFeatureConfig: [],
      loadMoreClicked: false,
      ratingByFeaturePDP: [],
      orderHistory: {},
      userData: {},
    };
  },
  computed: {
    showLoadMoreButton() {
      return this.reviewListItem?.length < this?.totalReviews;
    },
  },
  mounted() {
    window.FPI.state.product.subscribe(this.getProducts.bind(this));
    this.getProductReviewRatingConfig();
    this.getReviewFormConfig();

    window.FPI.state.user.subscribe((user) => {
      this.userData = user;
    });
  },
  destroy() {
    window.FPI.state.product.unsubscribe(this.getProducts.bind(this));
  },
  methods: {
    getReviewFormConfig() {
      reviewFormService.getReviewForm().then(({ data }) => {
        this.reviewFormConfig = data.data[0]?.config;
      });
    },
    getFeatureRatingPDP(productId) {
      configuration.getFeatureRating(productId).then(({ data }) => {
        this.ratingByFeaturePDP = data.data[0];
      });
    },
    getFeatureRatingConfig(productId) {
      reviewFormService
        .getFeatureReview(productId)
        .then(({ data }) => {
          this.ratingByFeatureConfig = data.data[0];
        })
        .catch((err) => console.log("error", err));
    },
    getProductReviewRatingConfig() {
      let widget = "product_review";
      configuration.getWidgetsConfig(widget).then(({ data }) => {
        this.productReviewConfig = data.data[0]?.config;
      });
    },
    scrollEvent() {
      if (this.allImageItem.length != this.totalImage) {
        this.paginationConfig.current += 1;
        this.getAllImagesByProduct();
      }
    },
    getProducts(product) {
      if (
        this.productSlug != this.$route.params.slug &&
        this.$route.params.slug == product.product.slug
      ) {
        this.productItem = product.product;
        this.productSlug = product.product.slug;
        this.productData = product.product;
        this.reset();
        this.getAllReviewListing(true);
        this.getAllImagesByProduct();
        this.getAllMatrices();
        this.getAttributeMetrics();
        this.getFeatureRatingPDP(product.product.uid);
        this.getFeatureRatingConfig(product.product.uid);
        this.getReviewHistory(product.product.uid);
        // this.getReviewExist(product.product.uid);

        for (let p in this.productItem.categories) {
          this.categoryId = this.productItem.categories[p].uid;
        }
      }
    },
    getReviewIdWithImage(review, url) {
      this.reviewListId = {
        review,
        url,
      };
      this.$refs.imageReview.openModal();
    },
    getReviewHistory(productId) {
      this.orderHistory = fetchOrderHistory(productId, this.userData?.user);
    },
    reset() {
      this.reviewList = [];
      this.reviewListItem = [];
      this.attributeMetrics = {};
      this.allImages = {};
      this.reviewMetrics = {};
      this.allImageItem = [];
    },
    updateOptions(event) {
      this.optionsName = event.target.value;
      this.paginationConfig.current = 1;
      this.getAllReviewListing(true);
    },
    getAttributeMetrics() {
      let tempImage = [];
      customerReview.getAttributeMatrices(this.productItem.uid).then((data) => {
        this.attributeMetrics = data.data;
        for (let p in this.attributeMetrics) {
          this.attributeFeatureAverage = (
            parseFloat(this.attributeMetrics[p].rating_sum) /
            parseFloat(this.attributeMetrics[p].rating_count)
          ).toPrecision(2);
          let object = {
            ...this.attributeMetrics[p],
            average_matrics: this.attributeFeatureAverage,
          };
          tempImage.push(object);
        }
        this.attributeMetrics = tempImage;
      });
    },
    loadMore() {
      this.loadMoreClicked = true;
      this.paginationConfig.current += 1;
      this.getAllReviewListing();
    },
    getAllReviewListing(isOptionChange) {
      let query = {
        page_size: this.paginationConfig.limit,
        page_no: this.paginationConfig.current,
        media_count: 10,
        sort_by: this.selectedOption,
      };
      customerReview
        .getAllReviews(this.productItem.uid, query)
        .then(({ data }) => {
          this.reviewList = data?.items;
          if (isOptionChange) {
            this.reviewListItem = this.reviewList;
          } else {
            this.reviewListItem = [...this.reviewListItem, ...this.reviewList];
          }
          this.totalReviews = data?.page?.item_total;
          this.hasNextReviews = data?.page?.has_next;
        });
    },
    getAllImagesByProduct() {
      let query = {
        page_size: this.paginationConfig.limit,
        page_no: this.paginationConfig.current,
      };
      customerReview
        .getImagesByProduct(this.productItem.uid, query)
        .then((data) => {
          this.imagesByProduct = data.data;
          this.allImages = data.data.items;
          this.totalImage = data.data.page.item_total;
          if (this.allImageItem.length != this.totalImage) {
            for (let i in this.allImages) {
              this.allImageItem.push(this.allImages[i]);
            }
          }
        });
    },
    getAllMatrices() {
      customerReview.getReviewMatrices(this.productItem.uid).then((data) => {
        if (data.data[0]) {
          this.reviewMetrics = JSON.parse(JSON.stringify(data.data[0]));
        }
      });
    },
    helpfulVotes(id) {
      let reviewIndex = this.reviewListItem.findIndex(
        (element) => element.id === id
      );
      const votes = {
        upvote: this.reviewListItem[reviewIndex].vote_count.upvote + 1,
      };
      customerReview
        .postVote(votes, id)
        .then(() => {
          const tempReviewListItem = [...this.reviewListItem];
          tempReviewListItem[reviewIndex].is_upvotable = false;
          tempReviewListItem[reviewIndex].voteSuccess = true;
          this.reviewListItem = JSON.parse(JSON.stringify(tempReviewListItem));
        })
        .catch((e) => {
          console.log(e);
        });
    },
    reportAbuse(id) {
      let reviewIndex = this.reviewListItem.findIndex(
        (element) => element.id === id
      );
      this.reviewListItem[reviewIndex].is_abuse_reportable = false;
      this.reviewListItem = this.reviewListItem.filter(
        (review) => review.is_abuse_reportable
      );
    },
    showMoreImage() {
      if (this.allImageItem.length != this.totalImage) {
        this.paginationConfig.current += 1;
        this.getAllImagesByProduct();
      }
    },
    setSelectedOption(option) {
      this.selectedOption = option;
    },
    updateOrderHistory(historyData) {
      this.orderHistory = historyData;
    },
  },
  watch: {
    selectedOption() {
      this.getAllReviewListing(true);
    },
  },
};
</script>

<style lang="less" scoped></style>
