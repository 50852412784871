<template>
  <div class="qna-item-container">
    <div class="qna-item">
      <div class="qna-profile">
        <div class="profile" v-if="qnaConfig.display_user_avatar">
          <div class="name-index">
            {{ item.created_by.first_name.charAt(0) }}
          </div>
        </div>
        <div class="name-with-verification">
          <div class="name">
            {{ item.created_by.first_name + " " + item.created_by.last_name }}
          </div>
          <!-- <div class="verification-status" v-if="markAsVerified">
            <img
              src="./../../../assets/verified_shopper.svg"
              alt=""
              srcset=""
            />
            <div class="verification-text">Verified Purchase</div>
          </div> -->
        </div>
      </div>
      <div class="qna-content">
        <div class="qna-holder qna-content-items">
          <div class="qna-content-question">{{ item.question.text }}</div>
          <div v-if="item.comment">
            <div v-if="item.comment.items.length">
              <div class="answer-feature">
                <div class="answer">
                  <div class="answer-comment">
                    {{
                      item.comment.items[item.comment.items.length - 1].comment
                    }}
                  </div>
                </div>
                <div class="comment-box">
                  <div class="seller-name">
                    <span class="answer-by">
                      {{ qnaConfig.answered_by_title }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="qnaConfig.show_like_and_dislike">
          <div class="qna-votes qna-content-items">
            <div v-if="checkIsPlatform" class="platform-votes">
              <button class="qna-upvotes">
                <img src="./../../../assets/thumb_up.svg" alt="" />
                <span class="qna-votes-count">12</span>
              </button>
              <button class="qna-downvotes">
                <img src="./../../../assets/thumb_down.svg" alt="" />
                <span class="qna-votes-count">3</span>
              </button>
            </div>
            <div v-else>
              <fdk-accounts v-slot="accountsData">
                <div class="storefront-votes">
                  <button
                    class="qna-upvotes"
                    @click="
                      upvotable && postAnswerLikeVote(item._id, accountsData)
                    "
                    :disabled="!upvotable"
                  >
                    <img src="./../../../assets/thumb_up.svg" alt="" />
                    <span class="qna-votes-count">{{ upVotes }}</span>
                  </button>
                  <button
                    class="qna-downvotes"
                    @click="
                      downvotable &&
                        postAnswerDislikeVote(item._id, accountsData)
                    "
                    :disabled="!downvotable"
                  >
                    <img src="./../../../assets/thumb_down.svg" alt="" />
                    <span class="qna-votes-count">{{ downVotes }}</span>
                  </button>
                </div>
              </fdk-accounts>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import MainServices from "./../../../services/questionAnswer";
import { convertDate, convertTime, fetchOrderHistory } from "./../../../utils";

export default {
  name: "question-answer-item",
  props: ["item", "qnaConfig", "closeAnswer"],
  data() {
    return {
      show: false,
      allComment: null,
      downvotable: this.item.is_downvotable,
      upvotable: this.item.is_upvotable,
      upVotes: (this.item.vote_count && this.item.vote_count.upvote) || 0,
      downVotes: (this.item.vote_count && this.item.vote_count.downvote) || 0,
      readMore: false,
      success: false,
      productData: null,
      productID: null,
      orderHistory: {},
    };
  },
  mounted() {
    window.FPI.state.product.subscribe((product) => {
      this.productData = product;
      this.productID = this.productData?.product.uid;
    });
    window.FPI.state.user.subscribe((user) => {
      this.userData = user;
    });
    this.orderHistory = fetchOrderHistory(
      this.productData?.product.uid,
      this.userData?.user
    );
  },
  computed: {
    checkIsPlatform() {
      return Boolean(window.sessionStorage.getItem("isPlatform"));
    },
    // markAsVerified() {
    //   return Object.keys(this.orderHistory?.ORDERS).length >
    //     this.orderHistory?.VERIFIED_REVIEW_COUNT
    //     ? true
    //     : false;
    // },
  },
  methods: {
    reset() {
      this.show = false;
    },
    getValue(value) {
      this.show = value;
    },
    likeIcon(config) {
      const tag = config.tag && config.tag.find((x) => x.slug == "like_tag");
      return tag && tag.value;
    },
    unlikeIcon(config) {
      const tag = config.tag && config.tag.find((x) => x.slug == "unlike_tag");
      return tag && tag.value;
    },
    getDate(items) {
      let date = convertDate(items);
      return date;
    },
    getTime(items) {
      let date = convertTime(items);
      return date;
    },
    postAnswerDislikeVote(answerId, accountsData) {
      if (!accountsData.is_logged_in) {
        accountsData.openLogin();
        return;
      }
      const params = {
        downvote: 1,
      };
      if (!this.upvotable) {
        params.upvote = -1;
      }
      MainServices.postAnswerVote(answerId, params).then(() => {
        this.downvotable = false;
        this.downVotes = this.downVotes + 1;
        if (!this.upvotable) {
          this.upvotable = true;
          this.upVotes = this.upVotes - 1;
        }
      });
    },
    postAnswerLikeVote(answerId, accountsData) {
      if (!accountsData.is_logged_in) {
        accountsData.openLogin();
        return;
      }
      const params = {
        upvote: 1,
      };
      if (!this.downvotable) {
        params.downvote = -1;
      }
      MainServices.postAnswerVote(answerId, params).then(() => {
        this.upvotable = false;
        this.upVotes = this.upVotes + 1;
        if (!this.downvotable) {
          this.downvotable = true;
          this.downVotes = this.downVotes - 1;
        }
      });
    },
  },
};
</script>

<style lang="less">
@import "./../../../assets/common-config.less";

.qna-item-container {
  .qna-item {
    display: block;
    margin-bottom: 2rem !important;

    .qna-profile {
      display: flex;
      gap: 8px;
      margin-bottom: 1rem !important;

      .profile {
        background: var(--themeAccentL1, #e7dbc2);
        border-radius: 50%;
        width: 36px;
        height: 36px;
        display: flex;
        align-items: center;
        justify-content: center;

        .name-index {
          text-align: center;
          font-family: unset;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          letter-spacing: -0.32px;
          padding: 8px 12px;
          border-radius: 50%;
          color: var(--themeAccentL5, #fff);
          text-transform: capitalize;
        }
      }

      .name-with-verification {
        display: flex;
        flex-direction: column;
        justify-content: center;

        .name {
          color: #3c3131;

          /* Desktop/Heading/Subheading H5 - Desktop */
          font-family: unset;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          letter-spacing: -0.32px;
        }

        .verification-status {
          display: flex;
          gap: 4px;

          .verification-text {
            color: #9c9c9c;

            /* label, caption/small body - Desktop,mob,tab */
            font-family: unset;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            letter-spacing: -0.24px;
          }
        }
      }
    }

    .qna-content {
      display: flex;
      gap: 24px;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: normal;
      align-items: normal;
      align-content: normal;

      .qna-content-items:nth-child(1) {
        display: block;
        flex-grow: 1;
        flex-shrink: 1;
        flex-basis: auto;
        align-self: auto;
        order: 0;
      }

      .qna-content-items:nth-child(2) {
        display: flex;
        flex-grow: 0;
        flex-shrink: 1;
        flex-basis: auto;
        align-self: auto;
        order: 0;
      }

      .qna-holder {
        .qna-content-question {
          color: var(--textHeading, #261a1a);
          font-family: unset;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          letter-spacing: -0.32px;
        }

        .answer-feature {
          .answer-comment {
            color: var(--text-body, #3c3131);

            /* Desktop/Body/B2 - Dektop */
            font-family: unset;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 18px;
            /* 128.571% */
            letter-spacing: -0.28px;
            margin-top: 10px;
          }

          .comment-box {
            .seller-name {
              margin-top: 10px;

              .answer-by {
                color: var(--textLabel, #7d7676);
                /* label, caption/small body - Desktop,mob,tab */
                font-family: unset;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                letter-spacing: -0.24px;
              }
            }
          }
        }
      }

      .qna-votes,
      .platform-votes,
      .storefront-votes {
        display: flex !important;
        gap: 1rem;

        .qna-upvotes,
        .qna-downvotes {
          display: flex;
          align-items: center;
          justify-content: center;
          align-self: flex-start;
          gap: 6px;
          border: none;
          background: none;
          cursor: pointer;

          img {
            background: var(--dividerStokes, #edece9);
            border-radius: 16px;
            padding: 6px;
          }

          .qna-votes-count {
            color: #9c9c9c;
            /* label, caption/small body - Desktop,mob,tab */
            font-family: unset;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            letter-spacing: -0.24px;
          }
        }
      }
    }
  }
}

@media screen and(max-width:576px) {
  .qna-item-container {
    .qna-item {
      .qna-content {
        gap: 4px;
        flex-direction: column;
      }
    }
  }
}
</style>
