<template>
  <div class="validation">
    {{ getValidation }}
  </div>
</template>

<script>
export default {
  name: "validation",
  props: {
    validation: {
      type: Object,
      default() {
        return {};
      },
    },
    originalRating: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      ratingType: ["", "poor", "bad", "average", "good", "excellent"],
    };
  },
  computed: {
    getValidation() {
      let validationForRating = this.ratingType[this.originalRating];
      return this.validation[validationForRating];
    },
  },
};
</script>

<style scoped lang="less">
.validation {
  color: #333;
  text-align: center;
  font-family: unset;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  margin-top: 16px;
  word-break: break-all;
  @media only screen and (max-width: 576px) {
    font-size: 14px;
  }
}
</style>
