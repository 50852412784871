<template>
  <div>
    <div
      class="review-comment-container"
      v-for="(item, reviewIndex) in reviewListItem"
      :key="item._id"
      v-if="productReviewConfig.selected_layout === 1"
    >
      <profile :productReviewConfig="productReviewConfig" :item="item" />
      <rating
        :productReviewConfig="productReviewConfig"
        :item="item"
        v-if="productReviewConfig.selected_layout !== 3"
      />
      <reviewBody :productReviewConfig="productReviewConfig" :item="item" />
      <mediaComponent :productReviewConfig="productReviewConfig" :item="item" />
      <replyComments :productReviewConfig="productReviewConfig" :item="item" />
      <voteComponent
        :productReviewConfig="productReviewConfig"
        :item="item"
        :reviewIndex="reviewIndex"
        :voteSuccess="voteSuccess"
        :productData="productData"
      />
    </div>
    <div v-if="productReviewConfig.selected_layout === 2">
      <vue-masonry-wall
        :items="reviewListItem"
        :options="options"
        @append="handleLoadMore"
      >
        <template v-slot:default="{ item }">
          <div class="multi-grid">
            <profile :productReviewConfig="productReviewConfig" :item="item" />
            <rating :productReviewConfig="productReviewConfig" :item="item" />
            <reviewBody
              :productReviewConfig="productReviewConfig"
              :item="item"
            />
            <mediaComponent
              :productReviewConfig="productReviewConfig"
              :item="item"
            />
            <replyComments
              :productReviewConfig="productReviewConfig"
              :item="item"
            />
            <voteComponent
              :productReviewConfig="productReviewConfig"
              :item="item"
              :voteSuccess="voteSuccess"
              :isMasonryWall="true"
              :productData="productData"
            />
          </div>
        </template>
      </vue-masonry-wall>
    </div>
    <div
      class="review-comment-container3"
      v-for="item in reviewListItem"
      :key="item._id"
      v-if="productReviewConfig.selected_layout === 3"
    >
      <profile :productReviewConfig="productReviewConfig" :item="item">
        <rating :productReviewConfig="productReviewConfig" :item="item" />
      </profile>
      <div class="review-layout-3">
        <reviewBody :productReviewConfig="productReviewConfig" :item="item" />
        <mediaComponent
          :productReviewConfig="productReviewConfig"
          :item="item"
        />
      </div>
      <replyComments :productReviewConfig="productReviewConfig" :item="item" />
      <voteComponent
        :productReviewConfig="productReviewConfig"
        :item="item"
        :reviewIndex="reviewIndex"
        :voteSuccess="voteSuccess"
        :productData="productData"
      />
    </div>
    <div class="line"></div>
    <div>
      <button class="view" v-if="showLoadMoreButton" @click="handleLoadMore">
        Load More
      </button>
    </div>
  </div>
</template>

<script>
import VueMasonryWall from "vue-masonry-wall";

import reviewHeader from "./components/review-header.vue";
import profile from "./components/profile.vue";
import rating from "./components/rating.vue";
import reviewBody from "./components/review-body.vue";
import mediaComponent from "./components/media-component.vue";
import voteComponent from "./components/vote-component.vue";
import replyComments from "./components/reply-comments.vue";

export default {
  name: "Reviews",
  emits: ["loadMore"],
  props: [
    "reviewListItem",
    "totalReviews",
    "productReviewConfig",
    "showLoadMoreButton",
    "voteSuccess",
    "productData",
  ],
  components: {
    reviewHeader,
    profile,
    rating,
    reviewBody,
    mediaComponent,
    voteComponent,
    VueMasonryWall,
    replyComments,
  },
  data() {
    return {
      options: {
        width: 363,
        padding: {
          default: 12,
        },
      },
    };
  },
  methods: {
    handleLoadMore() {
      this.$parent.$parent.$emit("loadMore");
    },
  },
};
</script>

<style scoped lang="scss">
.review-comment-container {
  margin-bottom: 32px;
}
.review-comment-container3 {
  margin-bottom: 24px;
}
.line {
  width: 100%;
  height: 0;
  border-bottom: 1px solid var(--dividerStokes, #d4d1d1);
  display: inline-block;
  @media only screen and (max-width: 576px) {
    width: 100%;
  }
}
.view {
  margin-top: 32px;
  cursor: pointer;
  border: none;
  background-color: transparent;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: -0.02em;
  text-transform: uppercase;
  color: var(--buttonLink, #8b8374);
}

.multi-grid {
  border: 1px solid var(--dividerStokes, #d4d1d1);
  border-radius: 4px;
  padding: 24px;
  background-color: #ffffff;

  @media only screen and (max-width: 576px) {
    padding: 16px;
  }
  @media only screen and (min-width: 577px) and (max-width: 576px) {
    width: 20px;
  }
}

.review-layout-3 {
  display: grid;
  grid-template-columns: 5fr 1fr;
  gap: 24px;
  @media only screen and (max-width: 576px) {
    display: block;
  }
}
</style>
