import axios from "axios";
let { url } = require("./urlset");
const PROXY_BASE_URL = "/ext/reviews/api";

const customerServices = {
  postReportAbuse(reviewId, productId) {
    return axios.patch(
      `${url}${PROXY_BASE_URL}/v2.0/products/${productId}/review/${reviewId}/report-abuse`
    );
  },
  getAllReviews(productid, query) {
    return axios.get(
      `${url}${PROXY_BASE_URL}/v1.0/review-rating/entity/product/entity-id/${productid}/?page_no=${query.page_no}&page_size=${query.page_size}&media_count=${query.media_count}&sort_by=${query.sort_by}`
    );
  },
  getReviewById(id) {
    return axios.get(
      `${url}${PROXY_BASE_URL}/v1.0/review-rating/${id}/?media_count=30`
    );
  },
  postVote(vote, id) {
    return axios.post(
      `${url}${PROXY_BASE_URL}/v1.0/votes/entity/review/entity-id/${id}/`,
      vote
    );
  },
  getAllReviewMatrics(productid) {
    return axios.get(
      `${url}${PROXY_BASE_URL}/v1.0/review-metric/entity/product/entity-id/${productid}/`
    );
  },

  getAttributeMatrices(productid) {
    return axios.get(
      `${url}${PROXY_BASE_URL}/v1.0/attributes/summary/entity/product/entity-id/${productid}/`
    );
  },
  getReviewMatrices(productid) {
    return axios.get(
      `${url}${PROXY_BASE_URL}/v1.0/review/summary/entity/product/entity-id/${productid}/`
    );
  },

  getImagesByProduct(productid, query) {
    return axios.get(
      `${url}${PROXY_BASE_URL}/v1.0/media/entity/product/entity-id/${productid}/?page_no=${query.page_no}&page_size=${query.page_size}`
    );
  },
  reviewExist(productid) {
    return axios.get(
      `${url}${PROXY_BASE_URL}/v2.0/products/${productid}/review/exists`
    );
  },

  getTopReviews(query) {
    return axios.get(
      `${url}${PROXY_BASE_URL}/v2.0/products/review/top?page_no=${query.page_no}&page_size=${query.page_size}`
    );
  },

  getFloatingReviews(query) {
    return axios.get(
      `${url}${PROXY_BASE_URL}/v2.0/products/review/top?page_no=${query.page_no}&page_size=${query.page_size}&floating=${query.floatingType}`
    );
  },

  checkCustomerEligibility(productId) {
    return axios.get(
      `${url}${PROXY_BASE_URL}/v2.0/products/${productId}/check-eligibility`
    );
  },
};
export default customerServices;
