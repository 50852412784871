<template>
  <div :style="addThemeVariables">
    <qnaComponent
      :questionAnswerConfig="questionAnswerConfigs"
      :resources="resources"
      :productItem="productItem"
      :showInput="showInput"
      :paginationConfig="paginationConfig"
      @search-change="handleSearchChange"
      @pagination-change="handlePaginationChange"
    />
  </div>
</template>

<script>
import qnaComponent from "./question-and-answers/index.vue";
import ConfigService from "./services/configuration";
import MainServices from "./services/questionAnswer";
import { colorShadeObject } from "./utils";

// import product from "./mocks/product.json";
// window.FPI = {
//   state: {
//     product: {
//       subscribe(cb) {
//         cb(product);
//       },
//     },
//   },
// };

export default {
  name: "Question",
  components: {
    qnaComponent,
  },
  data() {
    return {
      productItem: null,
      paginationConfig: {
        limit: 4,
        current: 1,
        total: 0,
      },
      questionAnswerConfigs: {},
      pages: null,
      resources: [],
      showInput: false,
      categoryId: null,
      searchQuery: "",
      selectedItem: "desc_date",
    };
  },
  mounted() {
    window.FPI.state.product.subscribe(this.getProducts.bind(this));
    this.getQuestionAnswerConfig();
  },
  destroy() {
    //window.FPI.state.product.unsubscribe(this.getProducts.bind(this));
  },
  methods: {
    handleSearchChange(newValue) {
      this.getAllQuestion(newValue, true);
    },
    handlePaginationChange(pageConfig) {
      this.paginationConfig = pageConfig;
      this.getAllQuestion(null, true);
    },
    getQuestionAnswerConfig() {
      let widget = "questions_answers";
      ConfigService.getWidgetsConfig(widget).then(({ data }) => {
        this.questionAnswerConfigs = data.data[0]?.config;
      });
    },
    getProducts(product) {
      this.productItem = product.product;
      this.paginationConfig.current = 1;
      this.getAllQuestion(null, true);
      for (let p in this.productItem.categories) {
        this.categoryId = this.productItem.categories[p].uid;
      }
    },
    getAllQuestion(event, mergeFalse) {
      if (event) {
        this.searchQuery = event.target.value;
      }
      const query = {
        page_size: this.paginationConfig.limit,
        page_no: this.paginationConfig.current,
        answer_count: 10,
        sort_by: this.selectedItem,
        search: this.searchQuery,
      };
      MainServices.getQuestionAnswer(this.productItem.uid, query).then(
        (resp) => {
          let temp = !mergeFalse
            ? [...this.resources, ...resp.data.items]
            : [...resp.data.items];
          this.pages = resp.data.page;
          this.resources = temp;
          console.log(JSON.parse(JSON.stringify(this.resources)));
          this.showInput =
            this.resources.length == 0 && this.searchQuery.length == 0
              ? false
              : true;
          this.paginationConfig.total = resp.data.page.item_total;
        }
      );
    },
  },
  computed: {
    addThemeVariables() {
      let themeShadeColor =
        getComputedStyle(document.documentElement).getPropertyValue(
          "--themeAccentL1"
        ) || undefined;

      let themeAccent = getComputedStyle(
        document.documentElement
      ).getPropertyValue("--accentColor");

      return !themeShadeColor ? colorShadeObject(themeAccent) : {};
    },
  },
};
</script>

<style lang="less" scoped></style>
