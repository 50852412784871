<template>
  <div class="customer-review">
    <div class="responsive-layout-3" v-if="renderResponsiveView">
      <h1>{{ productReviewConfig.section_heading }}</h1>
      <ratingComponent
        :reviewMetrics="reviewMetrics"
        :productReviewConfig="productReviewConfig"
      />
      <ratingGraph
        :color="productReviewConfig.star_color"
        :reviewMetrics="reviewMetrics"
        :metricList="metricList"
        :theme="productReviewConfig.theme"
      />
      <div>
        <slot></slot>
        <div class="line"></div>
      </div>
      <div v-if="productReviewConfig.has_rating_by_feature">
        <ratingByFeature
          :starColor="productReviewConfig.star_color"
          :selectedLayout="productReviewConfig.selected_layout"
          :ratingByFeaturePDP="ratingByFeaturePDP"
          v-if="checkRatingByFeaturePDP"
        />
      </div>
      <div>
        <div class="feedback-comment">Please help with us your feedback</div>
        <submitButton
          :productReviewConfig="productReviewConfig"
          @onClick="handleOnClick"
          :orderHistory="orderHistory"
        />
      </div>
    </div>
    <div class="tab-layout-3" v-else-if="renderTabView">
      <h1>{{ productReviewConfig.section_heading }}</h1>
      <div class="header-wrapper">
        <ratingComponent
          :reviewMetrics="reviewMetrics"
          :productReviewConfig="productReviewConfig"
        />
        <submitButton
          :productReviewConfig="productReviewConfig"
          @onClick="handleOnClick"
          :orderHistory="orderHistory"
        />
      </div>
      <ratingGraph
        :color="productReviewConfig.star_color"
        :reviewMetrics="reviewMetrics"
        :metricList="metricList"
        :theme="productReviewConfig.theme"
      />
      <div>
        <slot></slot>
        <div class="line"></div>
      </div>
      <div v-if="productReviewConfig.has_rating_by_feature">
        <ratingByFeature
          :selectedLayout="productReviewConfig.selected_layout"
          :ratingByFeaturePDP="ratingByFeaturePDP"
          :starColor="productReviewConfig.star_color"
          v-if="checkRatingByFeaturePDP"
        />
      </div>
    </div>

    <div class="web-layout" v-else>
      <div class="web-layout-3">
        <div>
          <h1>{{ productReviewConfig.section_heading }}</h1>
          <div class="header-wrapper">
            <ratingComponent
              :reviewMetrics="reviewMetrics"
              :productReviewConfig="productReviewConfig"
            />
            <submitButton
              :productReviewConfig="productReviewConfig"
              @onClick="handleOnClick"
              :orderHistory="orderHistory"
            />
          </div>
          <ratingGraph
            :color="productReviewConfig.star_color"
            :reviewMetrics="reviewMetrics"
            :metricList="metricList"
            :theme="productReviewConfig.theme"
          />
        </div>
        <div class="web-image-review">
          <slot></slot>
        </div>
      </div>
      <div class="line"></div>
      <div
        v-if="productReviewConfig.has_rating_by_feature"
        class="margin-bottom"
      >
        <ratingByFeature
          :selectedLayout="productReviewConfig.selected_layout"
          :ratingByFeaturePDP="ratingByFeaturePDP"
          :starColor="productReviewConfig.star_color"
          v-if="checkRatingByFeaturePDP"
        />
        <div class="line"></div>
      </div>
      <div>
        <div class="feedback-comment">Please help with us your feedback</div>
      </div>
    </div>
    <modals
      class="model_rating"
      ref="formModalName"
      :disableClose="disableCloseButton"
    >
      <template>
        <reviewForm
          :reviewFormConfig="reviewFormConfig"
          :ratingByFeatureConfig="ratingByFeatureConfig"
          :productData="productData"
          :orderHistory="orderHistory"
          :productReviewConfig="productReviewConfig"
          @closeDialog="$refs.formModalName.closeModal()"
          @disableClose="removeModalClose"
        ></reviewForm>
      </template>
    </modals>
    <modals class="not-eligible" ref="notEligible" :disableClose="true">
      <template>
        <notEligible @closeModals="closeModals" />
      </template>
    </modals>
  </div>
</template>

<script>
import ratingByFeature from "./components/rating-by-feature.vue";
import ratingGraph from "./components/rating-graph.vue";
import ratingComponent from "./components/rating-component.vue";
import submitButton from "./components/submit-button.vue";
import modals from "../../../components/modals.vue";
import reviewForm from "../../../product-review-form/product-review-form.vue";
import notEligible from "./components/not-eligible.vue";

export default {
  name: "customer-reviews-layout-3",
  props: [
    "reviewMetrics",
    "productReviewConfig",
    "formConfig",
    "reviewFormConfig",
    "ratingByFeatureConfig",
    "productData",
    "ratingByFeaturePDP",
    "orderHistory",
  ],
  components: {
    ratingByFeature,
    ratingComponent,
    ratingGraph,
    submitButton,
    reviewForm,
    modals,
    notEligible,
  },
  data() {
    return {
      disableCloseButton: false,
    };
  },
  computed: {
    metricList() {
      const data = this.reviewMetrics.rating_metric;
      return (data && [...data].reverse()) || [];
    },
    renderResponsiveView() {
      return window.innerWidth < 576;
    },
    renderTabView() {
      return window.innerWidth >= 576 && window.innerWidth < 972;
    },
    renderWebView() {
      return window.innerWidth >= 972;
    },
    checkRatingByFeaturePDP() {
      return this.ratingByFeaturePDP?.length > 0;
    },
  },
  methods: {
    handleOnClick(key) {
      if (key === "notEligible") {
        this.$refs.notEligible.openModal();
      } else {
        this.$refs.formModalName.openModal();
      }
    },
    closeModals() {
      this.$refs.notEligible.closeModal();
      this.$refs.formModalName.closeModal();
    },
    removeModalClose() {
      this.disableCloseButton = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.customer-review {
  h1 {
    font-family: unset;
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 37px;
    letter-spacing: -0.02em;
    color: var(--textHeading, #261a1a);
    margin-top: 0px;
    margin-bottom: 2rem;
  }

  @media only screen and (max-width: 576px) {
    width: 100%;
    padding: 0px;
  }
}

.feedback-comment {
  margin-top: 32px;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  text-align: left;
  letter-spacing: -0.02em;
  color: var(--textHeading, #261a1a);
  display: none;
  @media only screen and (max-width: 577px) {
    display: none;
  }
  @media only screen and (min-width: 577px) and (max-width: 992px) {
    margin-top: 0;
    display: block;
  }
}
.web-layout {
  @media only screen and (max-width: 992px) {
    display: none;
  }
}
.web-layout-3 {
  display: grid;
  gap: 24px;
  grid-template-columns: repeat(2, 1fr);

  @media only screen and (max-width: 577px) {
    display: none;
  }
  @media only screen and (min-width: 577px) and (max-width: 992px) {
    display: grid;
    gap: 24px;
    grid-template-columns: 1fr 1fr;
  }
}
.margin-bottom {
  margin-bottom: 32px;
}
.responsive-layout-3 {
  display: none;
  @media only screen and (max-width: 577px) {
    display: block;
  }
}
.feedback-component {
  width: 171px;
  height: 40px;
  @media only screen and (min-width: 577px) and (max-width: 992px) {
    grid-column-start: 1;
    grid-column-end: 3;
  }
}

.line {
  width: 100%;
  height: 0;
  border-bottom: 1px solid var(--dividerStokes, #e0e0e0);

  @media only screen and (max-width: 576px) {
    width: 100%;
  }
  @media only screen and (min-width: 577px) and (max-width: 992px) {
    display: none;
  }
}

.web-image-review {
  overflow-x: auto;
}
.header-wrapper {
  display: flex;
  justify-content: space-between;
}

.tab-layout-3 {
  display: none;
  @media only screen and (min-width: 577px) and (max-width: 992px) {
    display: block;
  }
}
</style>
