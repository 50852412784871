<template>
  <div class="profile-verified">
    <div class="verified-icon" v-if="item.created_by.account_type == 'user'">
      <div v-if="item.verified">
        <div>
          <img
            src="../../../../../../assets/verified_shopper.svg"
            alt="verified_shopper"
          />
        </div>
        <div class="verified-text">Verified Purchase</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "profile",
  props: ["item"],
};
</script>

<style lang="scss" scoped>
.profile-verified {
  margin-top: 4px;
  display: flex;
  align-items: center;
  justify-content: left;
  .verified-icon {
    margin-right: 2px;
    display: flex;

    justify-content: center;
    align-content: center;
    .verified-text {
      font-size: 12px;
      line-height: 14px;
      letter-spacing: -0.02em;
      color: var(--textLabel, #7d7676);
    }
    img {
      width: 15px;
      height: 15px;
      margin-right: 3px;
    }
  }
}
</style>
