<template>
  <div class="pagignation">
    <!-- <div class="previous">
      <button @click="previous" :disabled="previousDisabled">
        <img class="left-arrow" src="../assets/left.svg" alt="" />
        <span class="text">Previous</span>
      </button>
    </div>
    <div class="next">
      <button @click="next" :disabled="nextDisabled">
        <span class="text">Next</span>
        <img class="right-arrow" src="../assets/right.svg" alt="" />
      </button>
    </div> -->
    <div v-if="!nextDisabled">
      <div class="separator mb-2"></div>
      <div class="see-more-questions" @click="next">
        See More Question And Answer
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "pagination",
  data() {
    return {
      limit: 0,
      total: 0,
      current: 0,
    };
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  created() {
    this.setDefaults();
  },
  methods: {
    change() {
      this.$emit("input", this.value);
      this.$emit("change", this.value);
    },
    setDefaults() {
      if (!this.value.current) {
        this.$set(this.value, "current", 1);
      }
    },
    previous() {
      if (this.value.total) {
        if (this.value.current === 1) {
          return;
        }
        this.value = { ...this.value, current: this.value.current - 1 };
      }
      this.change();
      this.$emit("previousClick");
    },
    next() {
      if (this.value.total) {
        if (this.value.current >= this.pages) {
          this.value.current = this.pages;
          return;
        }
        if (this.pages === 0) {
          this.value.current = 0;
          return;
        }
        this.value = { ...this.value, current: this.value.current + 1 };
      }
      this.change();
      this.$emit("nextClick");
    },
  },
  computed: {
    previousDisabled() {
      return this.value.current === 1 ? true : false;
    },
    nextDisabled() {
      return this.value.current === this.pages ? true : false;
    },
    pages() {
      if (this.value.limit > 0) {
        return Math.ceil(this.value.total / this.value.limit);
      }
      return 0;
    },
    showTotal() {
      if (this.value.total) {
        return true;
      }
      return false;
    },
    showPrev() {
      if (this.value.total && this.value.current === 1) {
        return false;
      }
      return true;
    },
    showNext() {
      if (this.value.total && this.value.current >= this.pages) {
        return false;
      }
      return true;
    },
  },
};
</script>
<style scoped lang="less">
@import "./../assets/common-config.less";

.pagignation {
  .previous {
    margin-right: 30px;

    button {
      border: none;
      background: white;

      .text {
        font-family: unset;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        color: #828282;
        margin-left: 10px;
        cursor: pointer;
      }

      .left-arrow {
        cursor: pointer;
      }
    }

    button:disabled,
    button[disabled] {
      opacity: 0.5;

      .text {
        cursor: not-allowed;
      }

      .left-arrow {
        cursor: not-allowed;
      }
    }
  }

  .next {
    button {
      border: none;
      background: none;

      .text {
        margin-right: 10px;
        font-family: unset;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        color: #828282;
        cursor: pointer;
      }

      .right-arrow {
        cursor: pointer;
      }
    }

    button:disabled,
    button[disabled] {
      opacity: 0.5;

      .text {
        cursor: not-allowed;
      }

      .right-arrow {
        cursor: not-allowed;
      }
    }
  }

  .separator {
    border: 0.5px solid #d4d1d1;
  }

  .see-more-questions {
    color: #b1655b;

    /* Desktop/Button/Button text - Desktop */
    font-family: unset;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.28px;
    text-transform: uppercase;
    cursor: pointer;
  }
}
</style>
