<template>
  <floatingWindow
    v-if="showFloating"
    :key="floatingReviewConfig.heading ? floatingReviewConfig.heading : ''"
    :floatingReviewConfig="floatingReviewConfig"
    :reviewListItem="reviewListItem"
    :disableShowMore="disableShowMore"
    @more-reviews="showMoreReviews"
  ></floatingWindow>
</template>

<script>
import floatingWindow from "./floating-window/index.vue";
import configuration from "./services/configuration";
import customerReview from "./services/customer-review";
import Pages from "./constants";

export default {
  name: "FloatingWindow",
  components: {
    floatingWindow,
  },
  data() {
    return {
      options: {
        width: 264,
        padding: {
          default: 12,
        },
      },
      selectedOption: "desc_date",
      floatingReviewConfig: {},
      reviewListItem: [],
      disableShowMore: false,
      showFloating: false,
      currentPage: null,
      paginationConfig: {
        limit: 14,
        current: 1,
        total: null,
        hasNextPage: null,
        totalPages: null,
      },
    };
  },
  mounted() {
    //this.currentPage = window.FPI.utility.convertUrlToAction(window.location.pathname).page.type;

    this.getProductReviewRatingConfig();
    this.getAllTopReviews();
  },
  destroy() {},
  methods: {
    showMoreReviews() {
      if (this.paginationConfig.hasNextPage) {
        this.paginationConfig.current++;
        this.getAllTopReviews(false);
      }
    },
    showFloatingWindow() {
      this.currentPage = window.FPI?.utility?.convertUrlToAction(
        window.location.pathname
      ).page.type;

      const showPages = this.floatingReviewConfig.show_pages.map((page) => {
        if (Pages[page.value]) {
          return Pages[page.value];
        }
        return page.value;
      });
      this.showFloating = showPages?.includes(this.currentPage);
      return this.showFloating;
    },
    getProductReviewRatingConfig() {
      let widget = "floating_review";
      configuration.getWidgetsConfig(widget).then(({ data }) => {
        this.floatingReviewConfig = data.data[0]?.config;
        this.showFloatingWindow();
      });
    },
    getAllTopReviews(isOptionChange) {
      let query = {
        page_size: this.paginationConfig.limit,
        page_no: this.paginationConfig.current,
        sort_by: this.selectedOption,
        floatingType: true,
      };
      customerReview.getFloatingReviews(query).then(({ data }) => {
        this.reviewList = data?.data?.docs;
        this.paginationConfig.total = data?.data?.totalDocs;
        this.paginationConfig.totalPages = data?.data?.totalPages;
        this.paginationConfig.hasNextPage = data?.data?.hasNextPage;
        if (isOptionChange) {
          this.reviewListItem = this.reviewList;
        } else {
          this.reviewListItem = [...this.reviewListItem, ...this.reviewList];
        }
        this.totalReviews = data?.data?.totalDocs;
        this.hasNextReviews = data?.data?.hasNextPage;

        if (this.paginationConfig.hasNextPage == false) {
          this.disableShowMore = true;
        }
      });
    },
  },
  computed: {},
  watch: {
    selectedOption() {
      this.getAllTopReviews(true);
    },
  },
};
</script>

<style lang="less" scoped></style>
