<template>
  <image-resize
    :optimizeType="optimizeType"
    :mediaUrl="checkForNSFW"
    :className="className"
    :isNSFW="isNSFW"
    @img-loaded="imageLoaded"
  ></image-resize>
</template>

<script>
import imageResize from "./image-resize.vue";
import backUpImage from "./../assets/nsfw.svg";
export default {
  components: { imageResize },
  name: "image-moderation",
  props: ["isNSFW", "mediaUrl", "className", "optimizeType"],

  mounted() {},
  methods: {
    imageLoaded(data) {
      this.$emit("image-loaded", data);
    },
  },
  computed: {
    checkForNSFW() {
      return this.isNSFW ? backUpImage : this.mediaUrl;
    },
  },
};
</script>

<style lang="less" scoped></style>
