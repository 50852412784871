<template>
  <div>
    <button
      class="rating-button"
      v-if="averageRatingSelected"
      key="selectedOptionsAverage"
    >
      <lottie
        :options="selectedOptionsAverage"
        v-on:animCreated="handleAnimation"
      />
    </button>
    <button
      class="rating-button"
      @click="handleAverageStarClick"
      @mouseenter="handleBardStarHover"
      @mouseleave="handleBadStarHoverEnd"
      v-else-if="averageRatingHover"
      key="hoverOptionsAverage"
    >
      <lottie
        :options="hoverOptionsAverage"
        v-on:animCreated="handleAnimation"
      />
    </button>
    <button
      class="rating-button"
      v-else-if="averageRatingClicked"
      key="averageRatingClicked"
    >
      <lottie
        :options="clickedOptionsAverage"
        v-on:animCreated="handleAnimationAverage"
      />
    </button>
    <button
      class="rating-button"
      @click="handleAverageStarClick"
      @mouseenter="handleBardStarHover"
      @mouseleave="handleBadStarHoverEnd"
      key="defaultOptionsAverage"
      v-else
    >
      <lottie
        :options="defaultOptionsAverage"
        v-on:animCreated="handleAnimation"
      />
    </button>
  </div>
</template>

<script>
import Lottie from "vue-lottie";

import AverageStarHover from "../../assets/stars/hover/average.json";
import AverageStarDefault from "../../assets/stars/default/average.json";
import AverageStarSelected from "../../assets/stars/selected/average.json";
import AverageStarClicked from "../../assets/stars/clicked/average.json";

export default {
  name: "rating-button",
  data() {
    return {
      defaultOptionsAverage: { animationData: AverageStarDefault },
      hoverOptionsAverage: { animationData: AverageStarHover },
      clickedOptionsAverage: {
        animationData: AverageStarClicked,
        playCount: 1,
        loop: false,
      },
      selectedOptionsAverage: {
        animationData: AverageStarSelected,
      },
      animationSpeed: 1,
      averageRatingHover: false,
      averageRatingSelected: false,
      averageRatingClicked: false,
      anim: null, // for saving the reference to the animation
      animAverageClicked: null, // for saving the reference to the animation
    };
  },
  components: {
    lottie: Lottie,
  },
  props: ["originalRating"],
  methods: {
    handleAnimation: function (anim) {
      this.anim = anim;
    },
    handleAnimationAverage: function (animAverage) {
      this.animAverage = animAverage;
      this.$watch(
        () => {
          return this.anim._completedLoop;
        },
        (val) => {
          if (val && this.originalRating === 3) {
            this.averageRatingSelected = true;
          }
        }
      );
    },
    handleAverageStarClick() {
      this.averageRatingHover = false;
      this.$parent.$parent.$emit("ratingClick", 3);
      this.averageRatingClicked = true;
    },
    handleBardStarHover() {
      this.averageRatingHover = true;
    },
    handleBadStarHoverEnd() {
      this.averageRatingHover = false;
    },
  },
  watch: {
    originalRating(newRating) {
      if (newRating !== 3) {
        this.averageRatingClicked = false;
        this.averageRatingSelected = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../rating.scss";
</style>
