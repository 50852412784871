<template>
  <div class="share" v-if="showSharingLink">
    <p>Share your excitement with the world.</p>
    <div class="action-icons">
      <span @click="copyUrl" id="shareButton" class="action-button-share">
        <inline-svg :src="'share-icon'"></inline-svg>
      </span>
      <span
        v-if="showCopy"
        @click="copyCoupon"
        id="copyButton"
        class="action-button-copy"
      >
        <inline-svg :src="'copy-clipboard'"></inline-svg>
      </span>
    </div>
  </div>
</template>

<script>
import inlineSvg from "./inline-svg.vue";

export default {
  name: "share-copy-button",
  props: {
    showSharingLink: { type: Boolean },
    showCopy: { type: Boolean, default: true },
  },
  components: { inlineSvg },
  methods: {
    copyCoupon() {
      let text = document.getElementById("coupon").innerHTML;
      let copyButton = document.getElementById("copyButton");

      const copyContent = async () => {
        try {
          await navigator.clipboard.writeText(text);
          copyButton.classList.add("active");
          setTimeout(function () {
            copyButton?.classList?.remove("active");
          }, 2500);
        } catch (err) {
          console.error("Failed to copy: ", err);
        }
      };
      copyContent();
    },
    copyUrl() {
      let text = window.location.href;
      let shareButton = document.getElementById("shareButton");

      const copyContent = async () => {
        try {
          await navigator.clipboard.writeText(text);
          shareButton.classList.add("active");
          setTimeout(function () {
            shareButton?.classList?.remove("active");
          }, 2500);
        } catch (err) {
          console.error("Failed to copy: ", err);
        }
      };
      copyContent();
    },
  },
};
</script>

<style lang="less" scoped>
.share {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 24px;
  padding: 24px 0px;
  border-top: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0;
  width: 100%;
  .action-icons {
    display: flex;
  }
}

.action-button-share {
  position: relative;
}
.action-button-share span {
  color: #fff;
  font-size: 18px;
  cursor: pointer;
  margin-left: 8px;
}

.action-button-share span:before {
  content: "Product Url copied";
  position: absolute;
  top: -38px;
  right: 0px;
  background: var(--buttonPrimary, #8b8374);
  padding: 8px 10px;
  border-radius: 10px;
  font-size: 15px;
  width: 100px;
  display: none;
}
.action-button-share span:after {
  content: "";
  position: absolute;
  top: -12px;
  right: 25px;
  width: 10px;
  height: 10px;
  background: var(--buttonPrimary, #8b8374);
  transform: rotate(45deg);
  display: none;
}
.action-button-share.active span:before,
.action-button-share.active span:after {
  display: block;
}

.action-button-copy {
  position: relative;
}
.action-button-copy span {
  color: #fff;
  font-size: 18px;
  cursor: pointer;
  margin-left: 8px;
}

.action-button-copy span:before {
  content: "Coupon copied";
  position: absolute;
  top: -50px;
  right: 0px;
  background: var(--buttonPrimary, #8b8374);
  padding: 8px 10px;
  border-radius: 10px;
  font-size: 15px;
  width: 100px;
  display: none;
}
.action-button-copy span:after {
  content: "";
  position: absolute;
  top: -10px;
  right: 25px;
  width: 10px;
  height: 10px;
  background: var(--buttonPrimary, #8b8374);
  transform: rotate(45deg);
  display: none;
}
.action-button-copy.active span:before,
.action-button-copy.active span:after {
  display: block;
}
</style>
