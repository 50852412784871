<template>
  <div class="tag-list">
    <div
      v-for="tag in getTags"
      :value="tag"
      :key="tag"
      class="chip"
      @click="toggleTag($event)"
      :class="{ selected: checkIfIncludesTag(tag) }"
    >
      {{ tag }}
    </div>
  </div>
</template>

<script>
export default {
  name: "tags",
  props: {
    tags: {
      type: Object,
      default() {
        return {};
      },
    },
    originalRating: {
      type: Number,
      default: 0,
    },
    selectedTag: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      ratingType: ["", "poor", "bad", "average", "good", "excellent"],
    };
  },
  computed: {
    getTags() {
      let tagForRating = this.ratingType[this.originalRating];
      return this.tags[tagForRating];
    },
  },
  methods: {
    toggleTag(event) {
      this.$emit("toggleTag", event.target.getAttribute("value"));
    },
    checkIfIncludesTag(tag) {
      return this.selectedTag.includes(tag);
    },
  },
};
</script>

<style scoped lang="less">
.tag-list {
  margin-top: 16px;
  display: flex;
  flex-wrap: wrap;
  padding: 0 24px;
  justify-content: center;
}

.chip {
  color: var(--themeAccentD2, #8b8374);
  font-family: unset;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.28px;

  padding: 8px 16px;
  margin: 0 8px 8px 0;
  position: relative;
  overflow: hidden;
  transform: translateZ(0);
  cursor: pointer;

  border-radius: 4px;
  border: 1px solid var(--dividerStokes, #d4d1d1);
  background: var(--buttonSecondary, #fff);

  @media only screen and (max-width: 576px) {
    padding: 3px 10px;
    font-size: 12px;
  }
}

.selected {
  border-radius: 4px;
  border: 1px solid var(--themeAccentL1, #e7dbc2);
  background: var(--themeAccentL4, #faf8f3);

  font-family: unset;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.28px;
}
</style>
