<template>
  <div
    v-if="productReviewConfig.is_active"
    :style="getThemeVariables"
  >
    <div :class="getCustomerReviewsClass" v-if="reviewListItem.length > 0">
      <customerReviews
        v-if="productReviewConfig.selected_layout === 1"
        :reviewMetrics="reviewMetrics"
        :productReviewConfig="productReviewConfig"
        :reviewFormConfig="reviewFormConfig"
        :productData="productData"
        :ratingByFeatureConfig="ratingByFeatureConfig"
        :ratingByFeaturePDP="ratingByFeaturePDP"
        :orderHistory="orderHistory"
      />
      <customerReviewsLayout2
        v-if="productReviewConfig.selected_layout === 2"
        :reviewMetrics="reviewMetrics"
        :productReviewConfig="productReviewConfig"
        :reviewFormConfig="reviewFormConfig"
        :productData="productData"
        :ratingByFeatureConfig="ratingByFeatureConfig"
        :ratingByFeaturePDP="ratingByFeaturePDP"
        :orderHistory="orderHistory"
      />
      <customerReviewsLayout3
        v-if="productReviewConfig.selected_layout === 3"
        :reviewMetrics="reviewMetrics"
        :productReviewConfig="productReviewConfig"
        :reviewFormConfig="reviewFormConfig"
        :productData="productData"
        :ratingByFeatureConfig="ratingByFeatureConfig"
        :ratingByFeaturePDP="ratingByFeaturePDP"
        :orderHistory="orderHistory"
      >
        <reviewWithImage
          :productReviewConfig="productReviewConfig"
          :imagesByProduct="imagesByProduct"
        />
      </customerReviewsLayout3>

      <div class="image-review">
        <reviewWithImage
          v-if="productReviewConfig.selected_layout !== 3"
          :productReviewConfig="productReviewConfig"
          :imagesByProduct="imagesByProduct"
        />
        <topReviews
          :reviewListItem="reviewListItem"
          :totalReviews="totalReviews"
          :productReviewConfig="productReviewConfig"
          :showLoadMoreButton="showLoadMoreButton"
          :voteSuccess="voteSuccess"
          :productData="productData"
        />
      </div>
    </div>
    <noReview
      v-if="reviewListItem.length == 0 && lazyLoad"
      :productReviewConfig="productReviewConfig"
      :reviewFormConfig="reviewFormConfig"
      :productData="productData"
      :ratingByFeatureConfig="ratingByFeatureConfig"
      :ratingByFeaturePDP="ratingByFeaturePDP"
      :orderHistory="orderHistory"
    ></noReview>
  </div>
</template>

<script>
import starRating from "vue-star-rating";

import customerReviews from "./components/customer-review/customer-reviews.vue";
import customerReviewsLayout2 from "./components/customer-review/customer-reviews-layout2.vue";
import customerReviewsLayout3 from "./components/customer-review/customer-reviews-layout3.vue";

import reviewWithImage from "./components/review-with-image/review-with-image.vue";
import topReviews from "./components/top-reviews/top-reviews.vue";
import noReview from "./components/no-review.vue";
import { platformColorObject, colorShadeObject } from "../utils";

export default {
  name: "rating-layout1",
  props: [
    "reviewMetrics",
    "reviewListItem",
    "totalReviews",
    "imagesByProduct",
    "productReviewConfig",
    "reviewFormConfig",
    "ratingByFeatureConfig",
    "productData",
    "showLoadMoreButton",
    "voteSuccess",
    "ratingByFeaturePDP",
    "orderHistory",
    "theme",
  ],
  components: {
    starRating,
    customerReviews,
    reviewWithImage,
    topReviews,
    customerReviewsLayout2,
    customerReviewsLayout3,
    noReview,
  },
  data() {
    return {
      lazyLoad: null,
    };
  },
  computed: {
    getCustomerReviewsClass() {
      if (
        this.productReviewConfig?.selected_layout === 2 ||
        this.productReviewConfig?.selected_layout === 3
      ) {
        return "rating-container rating-container-layout";
      }
      return "rating-container";
    },
    checkIsPlatform() {
      return window.sessionStorage.getItem("isPlatform");
    },
    getThemeVariables() {
      return this.checkIsPlatform
        ? this.getPlatformVariable
        : this.getStoreFrontVariable;
    },
    getPlatformVariable() {
      return platformColorObject(this.theme);
    },
    getStoreFrontVariable() {
      let themeShadeColor =
        getComputedStyle(document.documentElement).getPropertyValue(
          "--themeAccentL1"
        ) || undefined;

      let themeAccent = getComputedStyle(
        document.documentElement
      ).getPropertyValue("--accentColor");

      return !themeShadeColor ? colorShadeObject(themeAccent) : {};
    },
  },
  mounted() {
    setTimeout(() => {
      this.lazyLoad = true;
    }, 2000); // 2000 milliseconds = 2 seconds
  },
};
</script>

<style lang="scss" scoped>
// @import "./rating.style.scss";
.rating-container {
  display: grid;
  justify-content: start;
  grid-column-gap: 2%;
  grid-template-columns: 30% 68%;
  margin: 4rem 0;

  @media only screen and (max-width: 576px) {
    display: flex;
    flex-direction: column;
    margin: 16px;
  }
  @media only screen and (min-width: 577px) and (max-width: 992px) {
    display: flex;
    flex-direction: column;
    margin: 24px;
  }
}
.rating-container-layout {
  display: block;
}

.image-review {
  @media only screen and (max-width: 576px) {
    width: 100%;
    margin: 0px;
  }
}

// .ratings {
//   -webkit-animation: fade-in 1.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
//   animation: fade-in 1.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
// }
// @-webkit-keyframes fade-in {
//   0% {
//     opacity: 0;
//   }
//   100% {
//     opacity: 1;
//   }
// }
// @keyframes fade-in {
//   0% {
//     opacity: 0;
//   }
//   100% {
//     opacity: 1;
//   }
// }
</style>
