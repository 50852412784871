<template>
  <div class="verified-tags" v-if="config.slug == 'tags'">
    <div class="verified-icon" v-if="item.created_by.account_type == 'user'">
      <div v-if="config.tag && config.tag[0]">
        <img :src="config.tag[0].value" alt="verified_shopper" loading="lazy" />
      </div>
      <div v-else>
        <img
          src="../../../../../../assets/verified_shopper.svg"
          alt="verified_shopper"
        />
      </div>
      <div class="verified-text" :style="{ color: config.colour }">
        {{ config.custom_text }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "review-header",
  props: ["config", "item"],
};
</script>

<style lang="scss" scoped>
.verified-tags {
  margin: 7px 0px 7px 0px;
  .verified-icon {
    display: flex;

    align-items: center;
    img {
      width: 14px;
      height: 14px;
    }
  }
  .verified-text {
    font-family: unset;
    margin-left: 4px;
    font-size: 12px;
    line-height: 15px;
  }
}
</style>
