<template>
  <div>
    <modals class="slickModal" ref="slickCarouselModal">
      <template>
        <VueSlickCarousel
          v-bind="carouselSettings"
          class="carousel floatingreview-carousel"
          ref="carousel"
          v-if="reviewList.length"
        >
          <div v-for="list in reviewList" :key="list._id">
            <floatingReviewItem :reviewItemRef="list" />
          </div>
        </VueSlickCarousel>
        <button class="slick-arrow slick-prev arrow-button" @click="showPrev">
          <img src="../../assets/chevron_left_24px.svg" class="next-btn-png" />
        </button>

        <button class="slick-arrow slick-next arrow-button" @click="showNext">
          <img src="../../assets/chevron_right_24px.svg" class="next-btn-png" />
        </button>
      </template>
    </modals>
  </div>
</template>

<script>
import allImage from "./../../components/all-image.vue";
import customerReview from "../../services/customer-review";
import configuration from "../../services/configuration";
import { convertDate, convertTime } from "../../utils";
import starRating from "vue-star-rating";
import modals from "./../../components/modals.vue";
// import imageReviewItem from "../../components/image-review.vue";
import floatingReviewItem from "./floating-review-item.vue";

import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";

export default {
  name: "floating-review-slide",
  props: { reviewList: {}, currentSlideIndex: {} },
  components: {
    starRating,
    modals,
    allImage,
    VueSlickCarousel,
    floatingReviewItem,
  },
  data() {
    return {
      success: false,
      votes: {
        upvote: 0,
      },
      count: 0,
      activeIndex: null,
      configImageReview: null,
      reviewItem: null,
      current: 0,
      direction: 1,
      transitionName: "fade",
      show: false,
      currentSlide: 0,
      goToTimeoutRef: null,

      carouselSettings: {
        arrows: true,
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: false,
        focusOnSelect: true,
        pauseOnHover: false,
        lazyLoad: "ondemand",
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      },
    };
  },
  mounted() {
    this.getConfigReviewRating();
    this.$refs.slickCarouselModal.openModal();
    //this.getAllReviewListingById();
    this.show = true;
    this.currentSlide = this.currentSlideIndex;
  },
  computed: {
    getReviewList() {
      return this.reviewList;
    },
  },
  methods: {
    backToGallery() {
      this.getBack();
    },
    helpfulVotes(id) {
      this.votes.upvote += 1;
      customerReview.postVote(this.votes, id).then(() => {
        this.success = true;
        this.reviewItem.is_upvotable = false;
      });
    },
    getImages(item) {
      let arr = [];
      for (let i of item) {
        if (i.type == "image") {
          arr.push(i);
        }
      }
      return arr;
    },
    video(item) {
      let arr = [];
      for (let i of item) {
        if (i.type == "video") {
          arr.push(i);
        }
      }
      return arr;
    },
    getTime(items) {
      let date = convertTime(items);
      return date;
    },
    getDate(items) {
      let date = convertDate(items);
      return date;
    },
    getConfigReviewRating() {
      let widget = "product_review";
      configuration.getWidgetsConfig(widget).then(({ data }) => {
        this.configImageReview = data.data;
      });
    },
    getFirstLetter(name) {
      if (name) {
        return name.split("")[0];
      }
    },
    getAllReviewListingById() {
      customerReview.getReviewById(this.rid?.review).then((data) => {
        this.reviewItem = data.data;
        let mediaURl = this.rid.url;
        let index = this.reviewItem.media.items.findIndex(
          (img) => img.url === mediaURl
        );
        [this.reviewItem.media.items[0], this.reviewItem.media.items[index]] = [
          this.reviewItem.media.items[index],
          this.reviewItem.media.items[0],
        ];
      });
    },
    slide(dir) {
      this.direction = dir;
      dir === 1
        ? (this.transitionName = "slide-next")
        : (this.transitionName = "slide-prev");
      if (
        this.reviewItem &&
        this.reviewItem.media &&
        this.reviewItem.media.items
      ) {
        let len = this.reviewItem.media.items.length;
        this.current = (this.current + (dir % len) + len) % len;
      }
    },
    showPrev() {
      this.$refs.carousel.prev();
    },
    showNext() {
      this.$refs.carousel.next();
    },
    goTo(index) {
      this.$refs.carousel.goTo(index);
    },
  },
  watch: {
    currentSlide(value) {
      //TODO: need to remove timeout
      if (this.goToTimeoutRef !== null) {
        clearTimeout(this.goToTimeoutRef);
      }
      this.goToTimeoutRef = setTimeout(() => {
        this.goTo(value);
      }, 100);
    },
  },
};
</script>
<style lang="less" scoped>
.slickModal {
  ::v-deep .modal__dialog {
    max-width: 946px;
    max-height: 100%;
    overflow-y: scroll;
    height: 549px;
  }

  ::v-deep .modal__body {
    padding: 0 !important;
    overflow: hidden;
  }
}

.shimmer {
  display: flex;
  justify-content: center;
  margin-top: 60%;
}
.image-video-section {
  width: 100%;
  height: 100%;
  .img-review {
    width: 100%;
    height: 100%;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
      background-color: transparent;
    }
    video {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
      background-color: transparent;
    }
  }
}
.number {
  display: flex;
  flex-direction: row;
}
.verified-icon {
  img {
    width: 15px;
    height: 15px;
    object-fit: contain;
  }
}
.star-rating {
  display: flex;
  .rating {
    margin-right: 6px;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.02em;
    color: #261a1a;
  }
}
.number-star-rating {
  display: flex;
  flex-direction: row;
  align-items: center;
  .number-average-rating {
    margin-right: 7px;
  }
}
.icon-text {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.review-with-images {
  overflow: hidden;
}

.selected {
  display: none;
}
.review-config {
  margin: 12px 0px;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: -0.02em;
  color: #261a1a;
}
.heading {
  font-family: unset;
  font-weight: 600;
  font-size: 20px;
  line-height: 140%;
  color: #41434c;
  margin: 0px 0px 0px 21px;
}
.line {
  width: 100%;
  height: 0;
  border-bottom: 1px solid var(--dividerStokes, #e0e0e0);
  display: inline-block;
  @media only screen and (max-width: 576px) {
    width: 100%;
  }
}
.back-to-gallery {
  display: flex;
  flex-direction: row;
  margin: 15px 0px 15px 0px;
  .back-button {
    display: flex;
    flex-direction: row;
    border: none;
    background: white;
    cursor: pointer;
  }
  .text {
    font-family: unset;
    font-weight: 500;
    font-size: 14px;
    line-height: 140%;
    color: #2e31be;
    margin-left: 12px;
  }
}
.image-gallery {
  display: flex;
  flex-direction: row;
  @media only screen and (max-width: 576px) {
    display: flex;
    flex-direction: column;
  }
  ::v-deep .fy__img {
    width: 680px;
    height: 500px;
    @media only screen and (max-width: 576px) {
      width: 100%;
      height: 500px;
    }
  }
  .image-review {
    width: 60%;
    @media only screen and (max-width: 576px) {
      width: 100%;
    }
    .fade-enter-active {
      transition: opacity 1s;
    }
    .fade-enter {
      opacity: 0;
    }

    /* GO TO NEXT SLIDE */
    .slide-next-enter-active,
    .slide-next-leave-active {
      transition: transform 0.5s ease-in-out;
    }

    /* GO TO PREVIOUS SLIDE */
    .slide-prev-enter-active,
    .slide-prev-leave-active {
      transition: transform 0.5s ease-in-out;
    }

    #slider {
      width: 100%;
      height: 549px;
      position: relative;
    }

    .slide {
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
    }

    .btn-modal {
      background: rgba(0, 0, 0, 0.4);
      border-radius: 50%;
      z-index: 10;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 10px;
      position: absolute;
      top: calc(50% - 35px);
      left: 1%;
      transition: transform 0.3s ease-in-out;
      user-select: none;
    }

    .btn-next {
      left: auto;
      right: 1%;
    }

    .btn:hover {
      transform: scale(1.1);
    }
  }
  .review-list {
    width: 40%;
    margin: 32px;
    @media only screen and (max-width: 576px) {
      width: 100%;
      margin-left: 0px;
    }
    .timestamp {
      display: flex;
      flex-direction: row;
      font-size: 12px;
      line-height: 14px;
      letter-spacing: -0.02em;
      color: #7d7676;
      .comment-time {
        margin-right: 2px;
      }
    }
    .profile-container {
      display: flex;
      flex-direction: row;
      .profile-icon {
        width: 36px;
        height: 36px;
        border-radius: 50%;
        margin-right: 7px;
        background: #4298a1;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .profile {
        display: flex;
        flex-direction: column;
        .profile-name {
          margin-bottom: 2px;
          max-width: 16rem;
          font-weight: 600;
          font-size: 16px;
          line-height: 19px;
          letter-spacing: -0.02em;
          color: #3c3131;
        }
        .verified-shopper {
          display: flex;
          flex-direction: row;
          .verified-text {
            font-family: unset;
            font-weight: 400;
            font-size: 12px;
            line-height: 14px;
            letter-spacing: -0.02em;
            color: #9c9c9c;
          }
        }
      }
    }
    .verified-purchase {
      display: flex;
      flex-direction: row;
      align-items: center;
      .verified-text {
        font-family: unset;
        font-weight: 400;
        font-size: 12px;
        line-height: 15px;
        color: #35919b;
      }
    }
    .timestamp {
      font-family: unset;
      font-weight: 400;
      font-size: 12px;
      line-height: 140%;
      color: #828282;
    }
    .subject-title {
      font-family: unset;
      font-weight: 600;
      font-size: 14px;
      line-height: 19px;
      color: #333333;
    }
    .comment {
      font-family: unset;
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      letter-spacing: -0.02em;
      color: #3c3131;
    }
    .comment-container-image {
      display: flex;
      flex-direction: row;
      .images {
        margin: 0px 7px 0px 0px;
        outline: 2px solid;
        border-radius: 4px;
        width: 56px;
        height: 56px;
        .review-image-block {
          width: 56px;
          height: 56px;
          object-fit: cover;
        }
      }
      video {
        object-fit: cover;
      }
    }

    ::v-deep .fy__img {
      width: 42px;
      height: 42px;
    }

    .vote-container {
      display: flex;
      flex-direction: column;
      .helful-feedback {
        .feedback {
          display: flex;
          flex-direction: row;
          .feedback-text {
            margin-left: 8px;
            font-family: unset;
            font-weight: 400;
            font-size: 14px;
            line-height: 19px;
            color: #219653;
          }
        }
      }
      .total-vote {
        font-family: unset;
        font-weight: 400;
        font-size: 12px;
        line-height: 140%;
        color: #828282;
        margin-bottom: 7px;
      }
      .vote-button {
        display: flex;
        flex-direction: row;
        align-items: center;

        .helpful {
          font-family: unset !important;
          line-height: normal;
          letter-spacing: -0.28px;
          display: flex;
          padding: 8px 16px;
          justify-content: center;
          align-items: center;
          gap: 10px;
          background: var(--buttonSecondary, #fff);
          border: 1px solid var(--dividerStokes, #e1cece);
          border-radius: 4px;
          cursor: pointer;
          font-weight: 500;
          font-size: 14px;
          line-height: 16px;
          letter-spacing: -0.02em;
          text-transform: uppercase;
          color: var(--buttonPrimary, #8b8374);
          background-color: var(--bgColor);
          border-color: var(--buttonPrimary);
          .text {
            font-family: unset;
            font-weight: 400;
            font-size: 12px;
            line-height: 15px;
            color: #333333;
            display: flex;
            align-items: center;
            margin: 8px 16px 8px 16px;
          }
        }
        .pipe {
          background: var(--dividerStokes, #8b8374);
          width: 1px;
          height: 21px;
          margin: 0px 21px 0px 21px;
        }
        .report-abuse {
          cursor: pointer;
          font-family: unset;

          font-weight: 500;
          font-size: 14px;
          line-height: 19px;

          color: #4f4f4f;
        }
      }
    }
  }
}
.floatingreview-carousel {
  padding: 0 !important;
}
.test {
  padding: 50px;
  margin: 50px;
}

.slide {
  margin-bottom: "8px";
}

.d-none {
  display: none;
}

.slick-arrow {
  border-radius: 24px;
  background: rgba(20, 19, 14, 0.6);
  height: 48px;
  width: 48px;
  z-index: 1;
}

.slick-prev {
  left: 10px;
}
.slick-next {
  right: 10px;
}
.slick-next:before,
.slick-prev:before {
  font-family: slick;
  font-size: 20px;
  line-height: 1;
  opacity: 0.75;
  color: rgba(0, 0, 0, 0.656) !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: none;
}

.slick-next.slick-disabled:before,
.slick-prev.slick-disabled:before {
  color: #888 !important;
}
.next-btn-png {
  width: 100%;
  height: 100%;
}
.slick-next img {
  height: 24px;
  width: 24px;
}

.slick-prev img {
  height: 24px;
  width: 24px;
}

.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  border-radius: 24px;
  background: rgba(20, 19, 14, 0.6);
  height: 48px;
  width: 48px;
  z-index: 1;
}

@media only screen and (max-width: 576px) {
  .slickModal {
    ::v-deep .modal__dialog {
      max-width: 946px;
      max-height: 100%;
      overflow-y: scroll;
      height: 600px;
    }

    ::v-deep .modal__body {
      padding: 0 !important;
      overflow: scroll;
      overflow-x: hidden;
    }
  }
}
@media only screen and (min-width: 577px) and (max-width: 767px) {
  .slickModal {
    ::v-deep .modal__dialog {
      overflow-y: scroll;
      height: 95vh;
      width: 90vw;
    }
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slickModal {
    ::v-deep .modal__dialog {
      width: 95%;
    }
  }
}
</style>
