<template>
  <div class="review-with-images">
    <!-- <div class="heading">Image Reviews</div>
    <div class="line"></div> -->
    <!-- <div class="back-to-gallery">
      <button class="back-button" v-on:click="backToGallery()">
        <img src="../assets/back.svg" alt="" />
        <div class="text">Back to Gallery</div>
      </button>
    </div> -->
    <div class="image-gallery">
      <div class="image-review">
        <div id="slider">
          <transition-group
            tag="div"
            :name="transitionName"
            class="slides-group"
          >
            <div v-if="show" :key="current" class="slide">
              <div
                class="image-video-section"
                v-if="reviewItem && reviewItem.media"
              >
                <div
                  class="img-review"
                  v-if="reviewItem.media.items[current].type == 'video'"
                >
                  <video width="680px" height="500px" controls>
                    <source
                      :src="reviewItem.media.items[current].url"
                      type="video/mp4"
                    />
                  </video>
                </div>
                <div class="img-review" v-else>
                  <img
                    src="../assets/loader.svg"
                    class="loader"
                    alt=""
                    srcset=""
                    v-show="showLoader"
                  />
                  <image-moderation
                    :isNSFW="reviewItem.media.items[current].is_nsfw"
                    :mediaUrl="reviewItem.media.items[current].url"
                    :optimizeType="'responsive'"
                    :className="['']"
                    @image-loaded="onImageLoad"
                  ></image-moderation>
                </div>
              </div>
              <div class="image-video-section shimmer" v-else>
                <!-- <fdk-loader></fdk-loader> -->
              </div>
            </div>
          </transition-group>
          <div
            class="btn-modal btn-prev"
            aria-label="Previous slide"
            @click="slide(-1)"
            v-if="getReviewItem > 1"
          >
            <img src="../assets/chevron_left_24px.svg" alt="" />
          </div>
          <div
            class="btn-modal btn-next"
            aria-label="Next slide"
            @click="slide(1)"
            v-if="getReviewItem > 1"
          >
            <img src="../assets/chevron_right_24px.svg" alt="" />
          </div>
        </div>
      </div>
      <div class="review-list" v-if="reviewItem">
        <div class="review-config" v-if="configImageReview">
          <div class="review-profile">
            <div class="profile">
              <div
                class="name-index"
                v-if="configImageReview.display_user_avatar"
              >
                {{ reviewItem.created_by.first_name.charAt(0) }}
              </div>
            </div>
            <div
              class="name-with-verification"
              :style="{
                'justify-content': reviewItem.verified
                  ? 'space-evenly'
                  : 'center',
              }"
            >
              <div class="name">
                {{
                  reviewItem.created_by.first_name +
                  " " +
                  reviewItem.created_by.last_name
                }}
              </div>
              <div class="verification-status" v-if="reviewItem.verified">
                <img src="./../assets/verified_shopper.svg" alt="" srcset="" />
                <div class="verification-text">Verified Purchase</div>
              </div>
            </div>
          </div>

          <div class="review-content">
            <div class="overall-rating">
              <div
                class="star-rating"
                v-if="configImageReview.rating_type == 'star_rating'"
              >
                <div class="rating">{{ reviewItem.rating.value }}</div>
                <star-rating
                  v-model="reviewItem.rating.value"
                  v-bind:star-size="15"
                  :read-only="true"
                  :max-rating="5"
                  :show-rating="false"
                  :active-color="configImageReview.star_color"
                />
              </div>
              <div
                class="number-rating"
                v-if="configImageReview.rating_type == 'number_rating'"
              >
                <div class="number">
                  <div class="number-average-rating">
                    {{ reviewItem.rating.value }}
                  </div>
                  <star-rating
                    v-model="reviewItem.rating.value"
                    v-bind:star-size="15"
                    :active-color="configImageReview.star_color"
                    :read-only="true"
                    :max-rating="1"
                    :show-rating="false"
                  />
                </div>
              </div>
              <div
                class="number-star"
                v-if="
                  configImageReview.rating_type == 'both_star_and_number_rating'
                "
              >
                <div class="number-star-rating">
                  <div class="number-average-rating">
                    {{ reviewItem.rating.value }}
                  </div>
                  <starRating
                    v-model="reviewItem.rating.value"
                    v-bind:star-size="15"
                    :active-color="configImageReview.star_color"
                    :read-only="true"
                    :show-rating="false"
                  />
                </div>
              </div>
            </div>

            <div class="comment-heading">
              <div class="comment-item" v-if="reviewItem.review.title">
                {{ getTitle }}
              </div>
            </div>

            <div class="date-time" v-if="configImageReview.display_date">
              Reviewed on: {{ getDate(reviewItem) }}
            </div>

            <div class="answer-description">
              <div class="comment" v-if="reviewItem.review.description">
                {{ getDescription }}
              </div>
            </div>
            <div class="image-container">
              <div class="comment-container-image" v-if="reviewItem.media">
                <div
                  class="images"
                  v-for="(i, imgIndex) in getImages(reviewItem.media.items)"
                  :key="i.type + imgIndex"
                  @click="selectedMedia(i, imgIndex)"
                  :style="{
                    outline:
                      imgIndex == current
                        ? '2px solid var(--buttonLink, #b9af9b)'
                        : 'none',
                  }"
                >
                  <div v-if="i.type == 'image'">
                    <div class="img-slot">
                      <img
                        src="./../assets/loader.svg"
                        class="loader-small"
                        alt=""
                        srcset=""
                        v-show="showLoader"
                      />
                      <image-moderation
                        :isNSFW="i.is_nsfw"
                        :mediaUrl="i.url"
                        :optimizeType="'thumbnail'"
                        :className="['review-image-block']"
                        @image-loaded="onImageLoad"
                      ></image-moderation>
                    </div>
                  </div>
                </div>
                <div
                  class="images"
                  v-for="(v, Vindex) in video(reviewItem.media.items)"
                  :key="v.type + Vindex"
                  @click="selectedMedia(v, Vindex)"
                  :style="{
                    outline:
                      Vindex == current
                        ? '2px solid var(--buttonLink, #b9af9b)'
                        : 'none',
                  }"
                >
                  <div v-if="v.type == 'video'">
                    <div>
                      <video width="56px" height="56px">
                        <source :src="v.url" type="video/mp4" />
                      </video>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- <div
            class="vote-container"
            v-if="config.slug == 'upvote' && config.show"
          >
            <div>
              <div class="total-vote" v-if="success">
                {{ reviewItem.vote_count.upvote + 1 }} people found this helpful
              </div>
              <div class="total-vote" v-else>
                {{ reviewItem.vote_count.upvote }} people found this helpful
              </div>
            </div>
            <div class="vote-button" v-if="reviewItem">
              <div v-if="reviewItem.is_upvotable">
                <fdk-accounts v-slot="accountsData">
                  <button
                    class="helpful"
                    @click="
                      accountsData.is_logged_in
                        ? helpfulVotes(reviewItem._id, reviewItem.is_upvotable)
                        : accountsData.openLogin()
                    "
                    :key="reviewItem._id"
                  >
                    <div class="icon-text" v-if="config.tag.value">
                      <img
                        :src="config.tag.value"
                        alt="button_icon"
                        loading="lazy"
                      />

                      <div>
                        {{ config.button_text.slice(0, 10) }}
                      </div>
                    </div>
                    <div>
                      {{ config.button_text.slice(0, 10) }}
                    </div>
                  </button>
                </fdk-accounts>
              </div>
              <div class="helful-feedback" v-if="!reviewItem.is_upvotable">
                <div class="feedback">
                  <img src="../assets/helpful_feedback.svg" alt="feedback" />
                  <div class="feedback-text">Thank you for feedback</div>
                </div>
              </div>
              <div class="pipe"></div>
              <fdk-accounts v-slot="accountsData">
                <div
                  class="report-abuse"
                  @click="
                    accountsData.is_logged_in
                      ? $refs.reportAbuse.openModal(reviewItem._id)
                      : accountsData.openLogin()
                  "
                >
                  <div class="report-abuse" :style="{ color: config.colour }">
                    Report abuse
                  </div>
                </div>
              </fdk-accounts>
            </div>
          </div> -->
        </div>
      </div>
    </div>
    <modals class="all_image" ref="modalName">
      <template>
        <allImage @closemodel="$refs.modalName.closeModal()" />
      </template>
    </modals>
  </div>
</template>

<script>
import allImage from "./all-image.vue";
import customerReview from "../services/customer-review";
import configuration from "../services/configuration";
import {
  convertDate,
  convertTime,
  resizeThumbnails,
  resizePixelbinImage,
} from "../utils";
import starRating from "vue-star-rating";
import modals from "./modals.vue";
import imageModeration from "./../components/image-moderation.vue";
export default {
  name: "image-review",
  props: ["rid", "getBack"],
  components: {
    starRating,
    modals,
    allImage,
    imageModeration,
  },
  data() {
    return {
      success: false,
      votes: {
        upvote: 0,
      },
      count: 0,
      activeIndex: null,
      configImageReview: null,
      reviewItem: null,
      current: 0,
      direction: 1,
      transitionName: "fade",
      show: false,
      showLoader: true,
    };
  },
  mounted() {
    this.getConfigReviewRating();
    this.getAllReviewListingById();
    this.show = true;
  },
  computed: {
    getReviewItem() {
      return (
        this.reviewItem &&
        this.reviewItem.media &&
        this.reviewItem.media.items.length
      );
    },
    getTitle() {
      if (this.reviewItem.is_abusive) {
        return this.reviewItem.masked_review.title;
      }
      return this.reviewItem.review.title;
    },
    getDescription() {
      if (this.reviewItem.is_abusive) {
        return this.reviewItem.masked_review.description;
      }
      return this.reviewItem.review.description;
    },
  },
  methods: {
    backToGallery() {
      this.getBack();
    },

    helpfulVotes(id) {
      this.votes.upvote += 1;
      customerReview.postVote(this.votes, id).then(() => {
        this.success = true;
        this.reviewItem.is_upvotable = false;
      });
    },

    getImages(item) {
      let arr = [];
      for (let i of item) {
        if (i.type == "image") {
          arr.push(i);
        }
      }
      return arr;
    },
    video(item) {
      let arr = [];
      for (let i of item) {
        if (i.type == "video") {
          arr.push(i);
        }
      }
      return arr;
    },
    getTime(items) {
      let date = convertTime(items);
      return date;
    },
    getDate(items) {
      let date = convertDate(items);
      return date;
    },
    getConfigReviewRating() {
      let widget = "product_review";
      configuration.getWidgetsConfig(widget).then(({ data }) => {
        this.configImageReview = data.data[0].config;
      });
    },
    getFirstLetter(name) {
      if (name) {
        return name.split("")[0];
      }
    },
    getAllReviewListingById() {
      customerReview.getReviewById(this.rid?.review).then((data) => {
        this.reviewItem = data.data;
        let mediaURl = this.rid.url;
        let index = this.reviewItem.media.items.findIndex(
          (img) => img.url === mediaURl
        );
        [this.reviewItem.media.items[0], this.reviewItem.media.items[index]] = [
          this.reviewItem.media.items[index],
          this.reviewItem.media.items[0],
        ];
      });
    },
    slide(dir) {
      this.direction = dir;
      dir === 1
        ? (this.transitionName = "slide-next")
        : (this.transitionName = "slide-prev");
      if (
        this.reviewItem &&
        this.reviewItem.media &&
        this.reviewItem.media.items
      ) {
        let len = this.reviewItem.media.items.length;
        this.current = (this.current + (dir % len) + len) % len;
      }
    },
    selectedMedia(item, index) {
      if (
        this.reviewItem &&
        this.reviewItem?.media &&
        this.reviewItem?.media.items
      ) {
        let len = this.reviewItem?.media?.items?.length;
        this.current = ((index % len) + len) % len;
      }
    },

    getThumbnailMedia(url) {
      return resizeThumbnails(url);
    },
    getOptimizedMedia(url) {
      return resizePixelbinImage(url);
    },
    onImageLoad() {
      this.showLoader = false;
    },
  },
};
</script>
<style lang="less" scoped>
@import "../styles.sass";
.shimmer {
  display: flex;
  justify-content: center;
  margin-top: 60%;
}

.img-box,
.img-slot {
  justify-content: center;
  align-items: center;
  display: flex;
  min-height: 56px;
  width: 56px;
  height: 56px;
  border-color: var(--buttonLink, #b9af9b);
}
.image-video-section {
  width: 100%;
  height: 100%;
  .img-review {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px 0px 0px 8px;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      object-position: center;
      background-color: transparent;
    }
    video {
      width: 100%;
      height: 100%;
      object-fit: contain;
      object-position: center;
      background-color: transparent;
    }
    @media only screen and (max-width: 576px) {
      width: 288px;
      height: 345px;
    }
  }
}
.number {
  display: flex;
  gap: 5px;
}
.verified-icon {
  img {
    width: 15px;
    height: 15px;
    object-fit: contain;
  }
}
.star-rating {
  display: flex;
  .rating {
    margin-right: 6px;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.02em;
    color: #261a1a;
  }
}
.number-star-rating {
  display: flex;
  flex-direction: row;
  align-items: center;
  .number-average-rating {
    margin-right: 7px;
  }
}
.icon-text {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.review-with-images {
  overflow: hidden;
  .report-modals {
    ::v-deep .modal__dialog {
      width: 100%;
      max-width: 500px;
    }
  }
}

.selected {
  display: none;
}
.review-config {
  margin: 12px 0px;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: -0.02em;
  color: #261a1a;

  .review-profile {
    display: flex;
    gap: 8px;
    margin-bottom: 1rem !important;

    .profile {
      background: var(--themeAccentL1, #e7dbc2);
      border-radius: 50%;
      width: 36px;
      height: 36px;
      display: flex;
      align-items: center;
      justify-content: center;

      .name-index {
        text-align: center;
        font-family: unset;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: -0.32px;
        padding: 8px 12px;
        border-radius: 50%;
        color: var(--themeAccentL5, #fff);
        text-transform: capitalize;
      }
    }

    .name-with-verification {
      display: flex;
      flex-direction: column;
      justify-content: center;

      .name {
        color: #3c3131;

        /* Desktop/Heading/Subheading H5 - Desktop */
        font-family: unset;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: -0.32px;
      }

      .verification-status {
        display: flex;
        gap: 4px;

        .verification-text {
          color: #9c9c9c;

          /* label, caption/small body - Desktop,mob,tab */
          font-family: unset;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          letter-spacing: -0.24px;
        }
      }
    }
  }

  .review-content {
    display: flex;
    flex-direction: column;
    gap: 12px;
    .comment-heading {
      .comment-item {
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        letter-spacing: -0.02em;
        color: var(--textHeading, #261a1a);

        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .date-time {
      font-size: 12px;
      line-height: 14px;
      letter-spacing: -0.02em;
      color: var(--textLabel, #7d7676);

      .location-margin {
        margin-right: 4px;
      }
    }
  }
}
.heading {
  font-family: unset;
  font-weight: 600;
  font-size: 20px;
  line-height: 140%;
  color: #41434c;
  margin: 0px 0px 0px 21px;
}
.line {
  width: 100%;
  height: 0;
  border-bottom: 1px solid var(--dividerStokes, #e0e0e0);
  display: inline-block;
  @media only screen and (max-width: 576px) {
    width: 100%;
  }
}
.back-to-gallery {
  display: flex;
  flex-direction: row;
  margin: 15px 0px 15px 0px;
  .back-button {
    display: flex;
    flex-direction: row;
    border: none;
    background: white;
    cursor: pointer;
  }
  .text {
    font-family: unset;
    font-weight: 500;
    font-size: 14px;
    line-height: 140%;
    color: #2e31be;
    margin-left: 12px;
  }
}
.image-gallery {
  display: flex;
  flex-direction: row;
  @media only screen and (max-width: 576px) {
    display: flex;
    flex-direction: column;
  }
  ::v-deep .fy__img {
    width: 680px;
    height: 500px;
    @media only screen and (max-width: 576px) {
      width: 100%;
      height: 500px;
    }
  }
  .image-review {
    width: 60%;
    @media only screen and (max-width: 576px) {
      width: 288px;
      height: 345px;
    }
    .fade-enter-active {
      transition: opacity 1s;
    }
    .fade-enter {
      opacity: 0;
    }

    /* GO TO NEXT SLIDE */
    .slide-next-enter-active,
    .slide-next-leave-active {
      transition: transform 0.5s ease-in-out;
    }

    /* GO TO PREVIOUS SLIDE */
    .slide-prev-enter-active,
    .slide-prev-leave-active {
      transition: transform 0.5s ease-in-out;
    }

    #slider {
      width: 100%;
      height: 549px;
      position: relative;
      @media only screen and (max-width: 576px) {
        height: 100%;
      }
    }

    .slide {
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
    }

    .btn-modal {
      background: rgba(0, 0, 0, 0.4);
      border-radius: 50%;
      z-index: 10;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 10px;
      position: absolute;
      top: calc(50% - 35px);
      left: 1%;
      transition: transform 0.3s ease-in-out;
      user-select: none;
    }

    .btn-next {
      left: auto;
      right: 1%;
    }

    .btn:hover {
      transform: scale(1.1);
    }
  }
  .review-list {
    width: 40%;
    margin: 32px;
    @media only screen and (max-width: 576px) {
      width: 100%;
      margin: 16px;
    }
    .timestamp {
      display: flex;
      flex-direction: row;
      font-size: 12px;
      line-height: 14px;
      letter-spacing: -0.02em;
      color: #7d7676;
      .comment-time {
        margin-right: 2px;
      }
    }
    .profile-container {
      display: flex;
      flex-direction: row;
      .profile-icon {
        width: 36px;
        height: 36px;
        border-radius: 50%;
        margin-right: 7px;
        background: #4298a1;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .profile {
        display: flex;
        flex-direction: column;
        justify-content: center;
        .profile-name {
          margin-bottom: 2px;
          max-width: 16rem;
          font-weight: 600;
          font-size: 16px;
          line-height: 19px;
          letter-spacing: -0.02em;
          color: #3c3131;
        }
        .verified-shopper {
          display: flex;
          flex-direction: row;
          .verified-text {
            font-family: unset;
            font-weight: 400;
            font-size: 12px;
            line-height: 14px;
            letter-spacing: -0.02em;
            color: #9c9c9c;
          }
        }
      }
    }
    .verified-purchase {
      display: flex;
      flex-direction: row;
      align-items: center;
      .verified-text {
        font-family: unset;
        font-weight: 400;
        font-size: 12px;
        line-height: 15px;
        color: #35919b;
      }
    }
    .timestamp {
      font-family: unset;
      font-weight: 400;
      font-size: 12px;
      line-height: 140%;
      color: #828282;
    }
    .subject-title {
      font-family: unset;
      font-weight: 600;
      font-size: 14px;
      line-height: 19px;
      color: #333333;
    }
    .comment {
      font-family: unset;
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      letter-spacing: -0.02em;
      color: #3c3131;
    }
    .comment-container-image {
      display: flex;
      flex-direction: row;
      gap: 7px;
      overflow-x: auto;
      overflow-y: hidden;
      padding: 2px 0;
      -ms-overflow-style: none;
      scrollbar-width: none;

      &::-webkit-scrollbar {
        display: none;
      }

      .images {
        // margin: 0px 7px 0px 0px;
        border-style: solid;
        border-radius: 4px;
        width: 56px;
        height: 56px;
        border-color: var(--buttonLink, #b9af9b);
        cursor: pointer;
        .review-image-block {
          width: 56px;
          height: 56px;
          object-fit: contain;
        }
      }
      video {
        object-fit: contain;
      }
    }

    ::v-deep .fy__img {
      width: 42px;
      height: 42px;
    }

    .vote-container {
      display: flex;
      flex-direction: column;
      .helful-feedback {
        .feedback {
          display: flex;
          flex-direction: row;
          .feedback-text {
            margin-left: 8px;
            font-family: unset;
            font-weight: 400;
            font-size: 14px;
            line-height: 19px;
            color: #219653;
          }
        }
      }
      .total-vote {
        font-family: unset;
        font-weight: 400;
        font-size: 12px;
        line-height: 140%;
        color: #828282;
        margin-bottom: 7px;
      }
      .vote-button {
        display: flex;
        flex-direction: row;
        align-items: center;

        .helpful {
          font-family: unset !important;
          line-height: normal;
          letter-spacing: -0.28px;
          display: flex;
          padding: 8px 16px;
          justify-content: center;
          align-items: center;
          gap: 10px;
          background: var(--buttonSecondary, #fff);
          border: 1px solid var(--dividerStokes, #e1cece);
          border-radius: 4px;
          cursor: pointer;
          font-weight: 500;
          font-size: 14px;
          line-height: 16px;
          letter-spacing: -0.02em;
          text-transform: uppercase;
          color: var(--buttonPrimary, #8b8374);
          background-color: var(--bgColor);
          border-color: var(--buttonPrimary);

          .text {
            font-family: unset;
            font-weight: 400;
            font-size: 12px;
            line-height: 15px;
            color: #333333;
            display: flex;
            align-items: center;
            margin: 8px 16px 8px 16px;
          }
        }
        .pipe {
          background: var(--dividerStokes, #8b8374);
          width: 1px;
          height: 21px;
          margin: 0px 21px 0px 21px;
        }
        .report-abuse {
          cursor: pointer;
          font-family: unset;

          font-weight: 500;
          font-size: 14px;
          line-height: 19px;

          color: #4f4f4f;
        }
      }
    }
  }
}
</style>
