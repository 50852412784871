<template>
  <img
    :src="modifiedMedia"
    alt=""
    loading="lazy"
    :class="className"
    @load="onImageLoad"
    @error="loadFallbackImage"
  />
</template>

<script>
import { resizePixelbinImage, resizeThumbnails } from "./../utils";

export default {
  name: "image-resize",
  props: ["mediaUrl", "optimizeType", "className", "isNSFW"],
  mounted() {},
  computed: {
    modifiedMedia() {
      return this.optimizeType == "responsive"
        ? resizePixelbinImage(this.mediaUrl, this.isNSFW)
        : resizeThumbnails(this.mediaUrl, this.isNSFW);
    },
  },
  methods: {
    onImageLoad() {
      this.$emit("img-loaded", true);
    },
    loadFallbackImage(event) {
      event.target.src = this.mediaUrl;
    },
  },
};
</script>

<style lang="less" scoped></style>
