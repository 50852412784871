<template>
  <div>
    <button
      class="rating-button"
      v-if="poorRatingSelected"
      key="selectedOptionsPoor"
    >
      <lottie
        :options="selectedOptionsPoor"
        v-on:animCreated="handleAnimation"
      />
    </button>
    <button
      class="rating-button"
      @click="handlePoorEmojiClick"
      @mouseenter="handleBardEmojiHover"
      @mouseleave="handleBadEmojiHoverEnd"
      v-else-if="poorRatingHover"
      key="hoverOptionsPoor"
    >
      <lottie :options="hoverOptionsPoor" v-on:animCreated="handleAnimation" />
    </button>
    <button
      class="rating-button"
      v-else-if="poorRatingClicked"
      key="poorRatingClicked"
    >
      <lottie
        :options="clickedOptionsPoor"
        v-on:animCreated="handleAnimationPoor"
      />
    </button>
    <button
      class="rating-button"
      @click="handlePoorEmojiClick"
      @mouseenter="handleBardEmojiHover"
      @mouseleave="handleBadEmojiHoverEnd"
      key="defaultOptionsPoor"
      v-else
    >
      <lottie
        :options="defaultOptionsPoor"
        v-on:animCreated="handleAnimation"
      />
    </button>
  </div>
</template>

<script>
import Lottie from "vue-lottie";

import PoorEmojiHover from "../../assets/emojis/hover/poor.json";
import PoorEmojiDefault from "../../assets/emojis/default/poor.json";
import PoorEmojiSelected from "../../assets/emojis/selected/poor.json";
import PoorEmojiClicked from "../../assets/emojis/clicked/poor.json";

export default {
  name: "rating-button",
  data() {
    return {
      defaultOptionsPoor: { animationData: PoorEmojiDefault, loop: true },
      hoverOptionsPoor: { animationData: PoorEmojiHover, loop: true },
      clickedOptionsPoor: { animationData: PoorEmojiClicked, loop: true },
      selectedOptionsPoor: { animationData: PoorEmojiSelected, loop: true },
      animationSpeed: 1,
      poorRatingHover: false,
      poorRatingSelected: false,
      poorRatingClicked: false,
      anim: null, // for saving the reference to the animation
      animPoorClicked: null, // for saving the reference to the animation
    };
  },
  components: {
    lottie: Lottie,
  },
  props: ["originalRating"],
  methods: {
    handleAnimation: function (anim) {
      this.anim = anim;
    },
    handleAnimationPoor: function (animPoor) {
      this.animPoor = animPoor;
      this.$watch(
        () => {
          return this.anim._completedLoop;
        },
        (val) => {
          if (val && this.originalRating === 2) {
            this.poorRatingSelected = true;
          }
        }
      );
    },
    handlePoorEmojiClick() {
      this.poorRatingHover = false;
      this.$parent.$parent.$emit("ratingClick", 2);
      this.poorRatingClicked = true;
    },
    handleBardEmojiHover() {
      this.poorRatingHover = true;
    },
    handleBadEmojiHoverEnd() {
      this.poorRatingHover = false;
    },
  },
  watch: {
    originalRating(newRating) {
      if (newRating !== 2) {
        this.poorRatingClicked = false;
        this.poorRatingSelected = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../rating.scss";
</style>
