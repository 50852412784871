import axios from "axios";
let {url} = require("./urlset")
const headers = {
    "Content-Type": "application/json",
    "x-currency-code": "INR"
};

const uploadPostRequest = (file) => {
    return axios.post(`${url}/ext/reviews/api/v1.0/media/namespaces/misc/start/`, {
        file_name: file.name,
        content_type: file.type,
        size: file.size
    }, {headers}).then((uploaddata) => {
        if (uploaddata && uploaddata.data) {
            return axios.put(uploaddata.data.upload.url, file, {
                withCredentials: false,
                headers: {
                    "Content-Type": file.type
                }
            }).then(() => {
                return axios.post(`${url}/ext/reviews/api/v1.0/media/namespaces/misc/complete/`, uploaddata.data);
            });
        } else {
            console.log("error");
        }
    });
};

export default uploadPostRequest;
