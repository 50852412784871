import _get from "lodash-es/get";
import _has from "lodash-es/has";
import _isEmpty from "lodash-es/isEmpty";
import _set from "lodash-es/set";
export const convertDate = (item) => {
  let [month, date, year] = new Date(item.created_at)
    .toLocaleDateString("en-us", {
      year: "numeric",
      month: "long",
      day: "numeric",
    })
    .split(" ");
  return `${date.split(",")[0]} ${month} ${year}`;
};

export const convertTime = (item) => {
  let [, time, ampm] = new Date(item.created_at)
    .toLocaleString("en-US", { timeZone: "Asia/Kolkata" })
    .split(" ");
  let time_value = time.split(":").slice(0, 2).join(":"); // Removing seconds
  return `${time_value} ${ampm}`;
};

export const debounce = (func, wait, immediate) => {
  var timeout;

  // This is the function that is actually executed when
  // the DOM event is triggered.
  return function executedFunction() {
    // Store the context of this and any
    // parameters passed to executedFunction
    var context = this;
    var args = arguments;

    // The function to be called after
    // the debounce time has elapsed
    var later = function () {
      // null timeout to indicate the debounce ended
      timeout = null;

      // Call function now if you did not on the leading end
      if (!immediate) func.apply(context, args);
    };

    // Determine if you should call the function
    // on the leading or trail end
    var callNow = immediate && !timeout;

    // This will reset the waiting every function execution.
    // This is the step that prevents the function from
    // being executed because it will never reach the
    // inside of the previous setTimeout
    clearTimeout(timeout);

    // Restart the debounce waiting period.
    // setTimeout returns a truthy value (it differs in web vs node)
    timeout = setTimeout(later, wait);

    // Call immediately if you're dong a leading
    // end execution
    if (callNow) func.apply(context, args);
  };
};

export const resizePixelbinImage = (src, nsfw) => {
  const mediaQueries = [
    { minWidth: 1440, action: "t.resize(w:550)" },
    { minWidth: 1200, action: "t.resize(w:550)" },
    { minWidth: 992, action: "t.resize(w:500)" },
    { minWidth: 768, action: "t.resize(w:450)" },
    { minWidth: 576, action: "t.resize(w:375)" },
    { minWidth: 320, action: "t.resize(w:296)" },
  ];

  let result = src;

  for (const query of mediaQueries) {
    if (window.matchMedia(`(min-width: ${query.minWidth}px)`).matches) {
      let searchString;
      const stringToAdd = query.action;
      // if (nsfw) {
      //   searchString = "t.blur(s:100,dpr:5)";
      //   return result.replace(searchString, `${searchString}~${stringToAdd}`);
      // } else {
      //   searchString = "wrkr";
      //   return result.replace(searchString, `${stringToAdd}`);
      // }
      searchString = "wrkr";
      return result.replace(searchString, `${stringToAdd}`);
    }
  }
  return result;
};

const isJSONValid = (jsonString) => {
  try {
    JSON.parse(jsonString);
    return true; // JSON is valid
  } catch (error) {
    return false; // JSON is not valid (empty or incomplete)
  }
};
const modifyMeta = (data) => {
  let resp;
  const isValidData = isJSONValid(data);
  if (isValidData) {
    resp = data;
  } else if (data.meta == "") {
    resp = "{}";
  } else {
    resp = JSON.stringify(data);
  }
  return resp;
};

export const fetchOrderHistory = (productID, data) => {
  try {
    let metaValue;

    if (data && data !== undefined) {
      // Check if meta is present
      const hasMeta = _has(data, "meta");

      if (hasMeta) {
        metaValue = JSON.parse(modifyMeta(data.meta));
      } else {
        _set(data, "meta", {});
        metaValue = JSON.parse(modifyMeta(data.meta));
      }

      // Check if REVIEWS is present
      const hasReview = _has(metaValue, "REVIEWS");

      if (!hasReview) {
        metaValue = { REVIEWS: {} };
      }

      if (_isEmpty(metaValue.REVIEWS)) {
        metaValue.REVIEWS = {
          ORDERS: {},
          REVIEW_COUNT: 0,
          VERIFIED_REVIEW_COUNT: 0,
        };
      }

      return _get(metaValue, `REVIEWS.${productID}`, {
        ORDERS: {},
        REVIEW_COUNT: 0,
        VERIFIED_REVIEW_COUNT: 0,
      });
    }
  } catch (error) {
    console.log(error);
  }
};

export const resizeThumbnails = (src, nsfw) => {
  let result = src;
  let searchString;
  const stringToAdd = "t.resize(w:296)";
  // if (nsfw) {
  //   searchString = "t.blur(s:100,dpr:5)";
  //   return result.replace(searchString, `${searchString}~${stringToAdd}`);
  // } else {
  //   searchString = "wrkr";
  //   return result.replace(searchString, `${stringToAdd}`);
  // }
  searchString = "wrkr";
  return result.replace(searchString, `${stringToAdd}`);
};

export const lightenDarkenColor = (hexColor, magnitude) => {
  hexColor = hexColor.replace(`#`, ``);
  if (hexColor.length === 6) {
    const decimalColor = parseInt(hexColor, 16);
    let r = (decimalColor >> 16) + magnitude;
    r > 255 && (r = 255);
    r < 0 && (r = 0);
    let g = (decimalColor & 0x0000ff) + magnitude;
    g > 255 && (g = 255);
    g < 0 && (g = 0);
    let b = ((decimalColor >> 8) & 0x00ff) + magnitude;
    b > 255 && (b = 255);
    b < 0 && (b = 0);
    return `#${(g | (b << 8) | (r << 16)).toString(16)}`;
  } else {
    return hexColor;
  }
};

export const colorShadeObject = (themeAccent) => {
  return {
    "--themeAccentL1": themeAccent
      ? lightenDarkenColor(themeAccent, 20)
      : "inherit",
    "--themeAccentL2": themeAccent
      ? lightenDarkenColor(themeAccent, 40)
      : "inherit",
    "--themeAccentL3": themeAccent
      ? lightenDarkenColor(themeAccent, 60)
      : "inherit",
    "--themeAccentL4": themeAccent
      ? lightenDarkenColor(themeAccent, 80)
      : "inherit",
    "--themeAccentL5": themeAccent
      ? lightenDarkenColor(themeAccent, 100)
      : "inherit",
  };
};

export const platformColorObject = (theme) => {
  return {
    "--buttonPrimary": theme?.button_primary,
    "--buttonSecondary": theme?.button_secondary,
    "--dividerStokes": theme?.divider_strokes,
    "--textHeading": theme?.text_heading,
    "--textBody": theme?.text_body,
    "--textLabel": theme?.text_label,
    "--buttonLink": theme?.button_link,
    "--themeAccent": theme?.theme_accent,
    "--themeAccentL1": theme
      ? lightenDarkenColor(theme?.theme_accent, 20)
      : "inherit",
    "--themeAccentL2": theme
      ? lightenDarkenColor(theme?.theme_accent, 40)
      : "inherit",
    "--themeAccentL3": theme
      ? lightenDarkenColor(theme?.theme_accent, 60)
      : "inherit",
    "--themeAccentL4": theme
      ? lightenDarkenColor(theme?.theme_accent, 80)
      : "inherit",
    "--themeAccentL5": theme
      ? lightenDarkenColor(theme?.theme_accent, 100)
      : "inherit",
  };
};
