<template>
  <div class="submit">
    <button
      class="submit-button"
      @click="handleSubmit"
      type="submit"
      :disabled="!isDisabled"
    >
      <div class="text">
        {{ submit_button_text }}
      </div>
    </button>
  </div>
</template>

<script>
export default {
  name: "submit-button",
  props: ["submit_button_text", "isDisabled"],
  methods: {
    handleSubmit() {
      this.$emit("handleSubmit");
    },
  },
};
</script>

<style scoped lang="scss">
.submit {
  margin-top: 14px;
  button:disabled,
  button[disabled] {
    opacity: 0.5;
    cursor: not-allowed;
  }
  .submit-button {
    width: 100%;
    height: 56px;
    padding: 12px 32px;

    display: flex;
    justify-content: center;
    align-items: center;

    cursor: pointer;
    border-radius: 4px;
    border: 1px solid var(--buttonPrimary, #8b8374);
    background: var(--buttonPrimary, #8b8374);

    .text {
      font-size: 14px;
      font-family: unset;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: -0.28px;
      text-transform: uppercase;
      color: var(--buttonSecondary, #fff);
    }
    @media screen and (max-width: 400px) {
      width: 100%;
    }
  }
}
</style>
