<template>
  <div class="question-feedback-container">
    <div class="question-feedback" v-if="feedback && !showNetworkError">
      <img
        class="feedback-icon"
        src="../../../../assets/feedback-submitted.svg"
        alt=""
      />
      <div class="feedback-title">Question Posted Successfully</div>
      <div class="submitted-question">
        "Q: {{ submittedQuestion.question.text }}"
      </div>
      <div class="feedback-response">
        Will notify you when someone answers your question.
      </div>
      <button class="feedback-button" @click="closeModals()">
        <div class="text">KEEP SHOPPING</div>
      </button>
    </div>
    <div class="feedback-comment" v-if="showNetworkError">
      <div class="feedback">
        <div class="heading">Network Error!</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "question-feedback",
  props: ["feedback", "showNetworkError", "submittedQuestion"],
  methods: {
    closeModals() {
      this.$emit("closeFeedback");
    },
  },
};
</script>

<style scoped lang="less">
@import "../../../../assets/common-config.less";

.question-feedback-container {
  .question-feedback {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    align-items: center;
    justify-content: center;

    .feedback-icon {
      width: 48px;
      height: 48px;
      object-fit: contain;
      margin-bottom: 4px;
    }

    .feedback-title {
      color: var(--gray-2, #4f4f4f);
      text-align: center;
      font-family: unset;
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: 120%;
    }

    .submitted-question {
      color: var(--textHeading, #261a1a);
      text-align: center;
      font-family: unset;
      font-size: 15px;
      font-style: normal;
      font-weight: 500;
      line-height: 140%;
    }

    .feedback-response {
      color: var(--textHeading, #261a1a);
      text-align: center;
      font-family: unset;
      font-size: 15px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%;
    }

    .feedback-button {
      display: flex;
      height: 56px;
      padding: 12px 32px;
      justify-content: center;
      align-items: center;
      gap: 4px;
      align-self: stretch;
      width: 100%;
      border-radius: 4px;
      border: 1px solid var(--buttonPrimary, #8b8374);
      background: var(--buttonPrimary, #8b8374);
      color: var(--buttonSecondary, #fff);
      font-family: unset;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: -0.28px;
      text-transform: uppercase;
      margin-top: 1rem;
      cursor: pointer;
    }
  }
}
</style>
