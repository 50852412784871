<template>
  <div class="header_font_style">{{ title }}</div>
</template>

<script>
export default {
  name: "title",
  props: {
    title: {
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="less" scoped>
.header_font_style {
  color: #000;
  text-align: center;
  font-family: unset;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  text-align: center;
  margin-bottom: 16px;

  @media only screen and (max-width: 576px) {
    font-size: 24px;
  }
}
</style>
