<template>
  <div class="top-reviews">
    <h1>Top Reviews</h1>
    <div class="sorted-by-item">
      <select class="sorted-by" v-model="selectedOption">
        <!-- @change="updateOptions($event)" -->
        <option
          v-for="options in jobTitles"
          :value="options.id"
          :key="options.id"
          class="selected-options"
        >
          {{ options.name }}
        </option>
      </select>
    </div>
  </div>
</template>

<script>
export default {
  name: "top-reviews-header",
  emits: ["selectedOption"],
  props: ["reviewListItem"],
  data() {
    return {
      selectedOption: "desc_date",
      jobTitles: [
        {
          name: "Most Recent",
          id: "desc_date",
          value: "desc_date",
        },
        {
          name: "Highest Rating",
          id: "desc_rating",
          value: "desc_rating",
        },
        {
          name: "Lowest Rating",
          id: "asc_rating",
          value: "asc_rating",
        },
        {
          name: "Oldest",
          id: "asc_date",
          value: "asc_date",
        },
      ],
    };
  },
  watch: {
    selectedOption() {
      this.$parent.$parent.$emit("setSelectedOption", this.selectedOption);
    },
  },
};
</script>

<style lang="scss" scoped>
.top-reviews {
  display: flex;

  justify-content: space-between;
  margin: 32px 0;

  @media screen and (max-width: 576px) {
    display: block;
    margin: 24px 0px;
  }

  h1 {
    font-family: unset;
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 37px;
    letter-spacing: -0.02em;
    color: var(--textHeading, #261a1a);
    margin: 0px;
  }

  .sorted-by-item {
    // width: 25%;
    @media screen and (max-width: 576px) {
      width: 100%;
      margin: 24px 0;
    }
    .sorted-by {
      cursor: pointer;
      padding: 0.5rem 1rem;
      display: block;
      background: white;
      width: 200px;
      height: 40px;
      border: 1px solid var(--dividerStokes, #d4d1d1);
      border-radius: 4px;
      background: url(../../../../assets/dropdown.svg) no-repeat right;
      -webkit-appearance: none;
      background-position-x: 95%;
      color: var(--textLabel, #7d7676);
      background-color: white;
    }
  }
}

@media screen and (max-width: 576px) {
  .sorted-by-item {
    .sorted-by {
      width: 100% !important;
    }
  }
}
</style>
