<template>
  <div>
    <div v-if="!rewards && !feedback && !showNetworkError">
      <modalHeader :modalHeading="reviewFormConfig.heading" />
      <form-component
        :reviewFormConfig="reviewFormConfig"
        :ratingByFeatureConfig="ratingByFeatureConfig"
        :productData="productData"
        @closeModals="closeModals"
        @handlePostSubmit="handlePostSubmit"
        :orderHistory="orderHistory"
        :productReviewConfig="productReviewConfig"
        @handleUserRating="handleUserRating"
      />
    </div>
    <feedback-component
      v-if="feedback || showNetworkError"
      :feedback="feedback"
      :showNetworkError="showNetworkError"
      :showSharingLink="showSharingLink"
      @closeModals="closeModals"
      :rating="rating"
    />
    <reward-modal
      v-if="rewards"
      :rewards="getRewardsData"
      @closeModals="closeModals"
      :showSharingLink="showSharingLink"
    />
  </div>
</template>

<script>
import starRating from "vue-star-rating";
import modalHeader from "./components/modal-header.vue";
import formComponent from "./components/form-component/form-component.vue";
import feedbackComment from "./components/feedback-comment.vue";
import rewardModal from "../rewards/rewards.vue";

export default {
  name: "reviewForm",
  components: {
    starRating,
    modalHeader,
    "form-component": formComponent,
    "feedback-component": feedbackComment,
    "reward-modal": rewardModal,
  },
  props: [
    "reviewFormConfig",
    "ratingByFeatureConfig",
    "productData",
    "orderHistory",
    "productReviewConfig",
  ],
  data() {
    return {
      feedback: false,
      showNetworkError: false,
      rewards: false,
      rewardsData: null,
      showSharingLink: false,
      rating: 0,
    };
  },
  computed: {
    getRewardsData() {
      return this?.rewardsData;
    },
  },
  watch: {},
  methods: {
    closeModals() {
      this.showNetworkError = false;
      this.$emit("closeDialog");
    },
    handlePostSubmit(data, showSharingLink) {
      if (data) {
        this.rewards = true;
        this.rewardsData = data;
      } else {
        this.feedback = true;
      }
      this.$emit("disableClose");
      this.showSharingLink = showSharingLink;
    },
    handleNetworkError() {
      this.showNetworkError = true;
    },
    handleUserRating(userRating) {
      this.rating = userRating;
    },
  },
};
</script>
