<template>
  <div
    :class="className ? `${className} profile-container` : 'profile-container'"
  >
    <div class="profile" v-if="showUserAvatar">
      <div class="profile-text">
        {{ getFirstLetter(item.created_by.first_name) }}
      </div>
    </div>
    <div class="verified">
      <div class="profile-name">
        {{ item.created_by.first_name }}
        {{ item.created_by.last_name }}
      </div>
      <div class="profile-verified">
        <div :style="flexOnLayout3">
          <div class="verified-icon" v-if="item.verified">
            <div>
              <img
                src="../../../../../../assets/verified_shopper.svg"
                alt="verified_shopper"
              />
            </div>
            <div class="verified-text">Verified Purchase</div>
            <div class="pipe" v-if="showRating" />
          </div>
          <slot v-if="showRating"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "profile",
  props: ["productReviewConfig", "item", "identifier", "className"],
  methods: {
    getFirstLetter(name) {
      if (name) {
        return name.split("")[0];
      }
    },
  },
  computed: {
    showRating() {
      return (
        this.identifier !== "review-carousel" &&
        this.productReviewConfig.selected_layout === 3
      );
    },
    showUserAvatar() {
      return this.productReviewConfig?.display_user_avatar;
    },
    flexOnLayout3() {
      let styles = {};
      // Check your condition (e.g., isActive) and add styles accordingly
      if (this.productReviewConfig.selected_layout === 3) {
        styles.display = "flex";
      }
      return styles;
    },
  },
};
</script>

<style lang="scss" scoped>
.profile-container {
  display: flex;
  align-items: center;

  margin-bottom: 1rem;

  .profile {
    margin-right: 7px;
    background: var(--themeAccentL1, #e7dbc2);
    border-radius: 50%;
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;

    .profile-text {
      text-align: center;
      font-family: unset;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      letter-spacing: -0.32px;
      padding: 8px 12px;
      border-radius: 50%;
      color: var(--themeAccentL5, #fff);
      text-transform: capitalize;
    }
  }

  .verified {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-content: center;

    .profile-verified {
      margin-top: 4px;
      display: flex;
      align-items: center;
      justify-content: left;

      .verified-icon {
        margin-right: 2px;
        display: flex;

        justify-content: center;
        align-content: center;

        .verified-text {
          font-size: 12px;
          line-height: 14px;
          letter-spacing: -0.02em;
          color: var(--textLabel, #7d7676);
        }

        img {
          width: 15px;
          height: 15px;
          margin-right: 3px;
        }
      }
    }

    .profile-name {
      font-weight: 600;
      font-size: 16px;
      line-height: 19px;
      letter-spacing: -0.02em;
      color: var(--textHeading, #261a1a);
      display: flex;
      align-items: center;
      justify-content: left;

      @media only screen and (max-width: 576px) {
        max-width: 16rem;
      }
    }
  }
}

.pipe {
  border: 1px solid var(--dividerStokes, #d4d1d1);
  height: 12px;
  margin: 0 8px;
}

.profile-review-carousel {
  margin-top: 16px;
  margin-bottom: 0;
}
.cs-center {
  justify-content: center;
}
</style>
