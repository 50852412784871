<template>
  <div>
    <button
      class="rating-button"
      v-if="averageRatingSelected"
      key="selectedOptionsAverage"
    >
      <lottie
        :options="selectedOptionsAverage"
        v-on:animCreated="handleAnimation"
      />
    </button>
    <button
      class="rating-button"
      @click="handleAverageEmojiClick"
      @mouseenter="handleBardEmojiHover"
      @mouseleave="handleBadEmojiHoverEnd"
      v-else-if="averageRatingHover"
      key="hoverOptionsAverage"
    >
      <lottie
        :options="hoverOptionsAverage"
        v-on:animCreated="handleAnimation"
      />
    </button>
    <button
      class="rating-button"
      v-else-if="averageRatingClicked"
      key="averageRatingClicked"
    >
      <lottie
        :options="clickedOptionsAverage"
        v-on:animCreated="handleAnimationAverage"
      />
    </button>
    <button
      class="rating-button"
      @click="handleAverageEmojiClick"
      @mouseenter="handleBardEmojiHover"
      @mouseleave="handleBadEmojiHoverEnd"
      key="defaultOptionsAverage"
      v-else
    >
      <lottie
        :options="defaultOptionsAverage"
        v-on:animCreated="handleAnimation"
      />
    </button>
  </div>
</template>

<script>
import Lottie from "vue-lottie";

import AverageEmojiHover from "../../assets/emojis/hover/average.json";
import AverageEmojiDefault from "../../assets/emojis/default/average.json";
import AverageEmojiSelected from "../../assets/emojis/selected/average.json";
import AverageEmojiClicked from "../../assets/emojis/clicked/average.json";

export default {
  name: "rating-button",
  data() {
    return {
      defaultOptionsAverage: { animationData: AverageEmojiDefault },
      hoverOptionsAverage: { animationData: AverageEmojiHover },
      clickedOptionsAverage: {
        animationData: AverageEmojiClicked,
        playCount: 1,
        loop: false,
      },
      selectedOptionsAverage: {
        animationData: AverageEmojiSelected,
      },
      animationSpeed: 1,
      averageRatingHover: false,
      averageRatingSelected: false,
      averageRatingClicked: false,
      anim: null, // for saving the reference to the animation
      animAverageClicked: null, // for saving the reference to the animation
    };
  },
  components: {
    lottie: Lottie,
  },
  props: ["originalRating"],
  methods: {
    handleAnimation: function (anim) {
      this.anim = anim;
    },
    handleAnimationAverage: function (animAverage) {
      this.animAverage = animAverage;
      this.$watch(
        () => {
          return this.anim._completedLoop;
        },
        (val) => {
          if (val && this.originalRating === 3) {
            this.averageRatingSelected = true;
          }
        }
      );
    },
    handleAverageEmojiClick() {
      this.averageRatingHover = false;
      this.$parent.$parent.$emit("ratingClick", 3);
      this.averageRatingClicked = true;
    },
    handleBardEmojiHover() {
      this.averageRatingHover = true;
    },
    handleBadEmojiHoverEnd() {
      this.averageRatingHover = false;
    },
  },
  watch: {
    originalRating(newRating) {
      if (newRating !== 3) {
        this.averageRatingClicked = false;
        this.averageRatingSelected = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../rating.scss";
</style>
