import axios from 'axios';
let { url } = require("./urlset");
const PROXY_BASE_URL = "/ext/reviews/api";

const MainServices = {
    getQuestionAnswer(id, query) {
        return axios.get(`${url}${PROXY_BASE_URL}/v1.0/questions/entity/product/entity-id/${id}/?answer_count=${query.answer_count
            }&page_size=${query.page_size
            }&page_no=${query.page_no
            }&sort_by=${query.sort_by
            }&search=${query.search
            }`);
    },
    postAnswerVote(id, votes) {
        return axios.post(`${url}${PROXY_BASE_URL}/v1.0/votes/entity/question/entity-id/${id}/`, votes);
    },
    postQuestions(postQuestion, productid) {
        return axios.post(`${url}${PROXY_BASE_URL}/v2.0/questions/entity/product/entity-id/${productid}`, postQuestion);
    }
}
export default MainServices;
