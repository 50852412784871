<template>
  <div>
    <div class="review-card-v2">
      <div class="review-wrapper">
        <review-header
          class="review-body"
          :productReviewConfig="homeReviewCarouselConfig"
          :item="review"
        />
        <review-body
          class="review-body"
          :productReviewConfig="homeReviewCarouselConfig"
          :item="review"
        />
        <view-product
          class="product-link"
          :item="review"
          :productReviewConfig="homeReviewCarouselConfig"
          :showDate="false"
        />
      </div>
    </div>
  </div>
</template>

<script>
import rating from "../../rating/components/top-reviews/components/reviews/components/rating.vue";
import reviewBody from "../../rating/components/top-reviews/components/reviews/components/review-body.vue";
import reviewHeader from "../components/review-header.vue";
import viewProduct from "../components/viewProduct.vue";

export default {
  name: "review-card-v2",
  props: ["review", "homeReviewCarouselConfig"],
  components: {
    rating,
    "review-body": reviewBody,
    "review-header": reviewHeader,
    "view-product": viewProduct,
  },
};
</script>

<style lang="scss" scoped>
.review-body {
  margin-bottom: 12px;
  text-align: left;
}
.product-link {
  margin-top: auto;
}
.review-wrapper {
  // border: 1px solid var(--dividerStokes, #d4d1d1);
  // border-radius: 0 0 4px 4px;
  padding: 24px;
  margin-bottom: 12px;

  @media only screen and (max-width: 576px) {
    padding: 16px;
  }
  @media only screen and (min-width: 577px) and (max-width: 768px) {
    padding: 20px;
  }
}
.review-card-v2 {
  margin: 0 12px;
  border: 1px solid var(--dividerStokes, #d4d1d1);
  border-radius: 4px;
  // overflow: hidden;
}
</style>
