<template>
  <div>
    <textarea
      class="description"
      rows="4"
      :maxlength="maxlength"
      :placeholder="placeholder"
      @change="handleDescriptionChange($event)"
      :value="description"
    ></textarea>
  </div>
</template>

<script>
export default {
  name: "description-box",
  props: {
    placeholder: {
      type: String,
      default: "",
    },
    description: {
      type: String,
      default: "",
    },
  },
  methods: {
    handleDescriptionChange($event) {
      this.$emit("handleDescription", $event.target.value);
    },
  },
};
</script>

<style scoped>
.description {
  margin: 16px 0 0 0;
  background: #ffffff;
  border: none;
  width: -webkit-fill-available;
  resize: none;
  padding: 16px 16px 17px 16px;
  border-radius: 4px;
  border: 1px solid #d4d1d1;
}
textarea:focus-visible {
  outline: none !important;
}
</style>
