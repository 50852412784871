<template>
  <section>
    <div class="number-rating">
      <div
        :class="[
          'number',
          isBadRating(item) ? 'bad' : '',
          isAverageRating(item) ? 'average' : '',
          isGoodRating(item) ? 'good' : '',
          isSelected(item) ? 'selected' : '',
        ]"
        v-for="item in ratingNumbers"
        @click="$parent.$emit('numberRatingClick', item)"
        v-bind:key="`number${item}`"
      >
        <span>{{ item }}</span>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "number-rating",
  data() {
    return {
      ratingNumbers: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    };
  },
  props: {
    originalRating: {
      type: Number,
      default: 3,
    },
    scalableRating: {
      type: Number,
      default: 3,
    },
  },
  methods: {
    isBadRating(item) {
      return item < 5;
    },
    isAverageRating(item) {
      return item < 9 && item > 4;
    },
    isGoodRating(item) {
      return item > 8;
    },
    isSelected(item) {
      return item === this.scalableRating;
    },
  },
};
</script>

<style lang="scss" scoped>
.number-rating {
  display: flex;
  gap: 1px;
  .number {
    display: flex;
    width: 48px;
    cursor: pointer;
    padding: 20px 0px;
    text-align: center;
    font-family: unset;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;

    span {
      margin: auto;
    }
    &:first-child {
      border-radius: 8px 0px 0px 8px;
    }
    &:last-child {
      border-radius: 0px 8px 8px 0px;
    }

    @media only screen and (max-width: 576px) {
      font-size: 12px;
    }
  }
  .bad {
    color: #b24141;
    background-color: #ffdcdc;
    border: 1px solid #ffdcdc;
  }
  .bad.selected {
    border-color: #b24141;
  }
  .average {
    color: #d28f51;
    background-color: #ffead8;
    border: 1px solid #ffead8;
  }
  .average.selected {
    border-color: #d28f51;
  }
  .good {
    color: #1c958f;
    background-color: #dbffe4;
    border: 1px solid #dbffe4;
  }
  .good.selected {
    border-color: #1c958f;
  }
}
</style>
