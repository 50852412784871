<template>
  <carousel-component
    :topReviewsList="topReviewsList"
    :homeReviewCarouselConfig="homeReviewCarouselConfig"
  />
</template>

<script>
import carouselComponent from "./review-carousel.vue";
import reviewService from "../services/customer-review";
import configuration from "../services/configuration";

export default {
  name: "review-carousel",
  data() {
    return {
      topReviewsList: [],
      query: { total: 0, page_no: 1, hasNext: false, page_size: 18 },
      homeReviewCarouselConfig: {},
      user: {},
    };
  },
  components: {
    "carousel-component": carouselComponent,
  },
  mounted() {
    window.FPI.state.user.subscribe(({ user }) => {
      this.user = user;
    });
    this.getCarouselConfig();
    this.topReviews();
  },
  methods: {
    async topReviews() {
      try {
        let response = await reviewService.getTopReviews(this.query);
        this.topReviewsList = response.data.data.docs;
      } catch (error) {
        console.log("error", error);
      }
    },
    async getCarouselConfig() {
      try {
        let widget = "home_carousel";
        let response = await configuration.getWidgetsConfig(widget);
        this.homeReviewCarouselConfig = response.data.data[0]?.config;
      } catch (error) {
        console.log("error", error);
      }
    },
  },
};
</script>
