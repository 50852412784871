<template>
  <div
    class="floating-window-container"
    v-if="floatingReviewConfig.is_active"
    :style="addThemeVariables"
  >
    <modals
      class="model_floating_window"
      ref="floatingWindowForm"
      @closeModal="onCloseModal"
    >
      <template>
        <vue-masonry-wall
          class="mt-2"
          :items="reviewListItem"
          :options="options"
        >
          <template v-slot:default="{ item }">
            <div class="multi-grid">
              <floating-media
                class="floating-window-media"
                :item="item"
                @selected-media="currentSelectedMedia"
              />
              <div class="floating-content">
                <profile
                  :floatingReviewConfig="floatingReviewConfig"
                  :item="item"
                />
                <rating
                  :productReviewConfig="floatingReviewConfig"
                  :item="item"
                />
                <reviewBody
                  :floatingReviewConfig="floatingReviewConfig"
                  :item="item"
                />
                <productCard
                  :floatingReviewConfig="floatingReviewConfig"
                  :item="item"
                />
              </div>
            </div>
          </template>
        </vue-masonry-wall>
        <button
          class="show-more-button"
          @click="handleShowMore()"
          v-if="!disableShowMore"
        >
          Show more review
        </button>
      </template>
    </modals>

    <button
      class="floating-button"
      :class="
        floatingConfig.floating_position == 'left'
          ? 'float-left'
          : 'float-right'
      "
      @click="openReviewsModal()"
      v-show="showFloatingButton"
    >
      {{ floatingConfig.heading }}
    </button>

    <floatingReviewSlide
      v-if="showCarousel"
      :reviewList="reviewListItem"
      :currentSlideIndex="currentSlideIndex"
      :key="currentSlideIndex"
    ></floatingReviewSlide>
  </div>
</template>

<script>
import VueMasonryWall from "vue-masonry-wall";

import modals from "./../components/modals.vue";
import media from "./../review-carousel/components/media.vue";
import profile from "./components/profile.vue";
import rating from "./../rating/components/top-reviews/components/reviews/components/rating.vue";
import reviewBody from "./components/review-body.vue";
import productCard from "./components/product-card.vue";
import FloatingMedia from "./components/floating-media.vue";
import floatingReviewSlide from "./components/floating-review-slide.vue";
import { colorShadeObject } from "../utils";
export default {
  name: "FloatingWindow",
  props: { floatingReviewConfig: {}, reviewListItem: {}, disableShowMore: {} },
  components: {
    modals,
    profile,
    rating,
    reviewBody,
    VueMasonryWall,
    media,
    productCard,
    FloatingMedia,
    floatingReviewSlide,
  },
  data() {
    return {
      imageFlowID_test: "INITIAL",
      options: {
        width: 264,
        padding: {
          default: 12,
        },
      },
      selectedOption: "desc_date",
      paginationConfig: {
        limit: 5,
        current: 1,
        total: 0,
      },
      floatingConfig: {},
      imageFlowID: null,
      currentSlideIndex: 0,
      showCarousel: false,
      showFloatingButton: true,
    };
  },
  mounted() {
    this.floatingReviewConfig.rating_type = "both_star_and_number_rating";
    this.floatingConfig = this.floatingReviewConfig;
  },
  destroy() {},
  methods: {
    openReviewsModal() {
      if (this.floatingConfig.floating_behaviour == "open_in_modal") {
        this.$refs.floatingWindowForm.openModal();
        this.showFloatingButton = false;
      } else if (this.floatingConfig.floating_behaviour == "open_in_page") {
        window.open(this.floatingConfig.page_link, "_blank");
      }
    },
    handleShowMore() {
      this.$emit("more-reviews");
    },
    onCloseModal() {
      this.showFloatingButton = true;
    },
    currentSelectedMedia(selectedReview) {
      const currentIndex = this.reviewListItem.findIndex(
        (item) => item._id === selectedReview?.review
      );
      if (currentIndex !== -1) {
        this.currentSlideIndex = currentIndex;
        this.showCarousel = true;
      }
    },
  },
  computed: {
    addThemeVariables() {
      let themeShadeColor =
        getComputedStyle(document.documentElement).getPropertyValue(
          "--themeAccentL1"
        ) || undefined;

      let themeAccent = getComputedStyle(
        document.documentElement
      ).getPropertyValue("--accentColor");

      return !themeShadeColor ? colorShadeObject(themeAccent) : {};
    },
  },
  watch: {
    reviewListItem(value) {
      // console.log(value);
    },
  },
};
</script>

<style lang="less" scoped>
@import "./../assets/common-config.less";

.floating-window-container {
  .model_floating_window {
    overflow: hidden;
    ::v-deep .modal__dialog {
      width: 100%;
      max-width: 90vw;

      @media only screen and (max-width: 480px) {
        border-radius: 12px 12px 0px 0px;
      }
    }
  }

  .mt-2 {
    margin-top: 24px !important;
  }

  .review-img {
    width: 100%;
    height: auto;
    max-height: 320px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }

  .multi-grid {
    border: 1px solid #d4d1d1;
    border-radius: 4px;

    .floating-content {
      padding: 1rem;

      @media only screen and (max-width: 576px) {
        padding: 16px;
      }

      @media only screen and (min-width: 577px) and (max-width: 576px) {
        width: 20px;
      }
    }
  }

  .floating-window-media {
    cursor: pointer;
  }

  .floating-button {
    position: fixed;
    z-index: 99;
    vertical-align: top;
    top: 50%;
    display: inline-flex;
    padding: 18px 30px;
    justify-content: center;
    align-items: center;
    border-radius: 24px 24px 0px 0px;
    border: 1px solid var(--buttonPrimary, #8b8374);
    background: var(--buttonPrimary, #8b8374);
    color: var(--buttonSecondary, #fff);
    cursor: pointer;

    /* Desktop/Button/Button text - Desktop */
    font-family: unset;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.28px;
    text-transform: uppercase;

    &.float-left {
      transform: rotate(90deg) translate(0, 90%) !important;
      -ms-transform: rotate(90deg);
      -moz-transform: rotate(90deg);
      left: 0;
    }

    &.float-right {
      transform: rotate(-90deg) translate(0, 90%) !important;
      -ms-transform: rotate(-90deg);
      -moz-transform: rotate(-90deg);
      right: 0;
    }
  }

  .show-more-button {
    color: var(--buttonLink, #8b8374);
    font-family: unset;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.28px;
    text-transform: uppercase;
    text-align: center;
    width: 200px;
    background: none;
    border: none;
    margin: 0 auto;
    cursor: pointer;
    margin-top: 2rem;
  }
}
</style>
