<template>
  <div
    v-if="productReviewConfig.display_all_media"
    :key="imagesByProduct.items[0]"
  >
    <div
      class="review-with-image"
      v-if="imagesByProduct.items.length"
      :key="imagesByProduct.items[0]"
    >
      <div class="image-review-container">
        <span class="image-review-heading">Reviews with images</span>
        <div
          :class="{
            'image-container-wrapper':
              productReviewConfig.selected_layout === 3,
          }"
        >
          <div class="image-container">
            <div
              class="review-image"
              v-for="(img, index) in imageReview"
              :key="index"
            >
              <button
                class="img-review"
                v-if="img.type == 'video'"
                v-on:click="getReviewIdWithImage(img.entity.id, img.url)"
              >
                <video class="image-review-block">
                  <source :src="img.url" type="video/mp4" />
                </video>
              </button>
              <button
                class="img-review"
                v-else
                v-on:click="getReviewIdWithImage(img.entity.id, img.url)"
              >
                <img
                  src="../../../assets/loader.svg"
                  class="loader-small"
                  alt=""
                  srcset=""
                  v-show="showLoader"
                />
                <image-moderation
                  :isNSFW="img.is_nsfw"
                  :mediaUrl="img.url"
                  :optimizeType="'thumbnail'"
                  :className="['image-review-block']"
                  @image-loaded="onImageLoad"
                ></image-moderation>
              </button>
            </div>
          </div>
          <div class="see-all-image">
            <button
              @click="
                $refs.modalName.openModal();
                showMoreImage();
              "
            >
              See all customer media
            </button>
          </div>
        </div>
      </div>
    </div>
    <modals class="all_image" ref="modalName">
      <template>
        <allImage
          v-on:reviewid="getReviewId($event)"
          @closemodel="$refs.modalName.closeModal()"
          :imageItem="allImageItem"
        />
      </template>
    </modals>
    <modals class="image_review" ref="imageReview">
      <template>
        <imageReviewItem
          :getBack="closeModals"
          :rid="reviewListId"
          @closeDialog="$refs.imageReview.closeModal()"
        />
      </template>
    </modals>
  </div>
</template>

<script>
import modals from "../../../components/modals.vue";
import allImage from "../../../components/all-image.vue";
import imageReviewItem from "../../../components/image-review.vue";
import ImageModeration from "../../../components/image-moderation.vue";
import { resizeThumbnails } from "./../../../utils";

export default {
  name: "review-with-image",
  props: ["productReviewConfig", "imagesByProduct"],
  data() {
    return {
      reviewListId: null,
      allImages: {},
      allImageItem: [],
      totalImage: null,
      showLoader: true,
    };
  },
  components: {
    modals,
    allImage,
    imageReviewItem,
    ImageModeration,
  },
  computed: {
    imageReview() {
      if (this.productReviewConfig.selected_layout === 3) {
        return this?.imagesByProduct?.items.slice(0, 8) || [];
      }
      return this?.imagesByProduct?.items.slice(0, 20) || [];
    },
    checkIsPlatform() {
      return Boolean(window.sessionStorage.getItem("isPlatform"));
    },
  },
  mounted() {
    this.allImages = this.imagesByProduct?.items;
    this.totalImage = this.imagesByProduct?.page?.item_total;
    if (this.allImageItem?.length != this.totalImage) {
      for (let i in this.allImages) {
        this.allImageItem.push(this.allImages[i]);
      }
    }
  },
  methods: {
    scrollEvent() {
      if (this?.imagesByProduct?.items?.length != this.totalImage) {
        this.getAllImagesByProduct();
      }
    },
    getReviewIdWithImage(review, url) {
      if (!this.checkIsPlatform) {
        this.reviewListId = {
          review,
          url,
        };
        this.$refs.imageReview.openModal();
      }
    },
    showMoreImage() {
      if (this.allImageItem?.length != this.totalImage) {
        this.getAllImagesByProduct();
        this.$emit("showMoreImage");
      }
    },
    getAllImagesByProduct() {
      let query = {
        page_size: this.paginationConfig.limit,
        page_no: this.paginationConfig.current,
      };
      customerReview
        .getImagesByProduct(this.productitem.uid, query)
        .then((data) => {
          this.imagesByProduct = data.data;
          this.allImages = data.data.items;
          this.totalImage = data.data.page.item_total;
          if (this.allImageItem.length != this.totalImage) {
            for (let i in this.allImages) {
              this.allImageItem.push(this.allImages[i]);
            }
          }
        });
    },
    getReviewId(reviewObj) {
      this.reviewListId = reviewObj;
      this.$refs.imageReview.openModal();
    },
    closeModals() {
      this.$refs.imageReview.closeModal();
      this.$refs.modalName.openModal();
    },
    getThumbnailMedia(url) {
      return resizeThumbnails(url);
    },
    onImageLoad() {
      this.showLoader = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.review-with-image {
  margin-bottom: 32px;
  @media screen and (max-width: 576px) {
    margin-top: 24px;
  }
  @media only screen and (min-width: 577px) and (max-width: 992px) {
    margin-top: 48px;
  }

  .image-review-heading {
    font-family: unset;
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 37px;
    letter-spacing: -0.02em;
    color: var(--textHeading, #261a1a);
    margin-bottom: 16px;
  }
  .image-review-container {
    display: flex;
    flex-direction: column;
    .image-container {
      display: flex;

      overflow-x: auto;
      gap: 1rem;
      overflow-y: hidden;
      .review-image {
        .img-review {
          border: none;
          background: transparent;
          cursor: pointer;
          width: 80px;
          height: 80px;

          img {
            object-fit: cover;
          }
          .image-review-block {
            width: 80px;
            height: 80px;
            object-fit: cover;
            border-radius: 24px;
          }
        }
      }
    }
    .see-all-image {
      margin-top: 0.5rem;
      button {
        border: none;
        background: none;
        text-decoration: none;
        font-family: unset;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 15px;
        color: var(--textBody, #3c3131);
        cursor: pointer;
      }
    }
  }
}
.all_image {
  ::v-deep .modal__dialog {
    max-width: 946px;
    max-height: 600px;
    overflow-y: scroll;
  }
}
.model_rating {
  ::v-deep .modal__dialog {
    max-width: 510px;
    @media screen and (max-width: 930px) {
      max-width: 100%;
    }
  }
}
.loader-small {
  width: 40px !important;
  height: 40px !important;
}

.image-container-wrapper {
  overflow: auto;
  .image-container {
    display: flex !important;
    gap: 14px !important;
    overflow-y: hidden !important;
    width: auto !important;
    flex-wrap: wrap !important;
    height: 100% !important;
    overflow-x: auto !important;
    white-space: nowrap !important;
    width: 100% !important;
    max-height: 225px !important;

    .review-image {
      .img-review {
        width: 100px !important;
        .image-review-block {
          width: 100px !important;
          height: 100px !important;
          object-fit: cover;
          border-radius: 16px !important;
        }
      }
    }

    @media only screen and (max-width: 576px) {
      flex-wrap: nowrap;
    }
    .review-image {
      flex-basis: 12% !important;
      @media only screen and (min-width: 577px) and (max-width: 992px) {
        flex-basis: auto;
      }
    }
  }
}
.image_review {
  ::v-deep .modal__dialog {
    width: 90%;
    max-width: 946px;
    max-height: 600px;
    @media only screen and (max-width: 576px) {
      width: 288px;
      max-height: none;
    }
  }
  ::v-deep .modal__body {
    padding: 0;
    @media only screen and (max-width: 576px) {
      max-width: 100%;
    }
  }
}
</style>
