<template>
  <div :class="getFeatureClass">
    <div class="feature-heading">By Features</div>
    <div v-for="rating in ratingByFeaturePDP" class="rating-by-feature">
      <div class="rating-tag">{{ rating.feature }}</div>
      <div class="feature-rating">
        <div>
          <star-rating
            v-model="rating.average_rating"
            :star-size="19"
            :read-only="true"
            :show-rating="false"
            :round-start-rating="false"
            :padding="3"
            :active-color="starColor"
          />
        </div>
        <div class="avg-rating">
          {{ rating.average_rating }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import starRating from "vue-star-rating";

export default {
  name: "rating-by-feature",
  components: {
    starRating,
  },
  props: ["selectedLayout", "ratingByFeaturePDP", "starColor"],
  computed: {
    getFeatureClass() {
      if (this.selectedLayout === 2) {
        return "feature-wrapper feature-wrapper-layout2";
      }
      if (this.selectedLayout === 3) {
        return "feature-wrapper feature-wrapper-layout3";
      }
      return "feature-wrapper";
    },
  },
};
</script>

<style scoped lang="scss">
.feature-heading {
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: -0.02em;
  color: var(--textHeading, #261a1a);
  margin-bottom: 16px;
  margin-top: 32px;
  text-transform: capitalize;
}
.rating-by-feature {
  display: flex;

  justify-content: space-between;
  align-items: center;
  margin-bottom: 4px;
}
.avg-rating {
  width: 20px;
  text-align: right;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: -0.02em;
  color: var(--textBody, #3c3131);
}
.rating-tag {
  font-size: 12px;
  line-height: 14px;
  letter-spacing: -0.02em;
  color: var(--textBody, #3c3131);
  text-transform: capitalize;
}

.feature-rating {
  display: flex;
  align-items: center;
}
.feature-wrapper {
  margin: 32px 0;
}

.feature-wrapper-layout2 {
  padding: 16px;
  border: 1px solid #d4d1d1;
  border-radius: 4px;
  .feature-heading {
    margin-top: 0;
  }
}
.feature-wrapper-layout3 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 21px 0;
  @media screen and (max-width: 576px) {
    display: block;
  }
  @media only screen and (min-width: 577px) and (max-width: 992px) {
    display: grid;
    grid-template-columns: 1fr 2fr 2fr;
    grid-column-gap: 24px;

    .rating-by-feature:last-child {
      grid-column-start: 2;
    }
  }
  .feature-heading {
    margin: 0;
  }
  .rating-tag {
    margin-right: 13px;
  }
}
</style>
