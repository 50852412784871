<template>
  <div>
    <div v-for="(media, index) in getMedia" :key="media.type + index">
      <div v-if="media">
        <div
          v-if="media.type == 'image'"
          :class="className ? className : 'media-v1'"
          v-on:click="getReviewIdWithImage(item._id, media.url)"
          class="fw-media-slot"
        >
          <img
            src="../../assets/loader.svg"
            class="loader"
            alt=""
            srcset=""
            v-show="showLoader"
          />
          <image-moderation
            :isNSFW="media.is_nsfw"
            :mediaUrl="media.url"
            :optimizeType="'responsive'"
            :className="['review-img']"
            @image-loaded="onImageLoad"
          ></image-moderation>
        </div>
        <div
          v-if="media.type == 'video'"
          :class="className ? className : 'media-v1 video-wrapper'"
          v-on:click="getReviewIdWithImage(item._id, media.url)"
        >
          <div class="overlay"></div>
          <video
            class="review-video"
            loading="lazy"
            autoplay
            controlsList="nodownload nofullscreen"
          >
            <source :src="media.url" type="video/mp4" />
          </video>
          <img
            class="play-icon"
            src="./../../assets/play_button.svg"
            alt=""
            srcset=""
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import modals from "../../components/modals.vue";
import floatingReviewSlide from "./floating-review-slide.vue";
import { resizePixelbinImage } from "../../utils";
import ImageModal from "../../../../../src/views/Settings/imageModal.vue";
import ImageModeration from "../../components/image-moderation.vue";

export default {
  name: "floatingMedia",
  props: { item: {}, className: {} },
  components: {
    floatingReviewSlide,
    modals,
    ImageModal,
    ImageModeration,
  },
  data() {
    return {
      reviewListId: null,
      showLoader: true,
    };
  },
  mounted() {},
  computed: {
    getMedia() {
      if (this.item?.media?.total_count > 0) {
        return this.item?.media?.items.slice(0, 1);
      }
    },
    checkIsPlatform() {
      return window.sessionStorage.getItem("isPlatform");
    },
  },
  methods: {
    closeModals() {
      this.$refs.imageReview.closeModal();
    },
    getReviewIdWithImage(review, url) {
      if (!this.checkIsPlatform) {
        this.reviewListId = {
          review,
          url,
        };
        this.$emit("selected-media", this.reviewListId);
      }
    },
    checkShowButton() {
      return true;
    },
    showPrev() {
      this.$refs.carousel.prev();
    },
    showNext() {
      this.$refs.carousel.next();
    },
    getOptimizedMedia(url) {
      return resizePixelbinImage(url);
    },
    onImageLoad() {
      this.showLoader = false;
    },
  },
};
</script>
<style scoped lang="scss">
.media-v1 {
  border: none;
  background: white;
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;

  img.review-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    aspect-ratio: 1/1;
  }

  video.review-video {
    width: 264px;
    height: 320px;
    aspect-ratio: 1/1;
    object-fit: cover;
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.4);
  }

  img.play-icon {
    width: 54px;
    height: 54px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.image_review {
  ::v-deep .modal__dialog {
    width: 100%;
    max-width: 946px !important;
    max-height: 600px !important;

    @media only screen and (max-width: 576px) {
      max-width: 100% !important;
    }
  }

  ::v-deep .modal__body {
    padding: 0 !important;

    @media only screen and (max-width: 576px) {
      max-width: 100% !important;
    }
  }
}
.media-v2 {
  border: none;
  background: white;
  width: 60px;
  height: 60px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    aspect-ratio: 1/1;
  }
}

.media-v3 {
  height: 288px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    aspect-ratio: 1/1;
  }
  @media only screen and (min-width: 577px) and (max-width: 650px) {
    height: 310px;
    width: 310px;
  }
  @media only screen and (min-width: 651px) and (max-width: 768px) {
    height: 348px;
    width: 348px;
  }
  @media only screen and (min-width: 769px) and (max-width: 991px) {
    height: 460px;
    width: 460px;
  }
  @media only screen and (min-width: 992px) {
    height: 558px;
    width: 558px;
  }
}

.fw-media-slot {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 250px;

  .loader {
    width: 100px;
    height: 100px;
  }
}
</style>
