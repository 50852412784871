<template>
  <div class="view-product-wrapper">
    <div>
      <a
        v-if="productReviewConfig.view_product_link"
        class="view-product"
        :href="`${this.item.product_url}`"
        target="_blank"
      >
        View Product
        <img
          src="../../assets/chevron_right_24px.svg"
          alt="right-arrow"
          class="right-arrow"
        />
      </a>
    </div>
    <div v-if="productReviewConfig.review_date && showDate" class="review-date">
      {{ getDate(item) }}
    </div>
  </div>
</template>

<script>
import { convertDate } from "./../../utils";

export default {
  name: "view-product",
  props: ["item", "productReviewConfig", "showDate"],
  methods: {
    getDate(items) {
      let date = convertDate(items);
      return date;
    },
  },
};
</script>

<style scoped lang="scss">
.view-product-wrapper {
  display: flex;
  justify-content: space-between;
  margin-top: 12px;
  @media only screen and (max-width: 576px) {
    display: block;
  }
}
.view-product {
  display: flex;
  align-content: center;
  color: var(--buttonLink, #8b8374);
  font-family: unset;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.28px;
}
.review-date {
  color: #7d7676;
  font-family: unset;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.24px;

  @media only screen and (max-width: 576px) {
    padding-top: 4px;
  }
}

.right-arrow {
  height: 18px;
  width: 18px;
  filter: invert(53%) sepia(10%) saturate(463%) hue-rotate(1deg) brightness(96%)
    contrast(90%);
}
</style>
