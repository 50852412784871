<template>
  <div class="feedback">
    <div class="heading">Review Form</div>
    <span>Please order the product for being eligible to review.</span>
    <button class="button" @click="$emit('closeModals')">
      <div class="text">KEEP SHOPPING</div>
    </button>
  </div>
</template>

<script>
export default {
  name: "feedback-component",
};
</script>

<style scoped lang="scss">
.feedback {
  .heading {
    font-family: unset;
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
    text-align: center;
    color: var(--textHeading, #261a1a);
    margin-bottom: 10px;
  }
  span {
    font-family: unset;
    font-size: 15px;
    line-height: 140%;
    text-align: center;
    color: var(--textBody, #3c3131);
    margin-bottom: 10px;
    display: flex;
    align-content: center;
    justify-content: center;
  }
  .button {
    border: none;
    width: 100%;
    height: 48px;
    border: 1px solid var(--buttonPrimary, #8b8374);
    background: var(--buttonPrimary, #8b8374);
    border-radius: 3px;
    cursor: pointer;
    .text {
      font-family: unset;
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      color: var(--buttonSecondary, #ffffff);
    }
  }
}
</style>
