<template>
  <div>
    <button
      class="rating-button"
      v-if="badRatingSelected"
      key="selectedOptionsBad"
    >
      <lottie
        :options="selectedOptionsBad"
        v-on:animCreated="handleAnimation"
      />
    </button>
    <button
      class="rating-button"
      @click="handleBadStarClick"
      @mouseenter="handleBardStarHover"
      @mouseleave="handleBadStarHoverEnd"
      v-else-if="badRatingHover"
      key="hoverOptionsBad"
    >
      <lottie :options="hoverOptionsBad" v-on:animCreated="handleAnimation" />
    </button>
    <button
      class="rating-button"
      v-else-if="badRatingClicked"
      key="badRatingClicked"
    >
      <lottie
        :options="clickedOptionsBad"
        v-on:animCreated="handleAnimationBad"
      />
    </button>
    <button
      class="rating-button"
      @click="handleBadStarClick"
      @mouseenter="handleBardStarHover"
      @mouseleave="handleBadStarHoverEnd"
      key="defaultOptionsBad"
      v-else
    >
      <lottie :options="defaultOptionsBad" v-on:animCreated="handleAnimation" />
    </button>
  </div>
</template>

<script>
import Lottie from "vue-lottie";

import BadStarHover from "../../assets/stars/hover/bad.json";
import BadStarDefault from "../../assets/stars/default/bad.json";
import BadStarSelected from "../../assets/stars/selected/bad.json";
import BadStarClicked from "../../assets/stars/clicked/bad.json";

export default {
  name: "rating-button",
  data() {
    return {
      defaultOptionsBad: { animationData: BadStarDefault, loop: true },
      hoverOptionsBad: { animationData: BadStarHover, loop: true },
      clickedOptionsBad: { animationData: BadStarClicked, loop: true },
      selectedOptionsBad: { animationData: BadStarSelected, loop: true },
      animationSpeed: 1,
      badRatingHover: false,
      badRatingSelected: false,
      badRatingClicked: false,
      anim: null, // for saving the reference to the animation
      animBadClicked: null, // for saving the reference to the animation
    };
  },
  props: ["originalRating"],
  components: {
    lottie: Lottie,
  },
  methods: {
    handleAnimation: function (anim) {
      this.anim = anim;
    },
    handleAnimationBad: function (animBad) {
      this.animBad = animBad;
      this.$watch(
        () => {
          return this.anim._completedLoop;
        },
        (val) => {
          if (val && this.originalRating === 1) {
            this.badRatingSelected = true;
          }
        }
      );
    },
    handleBadStarClick() {
      this.badRatingHover = false;
      this.$parent.$parent.$emit("ratingClick", 1);
      this.badRatingClicked = true;
    },
    handleBardStarHover() {
      this.badRatingHover = true;
    },
    handleBadStarHoverEnd() {
      this.badRatingHover = false;
    },
  },
  watch: {
    originalRating(newRating) {
      if (newRating !== 1) {
        this.badRatingClicked = false;
        this.badRatingSelected = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../rating.scss";
</style>
