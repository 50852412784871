import ProductRating from "./product-rating.vue";
import Question from "./Question.vue";
import ApplicationReview from "./application-review/application-review.vue";
import FloatingWindow from "./floatingWindow.vue";
import ReviewCarousel from "./review-carousel/index.vue";

window.FPI.extension.register("#review-list", {
  mounted(element) {
    window.FPI.extension.mountApp({
      element,
      component: ProductRating,
    });
  },
});

window.FPI.extension.register("#question-list", {
  mounted(element) {
    window.FPI.extension.mountApp({
      element,
      component: Question,
    });
  },
});

window.FPI.extension.register("#application-review-modal", {
  mounted(element) {
    window.FPI.extension.mountApp({
      element,
      component: ApplicationReview,
    });
  },
});

window.FPI.extension.register("#floating-window", {
  mounted(element) {
    window.FPI.extension.mountApp({
      element,
      component: FloatingWindow,
    });
  },
});

window.FPI.extension.register("#review-carousel", {
  mounted(element) {
    window.FPI.extension.mountApp({
      element,
      component: ReviewCarousel,
    });
  },
});
