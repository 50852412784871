<template>
  <div class="question-form">
    <div class="product-info">
      <div class="product-img">
        <img :src="productData.medias[0].url" alt="" loading="lazy" />
      </div>
      <div class="product-meta">
        <div class="product-brand">
          {{ productData.brand.name }}
        </div>
        <div class="product-title">
          {{ productData.name }}
        </div>
      </div>
    </div>
    <div class="horizontal-line"></div>
    <div class="points-for-questions">
      <ul>
        <li v-for="(item, index) in checklists" :key="index" class="points">
          {{ item.text }}
        </li>
      </ul>
    </div>
    <div class="horizontal-line"></div>
    <div class="add-your-question">
      Add your question
      <form @submit.prevent="submitQuestion" method="post">
        <div class="form mt-1">
          <textarea
            v-model="postQuestion.question.text"
            class="post-question-area"
            placeholder=""
          ></textarea>
          <button
            class="post-submit-button"
            :class="submitted ? '' : ''"
            type="submit"
            @click="validate"
            :disabled="checkIsPlatform || submitted"
          >
            Submit your question
          </button>
        </div>
      </form>
    </div>
    <div v-if="validated" class="error-message">
      Please provide your question
    </div>
  </div>
</template>

<script>
import MainServices from "./../../../../services/questionAnswer";

export default {
  name: "question-form",
  props: ["productData"],
  data() {
    return {
      content_type: null,
      postQuestion: {
        question: {
          text: null,
        },
        // product: {
        //   title: "",
        //   brand: "",
        //   description: "",
        //   media: [],
        //   link: "",
        //   slug: "",
        //   id: "",
        // },
      },
      feedback: false,
      isValidationAllowed: false,
      attribute: null,
      headingErrorMsg: null,
      loading: true,
      imageErrorMsg: "",
      showNetworkError: false,
      submitted: false,
      checklists: [
        {
          text: "Be specific, ask questions only about the product.",
        },
        {
          text: "Ensure you have gone through the product specifications before posting your question.",
        },
        {
          text: "Reach out to customer care for queries related to offers, orders, delivery etc.",
        },
      ],
    };
  },
  mounted() {
    window.FPI.state.product.subscribe(({ product }) => {
      this.postQuestion.product = {
        name: product?.name || "",
        brand_name: product?.brand?.name || "",
        short_description: product?.description || "",
        images: product?.medias || [],
        link: window.location.href || "",
        slug: product?.slug || "",
        uid: product?.uid || "",
        review_metric: {
          rating_sum: this.getValueByKey(product._custom_meta, "rating_sum"),
          rating_count: this.getValueByKey(
            product._custom_meta,
            "rating_count"
          ),
          review_count: this.getValueByKey(
            product._custom_meta,
            "review_count"
          ),
        },
      };
    });
  },

  computed: {
    isDisabled() {
      return this.loading ? true : false;
    },
    validated() {
      return this.isValidationAllowed && !this.postQuestion.question.text;
    },
    checkIsPlatform() {
      return Boolean(window.sessionStorage.getItem("isPlatform"));
    },
  },
  methods: {
    recommend() {
      this.forms.recommend = true;
    },
    validate() {
      this.isValidationAllowed = true;
    },
    cancelButton() {
      this.show = true;
    },
    async submitQuestion() {
      if (this.postQuestion.question.text)
        try {
          const response = await MainServices.postQuestions(
            this.postQuestion,
            this.productData.uid
          );
          if (response?.status == 200 || response?.status == 201) {
            this.$emit("handleQuestionSubmit");
            this.$emit("questionSubmitted", this.postQuestion);
            this.submitted = true;
            this.posted = false;
            this.showNetworkError = false;
          }
        } catch (error) {
          if (
            error &&
            error.message &&
            error.message.indexOf("Network Error") > -1
          ) {
            this.showNetworkError = true;
            this.$refs.errorModal.openModal();
          }
        }
    },
    getValueByKey(productMeta, key) {
      const item = productMeta.find((meta) => meta.key === key);
      return item ? item.value : "";
    },
  },
};
</script>

<style scoped lang="less">
@import "../../../../assets/common-config.less";

.question-form {
  display: inline-flex;
  gap: 1rem;
  flex-direction: column;

  .product-info {
    display: flex;
    gap: 0.75rem;
    align-items: center;

    .product-img {
      img {
        width: 90px;
        height: 72px;
        object-fit: contain;
      }
    }

    .product-meta {
      .product-brand {
        color: #000;
        font-family: unset;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 19px;
        margin-bottom: 0.5rem !important;
      }

      .product-title {
        color: #000;
        font-family: unset;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 19px;
      }
    }
  }

  .horizontal-line {
    border-bottom: 1px;
    border: 1px solid #e0e0e0;
  }

  .points-for-questions {
    ul {
      padding: 0;
      margin: 0;
      padding-left: 1rem;

      .points {
        color: var(--textBody, #3c3131);

        /* Desktop/Body/B2 - Dektop */
        font-family: unset;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
        letter-spacing: -0.28px;
        padding-bottom: 8px;
      }
    }
  }

  .add-your-question {
    color: #333;
    font-family: unset;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;

    .form {
      .post-question-area::-webkit-input-placeholder {
        color: #bdbdbd;
      }

      .post-question-area {
        padding: 10px;
        width: -webkit-fill-available;
        height: 97px;
        border-radius: 4px;
        border: 1px solid var(--dividerStokes, #d4d1d1);
        resize: none;
      }

      textarea:focus {
        outline: none !important;
        border-color: #33b2c1;
        box-shadow: 0 0 4px #33b2c1;
      }

      .post-submit-button {
        display: flex;
        height: 56px;
        padding: 12px 32px;
        justify-content: center;
        align-items: center;
        gap: 4px;
        align-self: stretch;
        width: 100%;
        border-radius: 4px;
        border: 1px solid var(--buttonPrimary, #8b8374);
        background: var(--buttonPrimary, #8b8374);
        color: var(--buttonSecondary, #fff);
        font-family: unset;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: -0.28px;
        text-transform: uppercase;
        margin-top: 1rem;
        cursor: pointer;
      }

      .post-submit-button.disabled {
        opacity: 0.5;
        cursor: not-allowed;
      }
    }
  }

  .error-message {
    margin: 0 auto;
    font-size: 12px;
  }
}
</style>
