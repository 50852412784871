<template>
  <div
    class="carousel-wrapper-rating"
    v-if="renderCarousel"
    :style="addThemeVariables"
  >
    <div class="carousel-heading">
      {{ homeReviewCarouselConfig.section_heading }}
    </div>
    <div
      v-if="
        homeReviewCarouselConfig.selected_layout === 1 &&
        homeReviewCarouselConfig.slide_grids === 'horizontal'
      "
    >
      <grid-carousel
        class="carousel-container-layout1-gird"
        :homeReviewCarouselConfig="homeReviewCarouselConfig"
        :topReviewsList="topReviewsList"
      />
    </div>
    <div
      v-if="
        homeReviewCarouselConfig.selected_layout === 1 &&
        homeReviewCarouselConfig.slide_grids === 'masonry'
      "
    >
      <masonry-carousel
        class="carousel-container-layout1-masonry"
        :homeReviewCarouselConfig="homeReviewCarouselConfig"
        :topReviewsList="topReviewsList"
      />
    </div>
    <div
      class="carousel-container-layout2"
      v-if="homeReviewCarouselConfig.selected_layout === 2"
    >
      <carousel-layout2
        :homeReviewCarouselConfig="homeReviewCarouselConfig"
        :topReviewsList="topReviewsList"
      />
    </div>
    <div
      class="carousel-container-layout3"
      v-if="homeReviewCarouselConfig.selected_layout === 3"
    >
      <carousel-layout3
        :homeReviewCarouselConfig="homeReviewCarouselConfig"
        :topReviewsList="topReviewsList"
      />
    </div>
  </div>
</template>

<script>
import GridCarousel from "./grid-carousel/grid-carousel.vue";
import MasonryCarousel from "./masonry-carousel/masonry-carousel.vue";
import CarouselLayout2 from "./layouts/carousel-layout2.vue";
import CarouselLayout3 from "./layouts/carousel-layout3.vue";
import { colorShadeObject } from "../utils";

export default {
  name: "review-carousel",
  props: ["topReviewsList", "homeReviewCarouselConfig"],
  components: {
    "grid-carousel": GridCarousel,
    "masonry-carousel": MasonryCarousel,
    "carousel-layout2": CarouselLayout2,
    "carousel-layout3": CarouselLayout3,
  },
  computed: {
    renderCarousel() {
      return (
        this.topReviewsList?.length && this.homeReviewCarouselConfig.is_active
      );
    },
    addThemeVariables() {
      let themeShadeColor =
        getComputedStyle(document.documentElement).getPropertyValue(
          "--themeAccentL1"
        ) || undefined;

      let themeAccent = getComputedStyle(
        document.documentElement
      ).getPropertyValue("--accentColor");

      return !themeShadeColor ? colorShadeObject(themeAccent) : {};
    },
  },
};
</script>

<style lang="scss" scoped>
.carousel-wrapper-rating {
  padding: 70px;
  overflow-x: hidden;

  @media only screen and (max-width: 576px) {
    padding: 16px;
  }

  @media only screen and (min-width: 577px) and (max-width: 992px) {
    padding: 24px;
  }
}

.carousel-heading {
  color: var(--textHeading, #261a1a);
  text-align: center;
  font-family: unset;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.56px;

  @media only screen and (max-width: 576px) {
    font-size: 24px;
  }
}
.text-left {
  text-align: left;
}
.carousel-container-layout1-gird {
  padding: 16px 0 26px;
  position: relative;

  @media only screen and (min-width: 577px) and (max-width: 768px) {
    padding: 30px 0;
  }
  @media only screen and (min-width: 769px) and (max-width: 991px) {
    padding: 32px 0;
  }
  @media only screen and (min-width: 992px) {
    padding: 40px 0;
  }
}
.carousel-container-layout1-masonry {
  padding: 24px 0 16px;
  position: relative;

  @media only screen and (min-width: 577px) and (max-width: 991px) {
    padding: 32px 0;
  }
  @media only screen and (min-width: 992px) {
    padding: 40px 0;
  }
}
.carousel-container-layout2 {
  padding: 16px 0 28px;
  position: relative;
  height: 100%;

  @media only screen and (min-width: 577px) and (max-width: 991px) {
    padding: 30px 0;
  }
  @media only screen and (min-width: 992px) {
    padding: 36px 0;
  }
}
.carousel-container-layout3 {
  padding: 16px 0 28px;
  position: relative;

  @media only screen and (min-width: 577px) {
    padding: 48px 0 36px;
  }
}
</style>
