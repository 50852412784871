import { render, staticRenderFns } from "./star-number-rating-header.vue?vue&type=template&id=c0f92b50&scoped=true&"
import script from "./star-number-rating-header.vue?vue&type=script&lang=js&"
export * from "./star-number-rating-header.vue?vue&type=script&lang=js&"
import style0 from "./star-number-rating-header.vue?vue&type=style&index=0&id=c0f92b50&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c0f92b50",
  null
  
)

export default component.exports