<template>
  <div class="star-rating">
    <star-rating
      v-model="average_rating"
      :star-size="21"
      :active-color="color"
      :read-only="true"
      :show-rating="false"
      :round-start-rating="false"
      :padding="3"
    />
    <div class="total-rating">{{ rating_count }} Total Rating</div>
  </div>
</template>

<script>
import starRating from "vue-star-rating";

export default {
  name: "star-rating-header",
  props: ["color", "average_rating", "rating_count"],
  components: {
    starRating,
  },
};
</script>

<style scoped lang="scss">
@import "./customer-rating-styles.scss";
</style>
