<template>
  <div class="rating-wrapper">
    <bad-rating :originalRating="originalRating" />
    <poor-rating :originalRating="originalRating" />
    <average-rating :originalRating="originalRating" />
    <good-rating :originalRating="originalRating" />
    <excellent-rating :originalRating="originalRating" />
  </div>
</template>

<script>
import Lottie from "vue-lottie";

import badRating from "./star-rating/bad-rating.vue";
import poorRating from "./star-rating/poor-rating.vue";
import averageRating from "./star-rating/average-rating.vue";
import goodRating from "./star-rating/good-rating.vue";
import excellentRating from "./star-rating/excellent-rating.vue";

export default {
  name: "star-rating",
  props: {
    originalRating: {
      type: Number,
      default: 0,
    },
    showDescription: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    lottie: Lottie,
    "bad-rating": badRating,
    "poor-rating": poorRating,
    "average-rating": averageRating,
    "good-rating": goodRating,
    "excellent-rating": excellentRating,
  },
};
</script>

<style lang="scss" scoped>
@import "./rating.scss";
</style>
